.Avatar {
    --avatar-size: 32px;
    --avatar-large-size: 40px;
    --avatar-small-size: 24px;
    --avatar-fz: 16px;
    --avatar-bg: var(--avatar-bg-theme, var(--secondary-light));
    --avatar-color: var(--avatar-color-theme, var(--primary-default));
    --avatar-border-radius: var(--control-border-radius);

    background: var(--avatar-bg);
    border-radius: var(--avatar-border-radius);
    color: var(--avatar-color);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: var(--avatar-size, 32px);
    height: var(--avatar-size, 32px);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: var(--avatar-fz);
    font-variant: var(--font-variant-base);
    font-feature-settings: var(--font-feature-settings-base);
    vertical-align: middle;
    list-style: none;
    white-space: nowrap;
    text-align: center;
    line-height: var(--avatar-size, 32px);
}

.Avatar .avatar-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Avatar .avatar-content > img {
    display: block;
    width: 100%;
    height: 100%;
    min-width: var(--avatar-size, 32px);
    min-height: var(--avatar-size, 32px);
}

.Avatar.avatar-lg {
    width: var(--avatar-large-size, 40px);
    height: var(--avatar-large-size, 40px);
    line-height: var(--avatar-large-size, 40px);
}

.Avatar.avatar-lg .avatar-content > img {
    min-width: var(--avatar-large-size, 40px);
    min-height: var(--avatar-large-size, 40px);
}

.Avatar.avatar-sm {
    width: var(--avatar-small-size, 24px);
    height: var(--avatar-small-size, 24px);
    line-height: var(--avatar-small-size, 24px);
}

.Avatar.avatar-sm .avatar-content > img {
    min-width: var(--avatar-small-size, 24px);
    min-height: var(--avatar-small-size, 24px);
}
