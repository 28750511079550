.Header {
    --header-h: 80px;
    --header-stacked-h: 100px;
    --header-stacked-padding-vertical: 15px;
    --header-fz: 16px;
    --header-lh: 1.375;
    --header-side-basis-w: 120px;
    --header-side-basis-sm-w: 95px;
    --header-side-basis-xs-w: 80px;
    --header-side-span-ml: calc(var(--margin-base) / 2);
    --header-xs-p: 0 var(--margin-base);
    --header-button-p: 5px 0;
    --header-button-fz: 16px;
    --header-button-min-h: 32px;
    --header-button-icon-m: 0 calc(var(--margin-base) / 2) 0 0;
    --header-icon-fz: 18px;
    --header-link-m: var(--indent-base);
    --header-link-p: 5px;
    --header-link-lh: 1.1875;
    --header-navigation-m: 5px 0 0 var(--indent-base);
    --header-navigation-icon-bg: var(--header-navigation-icon-bg-theme, var(--negative-light));
    --header-logo-w: 65px;
    --header-logo-m: 0 var(--margin-base);
    --header-color: var(--header-color-theme, var(--primary-default));
    --background-header: var(--background-header-theme, var(--background-base));
    --header-navigation-active: var(--header-navigation-active-theme, var(--secondary-lighter));
    --header-navigation-color: var(--header-navigation-color-theme, var(--secondary-default));

    position: relative;
    height: var(--header-h);
    background-color: var(--background-header);
    text-align: center;
    font-size: var(--header-fz);
    line-height: var(--header-lh);
}

.Header>.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: var(--header-h);
    width: 100%;
}

@media only screen and (max-width: 480px) {
    .Header>.container {
        padding: var(--header-xs-p);
    }
}

.Header__left,
.Header__right {
    max-width: 40%;
    min-width: 20%;
    display: flex;
    flex-shrink: 0;
    text-align: left;
    flex-direction: row;
}

.Header.Header_with-navigation .Header__left,
.Header.Header_with-navigation .Header__right {
    flex-basis: var(--header-side-basis-w);
}

@media only screen and (max-width: 768px) {
    .Header.Header_with-navigation .Header__left,
    .Header.Header_with-navigation .Header__right {
        flex-basis: var(--header-side-basis-sm-w);
    }
}

@media only screen and (max-width: 480px) {
    .Header.Header_with-navigation .Header__left,
    .Header.Header_with-navigation .Header__right {
        flex-basis: var(--header-side-basis-xs-w);
    }
}

.Header__right {
    text-align: right;
    justify-content: flex-end;
}

.Header__center {
    flex: 1;
    max-width: 60%;
    min-width: 20%;
}

.Header__button {
    position: relative;
    z-index: 10;
    display: inline-flex;
    justify-content: space-between;
    float: left;
    align-items: center;
    padding: var(--header-button-p);
    background: none;
    border: 0;
    min-height: var(--header-button-min-h);
    color: var(--header-color);
    text-align: left;
    font-size: var(--header-button-fz);
}

.Header__button.Left-button[name='login'] .Icon {
    transform: scaleX(-1);
}

@media only screen and (max-width: 540px) {
    .Header__button span {
        display: none;
    }
}

.Header__button:hover {
    color: var(--header-navigation-active);
    cursor: pointer;
}

.Header__button:hover span {
    text-decoration: underline;
}

.Header__right .Header__button {
    float: right;
    text-align: right;
    flex-direction: row-reverse;
}

.Header__right .Header__button .Icon+span {
    margin: var(--header-button-icon-m);
}

.Header__logo {
    width: 100%;
    color: var(--header-color);
}

.Header__logo a {
    color: var(--header-color);
}

.Header__logo b {
    font-weight: bold;
}

.Header__navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.Header__navigation .Navigation__link {
    position: relative;
    display: inline-block;
    margin: 0 calc(var(--header-link-m) - var(--header-link-p));
    padding: var(--header-link-p);
    color: var(--header-navigation-color);
    font-size: var(--header-fz);
    line-height: var(--header-link-lh);
    cursor: pointer;
}

.Header__navigation .Navigation__link.hasDropdown {
    padding: 0;
}

.Header__navigation .Navigation__link:first-child {
    margin-left: 0;
}

.Header__navigation .Navigation__link:last-child {
    margin-right: 0;
}

.Header__navigation .Navigation__link:hover,
.Header__navigation .Navigation__link:active,
.Header__navigation .Icon:hover,
.Header__navigation .Icon:active,
.Header__navigation .Dropdown-container__opened .Icon {
    color: var(--header-navigation-active);
}

.Header__navigation .Navigation__link.Navigation__link-active {
    color: var(--header-navigation-active);
    font-weight: bold;
}

.Navigation__link.Navigation__link_changed:after {
    position: absolute;
    right: -3px;
    top: 5px;
    background-color: var(--header-navigation-icon-bg);
}

.Header__navigation .Navigation__link .hasSubmenu {
    padding: var(--header-link-p);
}

.Header__navigation .Icon {
    margin: var(--header-navigation-m);
    color: var(--header-navigation-color);
    cursor: pointer;
    transition: color var(--transition);
}

.Header-dropdown {
    --link-fz: 16px;
    --min-width: 150px;
    --header-dropdown-link-p: calc(var(--margin-base) / 4) var(--margin-base);
}

.Header-dropdown .Menu {
    min-width: var(--min-width);
}

.Header-dropdown .menu-item {
    padding: 0;
}

.Header-dropdown .menu-item .Navigation__link {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: var(--header-dropdown-link-p);
    color: var(--header-navigation-color);
    font-size: var(--link-fz);
}

.Header-dropdown .menu-item .Navigation__link-active {
    font-weight: bold;
    color: var(--header-navigation-submenu-active);
}

.Header-dropdown .Navigation__link.Navigation__link_changed:after {
    top: 9px;
    right: unset;
    margin-left: -1px;
}

.Header-dropdown.rc-tooltip-placement-bottom  .rc-tooltip-inner,
.Header-dropdown.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
.Header-dropdown.rc-tooltip-placement-bottomRight .rc-tooltip-inner {
    margin-top: 0;
}

.Header-dropdown.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.Header-dropdown.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.Header-dropdown.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    top: 0;
}

.Header img {
    max-width: var(--header-logo-w);
}

.Header .Icon {
    font-size: var(--header-icon-fz);
}

.Header .Icon+span {
    margin-left: var(--header-side-span-ml);
}

.Header .profile-menu {
    justify-content: flex-end;
}

.Header.stacked {
    height: var(--header-stacked-h);
}

.Header.stacked .Header__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.Header.stacked .container {
    height: var(--header-stacked-h);
    flex-direction: column;
    padding-top: var(--header-stacked-padding-vertical);
    padding-bottom: var(--header-stacked-padding-vertical);
}

.Header.centered .Header__navigation,
.Header.stacked .Header__navigation {
    justify-content: flex-start;
}

.Header.stacked .Header__left {
    justify-content: center;
    text-align: center;
}

.Header.stacked .Header__center {
    max-width: 100%;
}

.Header.rightBar .Header__navigation {
    justify-content: flex-end;
}

.Header.rightBar .Header__right {
    width: auto;
    min-width: auto;
    max-width: initial;
}
