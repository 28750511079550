.BreadCrumbs {
    --breadcrumbs-m: calc(var(--margin-base) / 2);
    --breadcrumbs-font-size: var(--breadcrumb-font-size-theme);
    --breadcrumbs-line-height: var(--breadcrumb-line-height-theme);
    --breadcrumbs-break-w: 10px;
    --breadcrumbs-break-h: 1px;
    --breadcrumb-color: var(--breadcrumb-color-theme, var(--secondary-default));
    --breadcrumb-color-active: var(--breadcrumb-color-active-theme, var(--primary-default));
    --breadcrumb-color-hover: var(--breadcrumb-color-hover-theme, var(--secondary-dark));

    font-size: var(--breadcrumbs-font-size);
    line-height: var(--breadcrumbs-line-height);
    margin-bottom: var(--breadcrumbs-m);
}

.BreadCrumbs ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
}

.BreadCrumbs__item {
    display: flex;
    align-items: center;
    color: var(--breadcrumb-color);
    line-height: calc(var(--breadcrumbs-line-height) - 1px);
}

.BreadCrumbs__item a {
    color: var(--breadcrumb-color);
    transition: var(--transition);
}

.BreadCrumbs__item a:hover,
.BreadCrumbs__item a:active {
    color: var(--breadcrumb-color-hover);
}

.BreadCrumbs__item a:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.BreadCrumbs__item + .BreadCrumbs__item:before {
    display: inline-block;
    width: var(--breadcrumbs-break-w);
    height: var(--breadcrumbs-break-h);
    margin: 0 var(--breadcrumbs-m);
    background-color: var(--breadcrumb-color);
    content: '';
}

.BreadCrumbs__item:first-child a:before {
    display: inline-block;
    width: var(--breadcrumbs-break-w);
    height: calc(var(--breadcrumbs-break-w) - 1px);
    margin-right: var(--breadcrumbs-m);
    mask: url('../../assets/icons/breadcrumbs-arrow.svg') no-repeat center;
    mask-size: cover;
    background-color: var(--breadcrumb-color);
    content: '';
    transition: var(--transition);
}

.BreadCrumbs__item:first-child a:hover:before,
.BreadCrumbs__item:first-child a:active:before {
    background-color: var(--breadcrumb-color-hover);
}

.BreadCrumbs__item_current {
    color: var(--breadcrumb-color-active);
}

.BreadCrumbs__item.BreadCrumbs__item_current:before {
    background-color: var(--breadcrumb-color-active);
}
