.Info-box {
    --infobox-p: 16px var(--margin-base) 18px;
    --infobox-bg: var(--infobox-bg-theme, var(--background-content));
    --infobox-accent-bg: var(--infobox-accent-bg-theme, var(--background-base));
    --infobox-error-bg: var(--infobox-error-bg-theme, var(--negative-light));
    --infobox-success-bg: var(--infobox-success-bg-theme, var(--positive-dark));
    --infobox-color: var(--infobox-color-theme, var(--primary-default));
    --infobox-accent-color: var(--infobox-accent-color-theme, var(--primary-default));
    --infobox-error-color: var(--infobox-error-color-theme, var(--white));
    --infobox-success-color: var(--infobox-success-color-theme, var(--white));
    --infobox-border: var(--border-width-base) var(--border-style-base) var(--infobox-border-color-theme, var(--secondary-light));
    --infobox-border-radius: 4px;
    --infobox-fz: var(--font-size-base);
    --infobox-lh: 22px;
    --infobox-icon-fz: 18px;
    --infobox-icon-mr: calc(var(--margin-base) / 2);

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: var(--infobox-p);
    color: var(--infobox-color);
    border-radius: var(--infobox-border-radius);
    border: var(--infobox-border);
    background-color: var(--infobox-bg);
}

.info-box__icon {
    margin-right: var(--infobox-icon-mr);
    font-size: var(--infobox-icon-fz);
    line-height: var(--infobox-lh);
}

.info-box__content {
    font-size: var(--infobox-fz);
    line-height: var(--infobox-lh);
}

.info-box_accent {
    background-color: var(--infobox-accent-bg);
    border-color: var(--infobox-accent-bg);
    color: var(--infobox-accent-color);
}

.info-box_error {
    background-color: var(--infobox-error-bg);
    border-color: var(--infobox-error-bg);
    color: var(--infobox-error-color);
}

.info-box_success {
    background-color: var(--infobox-success-bg);
    border-color: var(--infobox-success-bg);
    color: var(--infobox-success-color);
}
