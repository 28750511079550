.Dropdown-container {
    --dropdown-p: var(--indent-base);
}

.Dropdown-container .Icon {
    margin: calc(var(--dropdown-p) * -1);
    padding: var(--dropdown-p);
}

.dropdown-inner {
    --dropdown-inner-p: var(--indent-base);
    --dropdown-inner-border-radius: 4px;
    --dropdown-inner-color: var(--dropdown-inner-color-theme, var(--primary-default));
    --dropdown-inner-lh: 30px;
    --dropdown-inner-active-bg: var(--dropdown-inner-active-bg-theme, var(--secondary-lighter));
    --dropdown-inner-active-color: var(--dropdown-menu-text-active-color-theme, var(--primary-default));
}

.dropdown-inner .dropdown .menu-item > * {
    color: var(--dropdown-inner-color);
    line-height: var(--dropdown-inner-lh);
}

.dropdown-inner .dropdown .menu-item:hover {
    background-color: var(--dropdown-inner-active-bg);
    border-color: var(--dropdown-inner-active-bg);
    color: var(--dropdown-inner-active-color);
}

.dropdown-inner .dropdown .menu-item:hover > * {
    color: var(--dropdown-inner-active-color);
}

.dropdown-inner.rc-tooltip-placement-top > .rc-tooltip-content > .rc-tooltip-arrow,
.dropdown-inner.rc-tooltip-placement-topLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.dropdown-inner.rc-tooltip-placement-topRight > .rc-tooltip-content > .rc-tooltip-arrow {
    bottom: 0;
}

.dropdown-inner.rc-tooltip-placement-bottom  .rc-tooltip-inner,
.dropdown-inner.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
.dropdown-inner.rc-tooltip-placement-bottomRight .rc-tooltip-inner {
    margin-top: calc(var(--dropdown-inner-p) * -1);
}

.dropdown-inner.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.dropdown-inner.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.dropdown-inner.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    top: calc(var(--dropdown-inner-p) * -1);
}
