.checkbox-wrapper {
    --checkbox-size: 18px;
    --checkbox-size-big: 24px;
    --checkbox-fz: var(--input-font-size-base);
    --checkbox-lh: var(--input-line-height-base);
    --checkbox-bg: var(--control-background-color);
    --checkbox-color: var(--control-color);
    --checkbox-description-color: var(--checkbox-description-color-theme, var(--secondary-default));
    --checkbox-border-color: var(--control-border-color);
    --checkbox-border: var(--border-width-base) var(--border-style-base) var(--checkbox-border-color);
    --checkbox-inner-size: 10px;
    --checkbox-inner-size-big: 12px;
    --checkbox-error-color: var(--checkbox-error-color-theme, var(--negative-default));
    --checkbox-mt: var(--margin-base);
    --checkbox-ml: calc(var(--margin-base) * 2);
    --checkbox-label-p: 0 0 0 calc(var(--margin-base) / 2);

    --checkbox-checked-background-color: var(--control-checked-background-color);
    --checkbox-checked-border-color: var(--control-checked-border-color);
    --checkbox-checked-color: var(--control-checked-color);

    --checkbox-hover-bg: var(--checkbox-hover-bg-theme, var(--secondary-lighter));

    position: relative;
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    font-size: var(--checkbox-fz);
    line-height: var(--checkbox-lh);
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    cursor: pointer;
}

.checkbox-wrapper.checkbox-big {
    align-items: center;
}

.checkbox-wrapper.checkbox-big .checkbox {
    top: 0;
}

.checkbox-wrapper .checkbox {
    position: relative;
    top: 4px;
}

.checkbox-wrapper .checkbox-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.checkbox-wrapper .checkbox-inner {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    background-color: var(--checkbox-bg);
    border: var(--checkbox-border);
    border-radius: var(--border-radius-base);
    border-collapse: separate;
    transition: var(--transition);
}

.checkbox-wrapper.checkbox-big .checkbox-inner {
    width: var(--checkbox-size-big);
    height: var(--checkbox-size-big);
}

.checkbox-wrapper.checkbox-error .checkbox-inner {
    border-color: var(--checkbox-error-color);
}

.checkbox-checked .checkbox-inner {
    background-color: var(--checkbox-checked-background-color);
    border-color: var(--checkbox-checked-border-color);
}

.checkbox-checked .checkbox-inner:after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: table;
    width: var(--checkbox-inner-size);
    height: var(--checkbox-inner-size);
    margin: 0;
    border: 0;
    mask: url('../../assets/icons/check.svg') no-repeat 100% 100%;
    mask-size: cover;
    background-color: var(--checkbox-checked-color);
    background-size: contain;
    transform: translate(-50%, -50%);
    opacity: 1;
    content: ' ';
    transition: var(--transition);
}

.checkbox-big .checkbox-checked .checkbox-inner:after {
    width: var(--checkbox-inner-size-big);
    height: var(--checkbox-inner-size-big);
}

.checkbox-wrapper .checkbox-label {
    display: block;
    color: var(--checkbox-color);
    font-size: var(--checkbox-fz);
}

.checkbox-wrapper .checkbox-description {
    color: var(--checkbox-description-color);
    margin-bottom: 0;
}

.checkbox-wrapper-disabled {
    opacity: 0.5;
}

.checkbox-wrapper-disabled,
.checkbox-wrapper-disabled .checkbox-input {
    cursor: not-allowed;
}

.checkbox-input:focus-visible,
.checkbox-input:focus-visible + .checkbox-inner {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.checkbox-group {
    --checkbox-group-wrapper-mt: var(--margin-base);

    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    gap: var(--checkbox-group-wrapper-mt);
    margin: 0;
    padding: 0;
    color: var(--checkbox-color);
    list-style: none;
    font-size: var(--checkbox-fz);
    line-height: var(--checkbox-lh);
    font-variant: var(--font-variant-base);
    font-feature-settings: var(--font-feature-settings-base);
}

.checkbox-group-horizontal {
    flex-direction: row;
}
