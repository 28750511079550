.multiselectFilter-dropdown-options {
    --mselect-group-select-max-h: 220px;
    --mselect-group-select-inner-w: 16px;
    --mselect-group-select-inner-h: 16px;
    --mselect-group-select-wrapper-p: 12px 20px;
    --mselect-group-select-wrapper-color: var(--mselect-group-select-wrapper-color-theme, var(--primary-default));
    --mselect-group-select-fz: 16px;
    --mselect-group-select-lh: 1;
    --mselect-group-select-wrapper-hover-label-color: var(--mselect-group-select-wrapper-hover-label-color-theme, var(--primary-default));
    --mselect-group-select-wrapper-hover-bc: var(--mselect-group-select-wrapper-hover-bc-theme, var(--secondary-lighter));
}

.multiselectFilter-dropdown-options .checkbox-group_select {
    max-height: var(--mselect-group-select-max-h);
    overflow-y: auto;
    gap: 0;
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox-inner {
    width: var(--mselect-group-select-inner-w);
    height: var(--mselect-group-select-inner-h);
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper {
    display: flex;
    flex: 1 0 auto;
    margin: 0;
    padding: var(--mselect-group-select-wrapper-p);
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox {
    top: 0;
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper + .checkbox-wrapper {
    margin-top: 0;
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper .checkbox-input {
    position: absolute;
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper:hover .checkbox-label {
    color: var(--mselect-group-select-wrapper-hover-label-color);
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox-wrapper:hover {
    background-color: var(--mselect-group-select-wrapper-hover-bc);
    cursor: pointer;
}

.multiselectFilter-dropdown-options .checkbox-group_select .checkbox-label {
    font-size: var(--mselect-group-select-fz);
    line-height: var(--mselect-group-select-lh);
}

.select {
    --select-min-width: 100px;
    --select-max-height: 300px;
    --select-height: 40px;
    --select-fz: var(--input-font-size-base);
    --select-line-height: var(--input-line-height-base);
    --select-bg: var(--select-dropdown-bg-theme, var(--bright));
    --select-input-vertical-padding: 0;
    --select-input-horizontal-padding: 12px;
    --select-bth-mini-size: 20px;
    --select-bth-mini-p: 5px;
    --select-bth-mini-border: var(--border-width-base) var(--border-style-base) transparent;
    --select-bth-mini-checked-bc: var(--select-bth-mini-checked-bc-theme, var(--accent-default));
    --select-bth-mini-checked-border-color: var(--select-bth-mini-checked-border-color-theme, var(--accent-default));
    --select-bth-mini-checked-arrow-color: var(--select-bth-mini-checked-arrow-color-theme, var(--white));
    --select-bth-mini-checked-hover-bc: var(--select-bth-mini-checked-hover-bc-theme, var(--secondary-lighter));
    --select-bth-mini-checked-hover-border-color: var(--select-bth-mini-checked-hover-border-color-theme, var(--secondary-default));
    --select-bth-mini-checked-hover-arrow-color: var(--select-bth-mini-checked-hover-arrow-color-theme, var(--primary-default));
    --select-dropdown-size: 240px;
    --select-btn-p: 6px 27px 6px 12px;
    --select-btn-border: var(--border-width-base) var(--border-style-base) var(--select-button-border-color-base-theme, var(--secondary-light));
    --select-btn-color: var(--select-btn-color-theme, var(--primary-default));
    --select-btn-fz: var(--input-font-size-base);
    --select-btn-lh: var(--input-line-height-base);
    --select-bth-focused-border-color: var(--select-bth-focused-border-color-theme, var(--accent-default));
    --select-bth-invalid-border-color: var(--select-bth-invalid-border-color-theme, var(--negative-default));
    --select-bth-placeholder-color: var(--select-bth-placeholder-color-theme, var(--secondary-default));
    --select-bth-tooltiped-pr: 52px;
    --select-bth-boxed-border-color: var(--select-bth-boxed-border-color-theme, var(--secondary-light));
    --select-option-wrapper-p: 10px 20px;
    --select-option-wrapper-color: var(--select-option-wrapper-color-theme, var(--primary-default));
    --select-option-fz: 16px;
    --select-option-lh: 26px;
    --select-option-hover-bc: var(--select-option-hover-bc-theme, var(--secondary-lighter));
    --select-option-hover-color: var(--select-option-hover-color-theme, var(--primary-default));
    --select-loader-mb: var(--margin-base);
    --select-selected-values-h: 16px;
    --select-selected-values-w: auto;
    --select-selected-values-lh: 17px;
    --select-selected-count-h: auto;
    --select-selected-count-p: 0 6px;
    --select-selected-count-background: var(--select-selected-count-background-theme, var(--accent-default));
    --select-selected-count-color: var(--select-selected-count-color-theme, var(--white));
    --select-selected-count-min-w: 0;
    --select-arrow-icon-fz: 10px;
    --select-arrow-icon-hover-color: var(--select-arrow-icon-hover-color-theme, var(--accent-default));
    --select-icon-error-color: var(--select-icon-error-color-theme, var(--negative-default));
    --select-button-border-color-base: var(--select-button-border-color-base-theme, var(--secondary-default));
    --select-button-focused-bg: var(--select-button-focused-bg-theme, var(--bright));

    position: relative;
    display: inline-block;
    width: 100%;
    min-width: var(--select-min-width);
    height: var(--select-height);
    font-size: var(--select-fz);
    line-height: var(--select-line-height);
}

.select__multi {
    min-width: calc(var(--select-min-width) + 100px);
}

.select__multi-mini {
    width: auto;
    height: auto;
    min-width: 0;
}

.SelectButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: inherit;
    padding: var(--select-btn-p);
    background: transparent;
    border: var(--select-btn-border);
    border-radius: var(--border-radius-base);
    color: var(--select-btn-color);
    text-align: inherit;
    font-size: var(--select-btn-fz);
    line-height: var(--select-btn-lh);
    gap: 6px;
}

.form-group_opened .SelectButton {
    outline: none;
    cursor: pointer;
}

.form-group_opened .SelectButton {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}

/* ---------------------------- */

.form-group_focused .SelectButton {
    background-color: var(--select-button-focused-bg);
    border-color: var(--select-bth-focused-border-color);
    outline: none;
}

.SelectButton:focus-visible,
.form-group_focused .SelectButton,
.form-group_focused .SelectButton:focus-visible {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

/* ---------------------------- */

.form-group_invalid .SelectButton {
    border-color: var(--select-bth-invalid-border-color);
}

/* ---------------------------- */

.form-group_disabled .SelectButton {
    pointer-events: none;
}

/* ---------------------------- */

.SelectButton_with_placeholder {
    color: var(--select-bth-placeholder-color);
}

/* ---------------------------- */

.SelectButton_mini {
    width: var(--select-bth-mini-size);
    height: var(--select-bth-mini-size);
    padding: var(--select-bth-mini-p);
    border: var(--select-bth-mini-border);
    border-radius: var(--border-radius-base);
}

.SelectButton_mini .Icon.anticon__arrow {
    left: 50%;
    right: auto;
    transform: translate3d(-50%, -50%, 0);
}

.form-group_opened .SelectButton_mini {
    border-color: transparent;
    border-radius: var(--border-radius-base);
}

.form-group_opened .SelectButton_mini .Icon.anticon__arrow {
    transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}

.SelectButton_mini.SelectButton_checked {
    background-color: var(--select-bth-mini-checked-bc);
    border-color: var(--select-bth-mini-checked-border-color);
}

.form-group .SelectButton_mini.SelectButton_checked .Icon.anticon__arrow {
    color: var(--select-bth-mini-checked-arrow-color);
}

.SelectButton_mini.SelectButton_checked:hover {
    background-color: var(--select-bth-mini-checked-hover-bc);
    border-color: var(--select-bth-mini-checked-hover-border-color);
}

.form-group .SelectButton_mini.SelectButton_checked:hover .Icon.anticon__arrow {
    color: var(--select-bth-mini-checked-hover-arrow-color);
}

/* ---------------------------- */

.SelectButton_tooltiped {
    padding-right: var(--select-bth-tooltiped-pr);
}

.SelectButton_boxed {
    border-color: var(--select-bth-boxed-border-color);
}

/* ---------------------------- */

.select__dropdown__wrapper {
    --select-dd-wrapper-zi: 10;

    position: absolute;
    z-index: var(--select-dd-wrapper-zi);
    display: none;
}

.select__dropdown {
    --select-dd-border: var(--select-dd-border-theme);
    --select-dd-mt: -1px;
    --select-dd-border-radius: 5px;
    --select-dd-invalid-border-top-color: var(--select-dd-invalid-border-top-color-theme, var(--negative-default));
    --select-dd-hover-invalid-border-top-color: var(--select-dd-hover-invalid-border-top-color-theme, var(--negative-dark));
    --select-dd-mini-mt: 5px;
    --select-dd-bg: var(--select-dropdown-bg-theme, var(--bright));

    top: 100%;
    right: 0;
    background-color: var(--select-dd-bg);
    overflow: auto;
    width: 100%;
    border: var(--select-dd-border);
    margin-top: var(--select-dd-mt);
    border-radius: var(--select-dd-border-radius);
    transition: var(--transition);
    box-shadow: var(--box-shadow);
}

/* ---------------------------- */

.form-group_opened .select__dropdown__wrapper {
    display: block;
    width: 100%;
    margin-top: 10px;
}

.form-group_invalid .select__dropdown {
    border-top-color: var(--select-dd-invalid-border-top-color);
}

/* ---------------------------- */

.select__dropdown_mini {
    min-width: calc(var(--select-min-width) + 150px);
    border-radius: var(--border-radius-base);
    margin-top: var(--select-dd-mini-mt);
}

/* ---------------------------- */

.select__search {
    --select-search-w: calc(100% - 20px * 2);
    --select-search-m: var(--margin-base) var(--margin-base) calc(var(--margin-base) / 2);
    --select-search-p: 0 12px;
    --select-search-border: var(--border-width-base) var(--border-style-base) var(--select-dropdown-search-border-color-theme, var(--secondary-light));
    --select-search-hidden-mt: calc(var(--margin-base) / 2);
    --select-search-focus-border-color: var(--select-search-focus-border-color-theme, var(--accent-default));
    --select-dropdown-search-bg: var(--select-dropdown-search-bg-theme, var(--bright));
    --select-search-height: 40px;
    --select-search-fz: var(--input-font-size-base);
    --select-search-lh: var(--input-line-height-base);

    border: var(--select-search-border);
    border-radius: var(--border-radius-base);
    background-color: var(--select-dropdown-search-bg);
    height: var(--select-search-height);
    margin: var(--select-search-m);
    width: var(--select-search-w);
    padding: var(--select-search-p);
    font-size: var(--select-search-fz);
    line-height: var(--select-search-lh);
}

.select .select__search+.select__dropdown-options {
    overflow: hidden;
}

.select__dropdown .select__search[hidden]+.select__dropdown-options {
    margin-top: var(--select-search-hidden-mt);
}

.select .select__search+.select__dropdown-options > div {
    max-height: var(--select-dropdown-size);
}

.select__dropdown .select__search[hidden]+.select__dropdown-options > div {
    margin-top: var(--select-search-hidden-mt);
}

.select__search:focus {
    border-color: var(--select-search-focus-border-color);
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.select__option {
    line-height: 0;
    transition: var(--transition);
}

.select__option .checkbox-wrapper {
    display: flex;
    padding: var(--select-option-wrapper-p);
    color: var(--select-option-wrapper-color);
    font-size: var(--select-option-fz);
    line-height: var(--select-option-lh);
}

.select__option .checkbox-label {
    font-size: var(--select-option-fz);
    line-height: var(--select-option-lh);
}

.select__option:hover {
    background-color: var(--select-option-hover-bc);
    cursor: pointer;
}

.select__option.select__option-disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.select__option.select__option-disabled .checkbox-wrapper {
    cursor: not-allowed;
}

.select__controls {
    --select-controls-border-top: var(--border-base);
    --select-controls-p: 15px 0 0;
    --select-controls-m: 10px 20px 20px;
    --select-controls-btn-color: var(--select-controls-btn-color-theme, var(--secondary-default));
    --select-controls-btn-hover-color: var(--select-controls-btn-hover-color-theme, var(--secondary-dark));
    --select-controls-fz: 16px;

    border-top: var(--select-controls-border-top);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: var(--select-controls-p);
    margin: var(--select-controls-m);
    gap: 30px
}

.select__controls button {
    padding: 0;
    border: 0;
    background: none;
    color: var(--select-controls-btn-color);
    font-size: var(--select-controls-fz);
    cursor: pointer;
}

.select__controls button:first-child {
    font-weight: bold;
}

.select__controls button:hover {
    color: var(--select-controls-btn-hover-color);
}

.form-group_disabled .select {
    opacity: 0.5;
    pointer-events: none;
}

.select .select__dropdown-options > div {
    max-height: var(--select-max-height);
    overflow: auto;
    text-align: left;
}

.select .select__dropdown-options .Loader-wrapper {
    overflow: hidden;
    margin-bottom: var(--select-loader-mb);
}

.selected__values {
    overflow: hidden;
    display: block;
    height: var(--select-selected-values-h);
    width: var(--select-selected-values-w);
    line-height: var(--select-selected-values-lh);
    white-space: nowrap;
    text-overflow: ellipsis;
}

.TimePicker .selected__single {
    width: 100%;
}

.selected__count {
    padding: var(--select-selected-count-p);
    background: var(--select-selected-count-background);
    color: var(--select-selected-count-color);
    font-weight: bold;
    border-radius: var(--border-radius-count-box);
}

.select .Icon {
    position: absolute;
    right: 11px;
    top: 50%;
    display: block;
    transform: translate3d(0, -50%, 0);
    transition: var(--transition);
}

.select .Icon.anticon__arrow {
    font-size: var(--select-arrow-icon-fz);
}

.form-group_opened .select .Icon.anticon__arrow,
.select .Icon.anticon__arrow:hover:visited,
.select .Icon.anticon__arrow:visited {
    color: var(--select-arrow-icon-hover-color);
    transform: translate3d(0, -50%, 0) rotate(-180deg);
}

.select .Icon.anticon__error {
    right: 30px;
    color: var(--select-icon-error-color);
}

.select__option:hover .checkbox-wrapper {
    background: var(--select-option-hover-bc);
    color: var(--select-option-hover-color);
}

.select__option:focus-visible .checkbox-wrapper,
.select__option.select__option-active .checkbox-wrapper {
    background: var(--select-option-hover-bc);
    color: var(--select-option-hover-color);
}

.select .Loader-wrapper {
    height: 60px;
}
