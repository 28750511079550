@import './styles/placements/bottom.css';
@import './styles/placements/right.css';
@import './styles/placements/top.css';
@import './styles/placements/left.css';

.Popover {
    --tooltip-arrow-width: 7px;
    --popover-arrow-border-width: 5px;
    --popover-inner-content-border-radius: 5px;
    --popover-arrow-shadow-width: 3px;
    --popover-arrow-shadow-width-sqrt: 3px;
    --popover-arrow-offset-vertical: 12px;
    --popover-arrow-offset-horizontal: 16px;
    --popover-arrow-distance: 15px;
    --popover-fz: 14px;
    --popover-lh: 1.5;
    --popover-rc-tooltip-color: var(--popover-rc-tooltip-color-theme, var(--text-color));
    --popover-rc-tooltip-fz: var(--font-size-base);
    --popover-rc-tooltip-fv: var(--font-variant-base);
    --popover-rc-tooltip-lh: var(--line-height-base);
    --popover-rc-tooltip-font-feature-settings: var(--font-feature-settings-base);
    --popover-rc-tooltip-inner-bc: var(--popover-rc-tooltip-inner-bc-theme, var(--background-content));
    --popover-rc-tooltip-inner-br: var(--border-radius-base);
    --popover-rc-tooltip-arrow-w: var(--tooltip-arrow-width);
    --popover-rc-tooltip-arrow-h: var(--tooltip-arrow-width);
    --popover-rc-tooltip-inner-content-bs: var(--popover-box-shadow-theme, none);
    --popover-rc-tooltip-inner-content-border: var(--popover-box-border-theme, --border-base);
    --popover-zi: 1030;
    --tooltip-distance: calc(var(--tooltip-arrow-width) - 1px + 4px);
    --popover-border-color: var(--popover-border-color-theme, var(--border-color));
    --popover-box-shadow: var(--secondary-light, var(--secondary-light));

    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--popover-zi);
    box-sizing: border-box;
    font-size: var(--popover-fz);
    font-variant: tabular-nums;
    line-height: var(--popover-lh);
    font-feature-settings: 'tnum';
    font-weight: normal;
    white-space: normal;
    text-align: left;
    cursor: auto;
    user-select: text;
}

.Popover .rc-tooltip-inner {
    border-color: var(--popover-border-color);
}

.rc-tooltip {
    box-sizing: border-box;
    color: var(--popover-rc-tooltip-color);
    font-size: var(--popover-rc-tooltip-fz);
    font-variant: var(--popover-rc-tooltip-fv);
    list-style: none;
    line-height: var(--popover-rc-tooltip-lh);
    font-feature-settings: var(--popover-rc-tooltip-font-feature-settings);
}

.rc-tooltip-hidden {
    display: none;
}

.rc-tooltip-inner {
    position: relative;
    background-color: var(--popover-rc-tooltip-inner-bc);
    border-radius: var(--popover-inner-content-border-radius);
    background-clip: padding-box;
    box-shadow: none;
}

.rc-tooltip-arrow {
    z-index: 9;
    position: absolute;
    display: block;
    width: var(--popover-rc-tooltip-arrow-w);
    height: var(--popover-rc-tooltip-arrow-h);
    background: transparent;
    border-style: solid;
    border-width: var(--popover-arrow-border-width);
    pointer-events: none;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow,
.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    transform: translateX(calc(1px - var(--tooltip-arrow-width)));
}

.rc-tooltip-placement-bottom .rc-tooltip-inner,
.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
.rc-tooltip-placement-bottomRight .rc-tooltip-inner,
.rc-tooltip-placement-top .rc-tooltip-inner,
.rc-tooltip-placement-topLeft .rc-tooltip-inner,
.rc-tooltip-placement-topRight .rc-tooltip-inner {
    transform: translateX(calc(var(--tooltip-arrow-width) / 2));
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: 0;
}

.rc-tooltip-inner-content {
    padding: 0;
    box-shadow: var(--popover-rc-tooltip-inner-content-bs);
    border-radius: var(--popover-inner-content-border-radius);
    border: var(--popover-rc-tooltip-inner-content-border);
}

.rc-tooltip-arrow:after {
    position: absolute;
    left: calc(var(--tooltip-arrow-width) * -1 - 3px);
    z-index: 1061;
    display: block;
    overflow: visible;
    width: 0;
    height: 0;
    background: none;
    content: '';
    border-style: solid;
    border-color: transparent;
}
