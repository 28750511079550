.PieChart {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
}

.PieChart .highcharts-pie {
    --pie-chart-container-min-width: 225px;
    --pie-chart-container-min-width-tablet: 250px;
    --pie-chart-container-width: 33%;
    --pie-chart-container-width-tablet: 50%;
    --pie-chart-size: 181px;
    --pie-chart-single-allocation-size: 100px;
    --pie-chart-point-stroke: var(--pie-chart-point-stroke-theme, var(--bright));
    --pie-chart-colors-0: var(--pie-chart-colors-0-theme, var(--primary-default));
    --pie-chart-colors-1: var(--pie-chart-colors-1-theme, var(--accent-default));
    --pie-chart-colors-2: var(--pie-chart-colors-2-theme, var(--accent-lighter));
    --pie-chart-colors-3: var(--pie-chart-colors-3-theme, var(--secondary-light));
    --pie-chart-colors-4: var(--pie-chart-colors-4-theme, var(--accent-light));
    --pie-chart-colors-5: var(--pie-chart-colors-5-theme, var(--accent-dark));
    --pie-chart-colors-6: var(--pie-chart-colors-6-theme, var(--secondary-default));
    --pie-chart-colors-7: var(--pie-chart-colors-7-theme, var(--positive-lighter));
    --pie-chart-colors-8: var(--pie-chart-colors-8-theme, var(--positive-default));
    --pie-chart-colors-9: var(--pie-chart-colors-9-theme, var(--positive-darker));
    --pie-chart-single-fill: var(--pie-chart-single-fill-theme, var(--secondary-lighter));
    --pie-chart-subtitle-color: var(--pie-chart-subtitle-color-theme, var(--primary-default));
    --pie-chart-subtitle-fz: 18px;
    --pie-chart-color: var(--pie-chart-color-theme, var(--primary-default));
    --pie-chart-legend-p: calc(var(--padding-base) / 3) 0;
    --pie-chart-legend-point-size: 14px;
    --pie-chart-legend-point-mr: calc(var(--margin-base) / 2);
    --pie-chart-legend-fz: 16px;
    --pie-chart-legend-lh: 20px;
    --pie-chart-legend-value-lh: 19px;
    --pie-chart-legend-mr: calc(var(--margin-base) / 2 * 3);
    --pie-chart-legend-mb: var(--margin-base);
    --pie-chart-legend-name-mb: 5px;
    --pie-chart-legend-name-max-w: 126px;
    --pie-chart-legend-percent-ml: 12px;
    --pie-chart-legend-value-color: var(--pie-chart-legend-value-color-theme, var(--secondary-default));

    display: flex;
    justify-content: stretch;
    align-items: center;
    min-width: var(--pie-chart-container-min-width);
    width: var(--pie-chart-container-width);
}

@media only screen and (max-width: 880px) {
    .PieChart .highcharts-pie {
        min-width: var(--pie-chart-container-min-width-tablet);
        width: var(--pie-chart-container-width-tablet);
    }
}

@media only screen and (max-width: 540px) {
    .PieChart .highcharts-pie {
        flex-direction: column;
    }

    .PieChart .highcharts-pie .legend {
        height: auto;
    }
}

.PieChart .highcharts-pie .highcharts-react-container {
    overflow: visible !important;
    min-width: calc(var(--pie-chart-size) + 20px);
    min-height: calc(var(--pie-chart-size) + 20px);
    width: calc(var(--pie-chart-size) + 20px);
    height: calc(var(--pie-chart-size) + 20px);
}

.PieChart .highcharts-pie .highcharts-container {
    overflow: visible !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PieChart .highcharts-pie .highcharts-root {
    overflow: visible !important;
}

.PieChart .highcharts-pie .highcharts-background {
    fill: transparent;
}

.PieChart .highcharts-pie .highcharts-subtitle {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
    z-index: -1;
    color: var(--pie-chart-subtitle-color);
    font-family: var(--body-font-family);
    text-align: center;
    font-weight: bold;
    font-size: var(--pie-chart-subtitle-fz);
    line-height: var(--pie-chart-subtitle-fz);
}

.highcharts-pie-series .highcharts-point {
    stroke: var(--pie-chart-point-stroke);
}

.PieChart .highcharts-pie .highcharts-color-0 {
    fill: var(--pie-chart-colors-0);
}

.PieChart .highcharts-pie .highcharts-color-1 {
    fill: var(--pie-chart-colors-1);
}

.PieChart .highcharts-pie .highcharts-color-2 {
    fill: var(--pie-chart-colors-2);
}

.PieChart .highcharts-pie .highcharts-color-3 {
    fill: var(--pie-chart-colors-3);
}

.PieChart .highcharts-pie .highcharts-color-4 {
    fill: var(--pie-chart-colors-4);
}

.PieChart .highcharts-pie .highcharts-color-5 {
    fill: var(--pie-chart-colors-5);
}

.PieChart .highcharts-pie .highcharts-color-6 {
    fill: var(--pie-chart-colors-6);
}

.PieChart .highcharts-pie .highcharts-color-7 {
    fill: var(--pie-chart-colors-7);
}

.PieChart .highcharts-pie .highcharts-color-8 {
    fill: var(--pie-chart-colors-8);
}

.PieChart .highcharts-pie .highcharts-color-9 {
    fill: var(--pie-chart-colors-9);
}

.PieChart .highcharts-pie .legend {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    padding: var(--pie-chart-legend-p);
}

.PieChart .highcharts-pie .legend-point {
    position: relative;
    top: 1px;
    width: var(--pie-chart-legend-point-size);
    height: var(--pie-chart-legend-point-size);
    border-radius: var(--border-radius-circle);
    margin-right: var(--pie-chart-legend-point-mr);
}

.PieChart .highcharts-pie .legend-item {
    font-size: var(--pie-chart-legend-fz);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;
    margin-left: var(--pie-chart-legend-mr);
}

.PieChart .highcharts-pie .legend-item:not(:last-child) {
    margin-bottom: var(--pie-chart-legend-mb);
}

.PieChart .highcharts-pie .legend-item:nth-child(1) .legend-point {
    background-color: var(--pie-chart-colors-0);
}

.PieChart .highcharts-pie .legend-item:nth-child(2) .legend-point {
    background-color: var(--pie-chart-colors-1);
}

.PieChart .highcharts-pie .legend-item:nth-child(3) .legend-point {
    background-color: var(--pie-chart-colors-2);
}

.PieChart .highcharts-pie .legend-item:nth-child(4) .legend-point {
    background-color: var(--pie-chart-colors-3);
}

.PieChart .highcharts-pie .legend-item:nth-child(5) .legend-point {
    background-color: var(--pie-chart-colors-4);
}

.PieChart .highcharts-pie .legend-item:nth-child(6) .legend-point {
    background-color: var(--pie-chart-colors-5);
}

.PieChart .highcharts-pie .legend-item:nth-child(7) .legend-point {
    background-color: var(--pie-chart-colors-6);
}

.PieChart .highcharts-pie .legend-item:nth-child(8) .legend-point {
    background-color: var(--pie-chart-colors-7);
}

.PieChart .highcharts-pie .legend-item:nth-child(9) .legend-point {
    background-color: var(--pie-chart-colors-8);
}

.PieChart .highcharts-pie .legend-item:nth-child(10) .legend-point {
    background-color: var(--pie-chart-colors-9);
}

.PieChart .highcharts-pie .legend-label {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    flex-direction: row;
}

.PieChart .highcharts-pie .legend-name {
    color: var(--pie-chart-color);
    font-family: var(--accent-font-family);
    font-weight: var(--accent-font-weight);
    font-size: var(--pie-chart-legend-fz);
    line-height: var(--pie-chart-legend-lh);
    margin-bottom: var(--pie-chart-legend-name-mb);
    max-width: var(--pie-chart-legend-name-max-w);
}

.PieChart .highcharts-pie .legend-percent {
    float: right;
    color: var(--pie-chart-color);
    text-align: right;
    font-size: var(--pie-chart-legend-fz);
    line-height: var(--pie-chart-legend-lh);
    margin-left: var(--pie-chart-legend-percent-ml);
}

.PieChart .highcharts-pie .legend-value {
    color: var(--pie-chart-legend-value-color);
    font-size: var(--pie-chart-legend-fz);
    line-height: var(--pie-chart-legend-value-lh);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation .highcharts-react-container {
    min-width: calc(var(--pie-chart-single-allocation-size) + 20px);
    min-height: calc(var(--pie-chart-single-allocation-size) + 20px);
    width: calc(var(--pie-chart-single-allocation-size) + 20px);
    height: calc(var(--pie-chart-single-allocation-size) + 20px);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation .highcharts-point-inactive,
.PieChart .highcharts-pie.highcharts-pie_single-allocation .highcharts-series-inactive {
    opacity: 1 !important;
    transition: none !important;
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(1) .highcharts-color-0 {
    fill: var(--pie-chart-colors-0);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(2) .highcharts-color-0 {
    fill: var(--pie-chart-colors-1);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(3) .highcharts-color-0 {
    fill: var(--pie-chart-colors-2);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(4) .highcharts-color-0 {
    fill: var(--pie-chart-colors-3);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(5) .highcharts-color-0 {
    fill: var(--pie-chart-colors-4);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(6) .highcharts-color-0 {
    fill: var(--pie-chart-colors-5);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(7) .highcharts-color-0 {
    fill: var(--pie-chart-colors-6);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(8) .highcharts-color-0 {
    fill: var(--pie-chart-colors-7);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(9) .highcharts-color-0 {
    fill: var(--pie-chart-colors-8);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation:nth-child(10) .highcharts-color-0 {
    fill: var(--pie-chart-colors-9);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation .highcharts-color-1 {
    fill: var(--pie-chart-single-fill);
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation .legend {
    justify-content: center;
    height: auto;
    padding: 0;
}

.PieChart .highcharts-pie.highcharts-pie_single-allocation .legend-item {
    margin-left: 0;
}

@media only screen and (max-width: 540px) {
    .PieChart .highcharts-pie.highcharts-pie_single-allocation {
        flex-direction: row;
    }
}
