.Tabs {
    --tabs-card-height: 55px;
    --tabs-extra-item-margin: 5px 5px 5px 15px;
    --tabs-highlight-color: var(--tabs-highlight-color-theme, var(--primary-default));
    --tabs-disabled-color: var(--tabs-disabled-color-theme, var(--secondary-light));
    --tabs-padding-vertical: 18px;
    --tabs-ink-bar-height: var(--tabs-ink-bar-height-theme, 1px);
    --tabs-icons-size: 16px;
    --tabs-icons-color: var(--tabs-icons-color-theme, var(--secondary-default));
    --tabs-icons-padding-vertical: 18px;
    --tabs-icons-padding-horizontal: 10px;
    --tabs-nav-min-width: 250px;
    --tabs-scroll-height: 17px;
    --tabs-fz: 14px;
    --tabs-lh: 16px;
    --tabs-extra-item-fz: 18px;
    --tabs-tab-color: var(--tabs-tab-color-theme, var(--secondary-default));

    position: relative;
    overflow: unset;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: var(--tabs-fz);
    font-variant: tabular-nums;
    line-height: var(--tabs-lh);
    font-feature-settings: 'tnum';
    zoom: 1;
}

.Tabs-content {
    position: relative;
    width: 100%;
}

.Tabs-extra__item {
    display: inline-block;
    margin: var(--tabs-extra-item-margin);
    font-size: var(--tabs-extra-item-fz);
    line-height: 0;
    cursor: pointer;
}

.Tabs-extra__item:last-child {
    margin-right: 0;
}

.Tabs-extra__item > .Icon {
    color: var(--tabs-tab-color);
}

.Tabs-extra__item:hover > .Icon {
    color: var(--tabs-highlight-color);
}

.Tabs-extra__item_active > .Icon {
    color: var(--tabs-highlight-color);
}

.Tabs-extra__item .form-group {
    margin: 0;
}
