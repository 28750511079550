.Navigation {
    --navigation-z-index: 2000;
    --navigation-w: 240px;
    --navigation-h: 100vh;
    --navigation-p: calc(var(--margin-base) * 2);
    --navigation-bg: var(--navigation-bg-theme, var(--background-content));
    --navigation-border: var(--border-width-base) var(--border-style-base) var(--navigation-border-color-theme, var(--secondary-light));
    --navigation-link-fz: 18px;
    --navigation-link-lh: 3.33333333;
    --navigation-link-changes-color: var(--navigation-link-changes-color-theme, var(--accent-default));
    --navigation-link-home-color: var(--navigation-link-home-color-theme, var(--primary-default));
    --navigation-close-size: 16px;
    --navigation-close-color: var(--navigation-close-color-theme, var(--accent-default));
    --navigation-link-color: var(--navigation-link-color-theme, var(--secondary-default));
    --navigation-link-color-hover: var(--navigation-link-color-hover-theme, var(--secondary-dark));
    --navigation-link-color-active: var(--navigation-link-color-active-theme, var(--secondary-darker));

    z-index: var(--navigation-z-index);
    position: fixed;
    top: 0;
    bottom: 0;
    left: calc(var(--navigation-w) * -1);
    width: var(--navigation-w);
    height: var(--navigation-h);
    background-color: var(--navigation-bg);
    margin: 0;
    padding: var(--navigation-p);
    border-right: var(--navigation-border);
    overflow-y: auto;
    transition: var(--transition);
}

.Navigation ~ * {
    transition: var(--transition);
}

.Navigation_open {
    left: 0;
}

.Navigation_open ~ * {
    transform: translateX(var(--navigation-w));
}

.Navigation__link {
    --navigation-link-marker-size: 8px;

    display: block;
    color: var(--navigation-link-color);
    font-size: var(--navigation-link-fz);
    line-height: var(--navigation-link-lh);
}

.Navigation__link:hover {
    color: var(--navigation-link-color-hover);
}

.Navigation__link:active {
    color: var(--navigation-link-color-active);
}

.Navigation__link_changed:after {
    display: inline-block;
    width: var(--navigation-link-marker-size);
    height: var(--navigation-link-marker-size);
    vertical-align: super;
    border-radius: var(--border-radius-circle);
    background: var(--navigation-link-changes-color);
    content: ' ';
    margin-left: calc(var(--navigation-link-marker-size) / 3);
}

.Navigation__link_home {
    font-weight: bold;
    color: var(--navigation-link-home-color);
}

.Navigation__link_home:hover {
    color: var(--navigation-link-home-color);
}

.Navigation__button {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    overflow: hidden;
    padding: 0;
    margin-bottom: calc(var(--navigation-close-size) / 3);
    color: var(--navigation-close-color);
    background: none;
    border: 0;
    cursor: pointer;
}

.Navigation__button .Icon {
    font-size: var(--navigation-close-size);
}
