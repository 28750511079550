.Paragraph {
    --paragraph-fz: 14px;
    --paragraph-lh: 24px;
    --paragraph-secondary-color: var(--paragraph-secondary-color-theme, var(--secondary-default));
}

.Paragraph_primary,
.Paragraph_title,
.Paragraph_secondary {
    font-size: var(--paragraph-fz);
    line-height: var(--paragraph-lh);
}

.Paragraph_highlights {
    font-weight: bold;
}

.Paragraph_title {
    font-style: italic;
}

.Paragraph_secondary {
    color: var(--paragraph-secondary-color);
}
