.Accordion {
    --accordion-bg: var(--accordion-bg-theme, var(--background-content));
    --accordion-fz: var(--font-size-base);
    --accordion-lh: var(--line-height-base);
    --accordion-color: var(--accordion-color-theme, var(--primary-default));
    --accordion-border-color: var(--accordion-border-color-theme, var(--border-color));
    --accordion-border: var(--border-width-base) var(--border-style-base) var(--accordion-border-color);
    --accordion-header-p: var(--padding-base);
    --accordion-header-fz: 18px;
    --accordion-header-lh: 1.333;
    --accordion-header-fw: var(--accent-font-weight);
    --accordion-header-icon-mr: 15px;
    --accordion-header-icon-size: 20px;
    --accordion-header-icon-color: var(--accordion-header-icon-color-theme, var(--primary-default));
    --accordion-header-extra-ml: 10px;
    --accordion-header-extra-p: 4px 8px;
    --accordion-header-extra-h: auto;
    --accordion-header-extra-color: var(--accordion-header-extra-color-theme, var(--background-content));
    --accordion-header-extra-bg: var(--accordion-header-extra-bg-theme, var(--background-content));
    --accordion-content-p: 0 var(--padding-base) var(--padding-base);

    border-top: var(--accordion-border);
    border-bottom: 0;
    border-radius: var(--border-radius-base);
    box-sizing: border-box;
    background-color: var(--accordion-bg);
    color: var(--accordion-color);
    font-size: var(--accordion-fz);
    line-height: var(--accordion-lh);
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.AccordionPanel {
    border-bottom: var(--accordion-border);
}

.AccordionPanel-header {
    align-items: center;
    cursor: pointer;
    color: var(--accordion-color);
    display: flex;
    font-size: var(--accordion-header-fz);
    font-weight: var(--accordion-header-fw);
    line-height: var(--accordion-header-lh);
    padding: var(--accordion-header-p);
    position: relative;
    transition: var(--transition);
}

.AccordionPanel-header i {
    position: static;
    display: inline-block;
    color: var(--accordion-header-icon-color);
    font-size: var(--accordion-header-icon-size);
    font-style: normal;
    text-align: center;
    text-transform: none;
    transform: none;
    margin-right: var(--accordion-header-icon-mr);
    line-height: 0;
    margin-top: 0;
    transition: var(--transition);
}

.AccordionPanel-extra {
    float: none;
    display: flex;
    margin-left: var(--accordion-header-extra-ml);
}

.AccordionPanel-extra-counter {
    background-color: var(--accordion-header-extra-bg);
    border-radius: var(--border-radius-count-box);
    color: var(--accordion-header-extra-color);
    font-size: var(--accordion-fz);
    height: var(--accordion-header-extra-h);
    padding: var(--accordion-header-extra-p);
}

.AccordionPanel-content {
    position: relative;
    z-index: 1;
    overflow: hidden;
    color: var(--accordion-color);
    background-color: transparent;
    border-top: 0;
    transition: var(--transition);
    max-height: 0;
    transition-property: max-height;
}

.AccordionPanel-content-box {
    padding: var(--accordion-content-p);
    transition: none;
}

.AccordionPanel-content__open {
    transition-duration: 0s;
}

.AccordionPanel-content__active {
    height: auto;
    max-height: 1000rem;
    transition-property: max-height;
}
