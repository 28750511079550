.FileItem {
    --file-item-p: 0 5px 0 0;
    --file-item-fz: var(--font-size-base);
    --file-item-lh: 22px;
    --file-item-color: var(--file-item-color-theme, var(--primary-default));
    --file-item-remove-size: 16px;
    --file-item-remove-fz: 8px;
    --file-item-remove-bg: var(--file-item-remove-bg-theme, var(--negative-default));
    --file-item-remove-color: var(--file-item-remove-color-theme, var(--white));

    display: flex;
    align-items: center;
    font-size: var(--file-item-fz);
    line-height: var(--file-item-lh);
}

.FileItem .upload-list-item-content {
    display: inline-block;
    overflow: hidden;
    height: 100%;
    padding: var(--file-item-p);
    color: var(--file-item-color);
    white-space: nowrap;
    text-overflow: ellipsis;
}

.FileItem .action-delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--file-item-remove-size);
    height: var(--file-item-remove-size);
    padding: calc((var(--file-item-remove-size) - var(--file-item-remove-fz)) / 2);
    background: var(--file-item-remove-bg);
    border-radius: var(--border-radius-circle);
    font-size: var(--file-item-remove-fz);

}

.FileItem .action-delete > svg > path {
    fill: var(--file-item-remove-color);
}
