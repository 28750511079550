.Colors ul {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    padding: 0;
}

.Colors li {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    overflow: hidden;
    margin: 0 2% 2%;
    width: 20%;
    border-radius: 4px;

    /* stylelint-disable-next-line */
    box-shadow: 0 3px 10px 0 rgba(var(--accent-default-rgb), 0.1);
    padding-top: 70px;
}

.Colors span {
    display: block;
    overflow: hidden;
    padding: 10px 15px;
    background: var(--background-base);
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Colors_mini ul {
    flex-wrap: wrap;
    margin: 0;
    padding: 10px 0;
}

.Colors_mini li {
    position: relative;
    display: flex;
    flex-direction: initial;
    justify-content: flex-start;
    overflow: hidden;
    border-radius: 0;
    padding: 0 0 0 20px;
    margin: 0 10px 20px 0;
    box-shadow: none;
}

.Colors_mini li:before {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 21px;
    height: 21px;
    border: 1px solid var(--primary-default);
    content: '';
}

.Colors_mini span {
    padding: 0 0 0 10px;
    width: 100%;
}

.primary-default {
    background-color: var(--primary-default);
}

.primary-lighter {
    background-color: var(--primary-lighter);
}

.primary-light {
    background-color: var(--primary-light);
}

.primary-dark {
    background-color: var(--primary-dark);
}

.primary-darker {
    background-color: var(--primary-darker);
}

.secondary-default {
    background-color: var(--secondary-default);
}

.secondary-lighter {
    background-color: var(--secondary-lighter);
}

.secondary-light {
    background-color: var(--secondary-light);
}

.secondary-dark {
    background-color: var(--secondary-dark);
}

.secondary-darker {
    background-color: var(--secondary-darker);
}

.accent-default {
    background-color: var(--accent-default);
}

.accent-lighter {
    background-color: var(--accent-lighter);
}

.accent-light {
    background-color: var(--accent-light);
}

.accent-dark {
    background-color: var(--accent-dark);
}

.accent-darker {
    background-color: var(--accent-darker);
}

.positive-default {
    background-color: var(--positive-default);
}

.positive-lighter {
    background-color: var(--positive-lighter);
}

.positive-light {
    background-color: var(--positive-light);
}

.positive-dark {
    background-color: var(--positive-dark);
}

.positive-darker {
    background-color: var(--positive-darker);
}

.negative-default {
    background-color: var(--negative-default);
}

.negative-lighter {
    background-color: var(--negative-lighter);
}

.negative-light {
    background-color: var(--negative-light);
}

.negative-dark {
    background-color: var(--negative-dark);
}

.negative-darker {
    background-color: var(--negative-darker);
}

.warning-yellow {
    background-color: var(--warning-yellow);
}

.warning-yellow-light {
    background-color: var(--warning-yellow-light);
}

.white {
    background-color: var(--white);
}

.line-chart-goal-color {
    background-color: var(--accent-default);
}

.bright {
    background-color: var(--bright);
}

.background-content {
    background-color: var(--background-content);
}

.background-base {
    background-color: var(--background-base);
}
