.Number-input-wrapper {
    --number-input-h: 30px;
    --number-input-p: 0 12px;
    --number-input-error-hover-color: var(--number-input-error-hover-color-theme, var(--negative-dark));
    --number-input-border-color-base: var(--number-input-border-color-base-theme, var(--secondary-light));
    --number-input-hover-border-color: var(--number-input-hover-border-color-theme, var(--secondary-default));
    --number-input-affix-suffix-color: var(--number-input-affix-suffix-color-theme, var(--primary-default));
    --number-input-disabled-affix-suffix-color: var(--number-input-disabled-affix-suffix-color-theme, var(--secondary-default));
    --number-input-affix-hover-bc: var(--number-input-affix-hover-bc-theme, var(--secondary-default));
    --number-input-hover-bc: var(--number-input-hover-border-color);
    --number-input-focus-bs: none;
    --number-input-invalid-input-bc: var(--number-input-invalid-input-bc-theme, var(--negative-default));
    --numbert-input-disabled-color: var(--numbert-input-disabled-color-theme, var(--primary-default));
    --number-input-invalid-rc-tooltiped-text-color: var(--number-input-invalid-rc-tooltiped-text-color-theme, var(--negative-default));
    --number-input-text-input_invalid-hover-bc: var(--number-input-error-hover-color);
    --number-input-group-border: var(--border-width-base) solid var(--number-input-border-color-base);
    --number-input-group-br: var(--border-radius-base);
    --number-input-invalid-group-bc: var(--number-input-invalid-group-bc-theme, var(--negative-default));
    --number-input-group-hover-bc: var(--number-input-border-color-base);
    --number-input-group-disabled-hover-bc: var(--number-input-border-color-base);
    --number-input-group-invalid-hover-bc: var(--number-input-error-hover-color);
    --number-input-group-addon-color: var(--number-input-group-addon-color-theme, var(--secondary-default));
    --number-input-group-addon-btn-color: var(--number-input-group-addon-btn-color-theme, var(--primary-default));
    --number-input-group-addon-btn-h: var(--line-height-base);
    --number-input-group-addon-btn-hover-color: var(--number-input-group-addon-btn-hover-color-theme, var(--primary-darker));
    --number-input-addon-after-border-before-h: calc(var(--number-input-h) - 12px);
    --number-input-addon-after-border-before-bc: var(--text-input-border-color-theme, var(--input-border-color-base));
    --number-input-select__dropdown-w: calc(100% + 1px);
    --number-input-select__dropdown-select-w: 70px;
    --number-input-select-box-checkbox-wrapper-p: 12px 10px;
}

.Number-input-wrapper .TextInput-affix-wrapper .rc-tooltiped-text {
    margin-right: 0;
}

.Number-input-wrapper .TextInput-affix-wrapper.TextInput-suffix {
    right: 10px;
    color: var(--number-input-affix-suffix-color);
}

.Number-input-wrapper .form-group_invalid .TextInput-affix-wrapper.TextInput-suffix {
    color: var(--number-input-invalid-input-bc);
}

.Number-input-wrapper .form-group_disabled .TextInput-affix-wrapper.TextInput-suffix {
    color: var(--number-input-disabled-affix-suffix-color);
}

.Number-input-wrapper .form-group_disabled .TextInput-affix-wrapper {
    opacity: 0.5;
    pointer-events: none;
}

.Number-input-wrapper .TextInput:focus,
.Number-input-wrapper .TextInput_focused,
.Number-input-wrapper .TextInput_focused .TextInput-input,
.Number-input-wrapper .TextInput_focused .TextInput-group:hover {
    box-shadow: var(--number-input-focus-bs);
}

.Number-input-wrapper .TextInput_invalid input {
    border-color: var(--number-input-invalid-input-bc);
}

.Number-input-wrapper .TextInput_invalid .rc-tooltiped-text {
    color: var(--number-input-invalid-rc-tooltiped-text-color);
}

.Number-input-wrapper .TextInput[disabled],
.Number-input-wrapper .form-group_disabled .TextInput,
.Number-input-wrapper .TextInput_disabled {
    color: var(--numbert-input-disabled-color);
    box-shadow: none;
    background: transparent;
}

.Number-input-wrapper  .form-group_disabled .TextInput-group-wrapper .TextInput-group {
    cursor: not-allowed;
}

.Number-input-wrapper .TextInput_invalid .TextInput-group {
    border-color: var(--number-input-invalid-group-bc);
}

.Number-input-wrapper .TextInput-group-addon {
    border: 0;
    background-color: transparent;
    color: var(--number-input-group-addon-color);
    position: relative;
}

.Number-input-wrapper .with-select-box .TextInput-group-addon {
    border-left: 1px solid var(--number-input-border-color-base);
}

.Number-input-wrapper .TextInput-group-addon button {
    padding: 0;
    height: var(--number-input-group-addon-btn-h);
    min-width: auto;
    color: var(--number-input-group-addon-btn-color);
    text-decoration: underline;
    border: none;
}

.Number-input-wrapper .TextInput-group-addon button:hover {
    color: var(--number-input-group-addon-btn-hover-color);
}

.Number-input-wrapper .form-group_disabled .TextInput-group-addon {
    opacity: 0.5;
}

.Number-input-wrapper .TextInput:focus-visible {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.Number-input-wrapper .with-select-box .SelectButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 0;
    text-align: center;
    text-decoration: none;
}

.Number-input-wrapper .with-select-box .select__dropdown {
    margin-top: 0;
    right: -1px;
    width: var(--number-input-select__dropdown-w);
}

.Number-input-wrapper .with-select-box .select {
    min-width: var(--number-input-select__dropdown-select-w);
}

.Number-input-wrapper .with-select-box .select__option .checkbox-wrapper {
    padding: var(--number-input-select-box-checkbox-wrapper-p);
}
