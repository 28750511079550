.Form-control-label {
    --label-m: 0 0 calc(var(--margin-base) / 2);
    --label-fz: var(--label-font-size-theme);
    --label-lh: var(--label-line-height-theme);
    --label-color: var(--label-color-theme, var(--primary-default));
    --label-icon-p: 3px;
    --label-icon-fz: 10px;
    --label-icon-color: var(--label-icon-color-theme, var(--secondary-default));

    align-items: flex-start;
    color: var(--label-color);
    display: block;
    justify-content: flex-start;
    line-height: var(--label-lh);
    font-family: var(--accent-font-family);
    font-size: var(--label-fz);
    font-weight: var(--label-font-weight-theme, var(--accent-font-weight));
    margin: var(--label-m);
}

.Form-control-label.required span:after {
    content: '*';
}

.Form-control-label .Icon {
    font-size: var(--label-icon-fz);
    top: calc(-1 * var(--label-icon-p));
    position: relative;
    display: inline-block;
    padding: var(--label-icon-p);
    color: var(--label-icon-color);
}

.form-group_disabled .Form-control-label {
    color: var(--label-color);
    opacity: 0.5;
}
