.Table-wrapper {
    --table-cell-expand-size: 34px;
    --table-cell-expand-fw: var(--accent-font-weight);
    --table-cell-vertical-indents: 19px;
    --table-cell-horizontal-padding: 5px;
    --table-cell-horizontal-indents: 8px;
    --table-sort-height: 20px;
    --table-sort-width: 20px;
    --table-filter-width: 20px;
    --table-dropdown-spacing: 26px;
    --table-mini-cell-vertical-indents: 5px;
    --table-mini-cell-horizontal-indents: 8px;
    --checkbox-size: 16px;
    --table-border: var(--border-width-base) var(--border-style-base) var(--table-border-color-theme, var(--border-color));
    --table-primary-color: var(--table-primary-color-theme, var(--primary-default));
    --table-pagination-p: 30px 0 0;
    --table-border-radius: 2px 2px 0 0;
    --table-scrollable-w: calc(100% - 16px);
    --table-header-dd-tooltip-content-mt: calc(var(--margin-base) / 2);
    --table-header-dd-menu-item-min-w: 150px;
    --table-row-selected-bg: var(--table-row-selected-bg-theme, var(--secondary-lighter));
    --table-row-selected-color: var(--table-row-selected-color-theme, var(--table-primary-color));
    --table-linked: var(--table-linked-theme, var(--primary-default));
    --table-linked-hover: var(--table-linked-hover-theme, var(--secondary-default));

    /* thead */
    --table-thead-tr: all 0.3s, height 0s;
    --table-thead-tr-th-p: 16px 0;
    --table-thead-tr-th-lh: calc(var(--table-thead-tr-th-fz) + 10px);
    --table-thead-tr-th-min-w: 80px;
    --table-thead-tr-th-fz: 16px;
    --table-thead-tr-th-transition: 16px;
    --table-thead-tr-th-sell-sorter-fz: 10px;
    --table-thead-tr-th-sell-sorter-lh: 18px;
    --table-thead-tr-th-sell-sorter-transition: all 0s;
    --table-thead-tr-th-sell-sorter-ml: 0.57142857em;
    --table-thead-tr-th-sell-sorter-icon-w: 14px;
    --table-thead-tr-th-sell-sorter-icon-h: 10px;
    --table-thead-tr-th-sell-sorter-icon-mr: 0.57142857em;
    --table-thead-tr-th-select-dd-w: 250px;
    --table-thead-color: var(--table-header-color-theme, var(--primary-default));
    --table-thead-hover-color: var(--table-header-hover-color-theme, var(--primary-default));
    --table-thead-border: 1px solid var(--primary-default);

    /* tbody */
    --table-tbody-tr-transition: all 0.3s, height 0s;
    --table-tbody-tr-td-transition: all 0.3s, border 0s;
    --table-tbody-tr-td-p: var(--margin-base) 0;
    --table-tbody-tr-td-fz: 14px;
    --table-tbody-tr-td-lh: calc(var(--table-tbody-tr-td-fz) + 10px);
    --table-tr-td-expand-icon-pl: 10px;
    --table-tr-td-expand-icon-pr: 10px;
    --table-tr-td-expand-icon-i-fz: 14px;
    --table-tr-td-expand-icon-i-lh: 0;
    --table-tr-td-expand-icon-i-color: var(--table-tr-td-expand-icon-i-color-theme, var(--primary-default));
    --table-no-header-tr-td-lh: 1.57142857;
    --table-no-header-tr-td-p: 13px 0;
    --table-tr-td-no-data-color: var(--table-tr-td-no-data-color-theme, var(--secondary-light));

    /* tfoot */
    --table-tfoot-tr-transition: all 0.3s, height 0s;
    --table-tfoot-tr-th-p: var(--margin-base) 0;
    --table-tfoot-tr-th-fz: 14px;
    --table-tfoot-tr-th-lh: 1.14285714;
    --table-tfoot-tr-th-pr: 8px;
    --table-tfoot-border: 2px solid var(--primary-default);

    /* mini */
    --table-mini-tr-td-p: 13px 0;
    --table-mini-tr-td-lh: 1.57142857;
    --table-mini-tr-td-first-pl: var(--padding-base);
    --table-mini-tr-td-last-pr: var(--padding-base);
    --table-mini-tr-td-not-last-pr: 10px;

    /* header mini */
    --table-header-mini-fz: 14px;
    --table-header-mini-lh: 1.57142857;
    --table-header-mini-span-pr: 5px;
    --table-header-mini-span-lh: 30px;
    --table-header-mini-select-dd-w: 100px;
    --table-header-mini-select-values-pr: var(--padding-base);
    --table-header-mini-checkbox-wrapper-p: 12px 8px;
    --table-header-mini-sorter-icon-m: 0 0 0 2px;
    --table-header-mini-dd-title-mr: calc(var(--margin-base) / -2);
    --table-header-mini-dd-title-arrow-fz: 10px;
    --table-header-mini-dd-title-arrow-m: calc(var(--margin-base) / -2) -5px;

    /* table draggable */
    --table-draggable-th-transition: all 130ms ease 0s;
    --table-draggable-drag-element-color: var(--table-draggable-drag-element-color-theme, var(--secondary-default));
    --table-draggable-drag-element-h: 30px;
    --table-draggable-drag-element-p: 3px var(--margin-base);
    --table-draggable-drag-element-border: var(--border-width-base) var(--border-style-base) var(--table-draggable-drag-element-border-color-theme, var(--secondary-default));
    --table-draggable-drag-min-w: 100px;
    --table-draggable-drag-bc: var(--table-draggable-drag-bc-theme, var(--secondary-lighter));

    /* Renders */
    --table-tbody-cell-current-new-first-mb: 5px;
    --table-tbody-cell-current-new-span-first-pr: 10px;
    --table-tbody-cell-tooltipped-pr: 30px;
    --table-tbody-cell-tooltipped-span-mr: 4px;
    --table-tbody-tr-cell-positive-color: var(--table-tbody-tr-cell-positive-color-theme, var(--positive-default));
    --table-tbody-tr-cell-negative-color: var(--table-tbody-tr-cell-negative-color-theme, var(--negative-default));
    --table-tbody-cell-icon-color: var(--table-tbody-cell-icon-color-theme, var(--secondary-default));
    --table-tbody-cell-icon-i-ml: var(--margin-base);
    --table-tbody-cell-with-button-icon-btn-pt: 5px;
    --table-tbody-cell_with-checkbox-pt: 7px;
    --table-tbody-cell_with-checkbox-pr: 10px;
    --table-tbody-cell_with-checkbox-inner-size: 24px;
    --table-tbody-cell_with-checkbox-inner-after-size: 12px;
    --table-tbody-cell_with-message-p-fz: 16px;
    --table-tbody-cell_with-message-new-mt: -6px;
    --table-tbody-cell_with-message-new-before-size: 6px;
    --table-tbody-cell_with-message-new-before-border-radius: 6px;
    --table-tbody-cell_with-message-new-before-bc: var(--table-tbody-cell_with-message-new-before-bc-theme, var(--accent-default));
    --table-tbody-cell_with-message-date-replied-i-pb: 5px;
    --table-tbody-cell_with-message-date-replied-i-color: var(--table-tbody-cell_with-message-date-replied-i-color-theme, var(--secondary-default));
    --table-tbody-row-selection-w: calc(var(--checkbox-size) * 2);
    --table-tbody-row-selection-pl: 2px;
    --table-thead-th-column-fixed-w: 25px;

    position: relative;
    width: 100%;
    scrollbar-width: none;
    overflow-y: auto;
}

.Content-box .Table-wrapper {
    --table-bg: var(--table-bg-theme, var(--background-content));
}

.Accordion .AccordionPanel .Table-wrapper {
    --table-bg: var(--table-accordion-bg-theme, var(--bright));
}

.Table-wrapper .Pagination {
    padding: var(--table-pagination-p);
    margin: 0;
    border-top: var(--table-border);
}

.Table {
    width: 100%;
    text-align: left;
    border-radius: var(--table-border-radius);
    border-collapse: collapse;
}

.Table.Table-no-header thead {
    display: none;
}

/* Table scrollable */

.Table-wrapper .Table-content {
    overflow-x: auto;
}

.Table-wrapper.scrollable .Table-content {
    overflow-y: auto;
    display: block;
    width: 100%;
}

.Table-wrapper.scrollable .Table-content .Table {
    width: 100%;
    table-layout: fixed;
}

.Table-wrapper.scrollable > .Table {
    width: var(--table-scrollable-w);
}

/* Table head */

.Table thead > tr {
    background: transparent !important;
    border-bottom: var(--table-thead-border);
    transition: var(--table-thead-tr);
}

.Table thead > tr th {
    position: relative;
    padding: var(--table-thead-tr-th-p);
    color: var(--table-thead-color);
    background: transparent;
    line-height: var(--table-thead-tr-th-lh);
    font-weight: bold;
    min-width: var(--table-thead-tr-th-min-w);
    white-space: nowrap;
    font-size: var(--table-thead-tr-th-fz);
    border-bottom: none;
    transition: var(--table-thead-tr-th-transition);
}

.Table thead > tr th > .header-column {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table thead > tr th.column-sort:hover,
.Table thead > tr th.column-filter:hover {
    color: var(--table-thead-hover-color);
}

.Table thead > tr th:not(:last-child) {
    padding-right: var(--table-cell-horizontal-indents);
}

.Table thead > tr th.column-sort {
    cursor: pointer;
}

.Table thead > tr th:hover .table-cell__sorter {
    opacity: 0.7;
}

.Table thead:hover > tr th.column-sort.sorted {
    color: var(--table-primary-color);
    font-weight: bold;
}

.Table thead > tr th.column-sort.sorted {
    color: var(--table-primary-color);
}

.Table thead > tr th.column-sort.sorted .table-cell__sorter {
    opacity: 1;
}

.Table thead > tr th .table-cell__sorter {
    background: var(--secondary-lighter);
    border: none;
    border-radius: 4px;
    color: var(--table-primary-color);
    cursor: pointer;
    font-size: var(--table-thead-tr-th-sell-sorter-fz);
    height: var(--table-sort-height);
    line-height: var(--table-thead-tr-th-sell-sorter-lh);
    margin-left: var(--table-thead-tr-th-sell-sorter-ml);
    opacity: 0;
    padding: 0;
    position: relative;
    top: -2px;
    transition: var(--table-thead-tr-th-sell-sorter-transition);
    width: var(--table-sort-width);
}

.Table thead > tr th .table-cell__sorter .Icon {
    width: var(--table-thead-tr-th-sell-sorter-icon-w);
    height: var(--table-thead-tr-th-sell-sorter-icon-h);
    color: var(--table-primary-color);
}

.Table thead > tr th:not(:first-child):last-child .table-cell__sorter {
    margin-left: 0;
    margin-right: var(--table-thead-tr-th-sell-sorter-icon-mr);
}

.Table thead > tr th .table-cell__sorter-asc {
    transform: scaleY(-1);
}

.Table thead > tr th .select__dropdown {
    width: var(--table-thead-tr-th-select-dd-w);
    font-weight: normal;
}

.Table-filters .rc-tooltip-inner {
    min-height: 0;
    padding: 0;
    margin-top: -1px;
    border: none;
    box-shadow: none;
}

.Table-filters .select__dropdown {
    margin-top: 0;
    position: inherit;
}

.Table-filters.row-filters .select__dropdown {
    right: -24px;
}

/* Table body */
.Table tbody > tr {
    position: relative;
    transition: var(--table-tbody-tr-transition);
}

.Table tbody > tr.row-level-0,
.Table tbody > tr.row-level-1 td {
    border-top: var(--table-border);
}

.Table tbody > tr.row-level-0:first-child {
    border-top-color: transparent;
}

.Table.Table-with-clickable-row tr {
    cursor: pointer;
}

.Table tr.row__selected,
.Table tr.row__selected:hover {
    background-color: var(--table-row-selected-bg) !important;
}

.Table tr.row__selected * {
    color: var(--table-row-selected-color);
}

.Table tbody > tr td {
    padding: 20px 0;
    color: var(--table-primary-color);
    font-size: var(--table-tbody-tr-td-fz);
    line-height: var(--table-tbody-tr-td-lh);
    border-bottom: none;
    transition: var(--table-tbody-tr-td-transition);
}

.Table tbody > tr td.cell-clickable {
    display: flex;
    cursor: pointer;
}

.Table tbody > tr td:not(:last-child) {
    padding-right: var(--table-cell-horizontal-indents);
}

.Table tr:not(.row-level-0) td:first-child {
    margin-left: var(--table-cell-expand-size);
    overflow: hidden;
    width: calc(100% - var(--table-cell-expand-size));
}

.Table .cell-expandable *[class*='table-cell'] {
    font-weight: var(--table-cell-expand-fw);
}

.Table tr.row-level-1 td:first-child,
.Table tbody > tr td.cell-expandable {
    display: flex;
}

.Table tbody > tr td.text-right.cell-sortable {
    padding-right: calc(var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td:last-child.text-right.cell-sortable {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table tbody > tr td:not(:last-child).text-right.cell-sortable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td.text-right.cell-filterable {
    padding-right: calc(var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td:last-child.text-right.cell-filterable {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table tbody > tr td:not(:last-child).text-right.cell-filterable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td.text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tbody > tr td:not(:last-child).text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding) + var(--table-cell-horizontal-indents));
}

.Table tbody > tr td:last-child.text-right.cell-sortable.cell-filterable {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table tr td .expand-icon {
    display: inline-block;
    width: var(--table-cell-expand-size);
    padding-left: var(--table-tr-td-expand-icon-pl);
    padding-right: var(--table-tr-td-expand-icon-pr);
}

.Table tr td .expand-icon i {
    color: var(--table-tr-td-expand-icon-i-color);
    cursor: pointer;
    font-size: var(--table-tr-td-expand-icon-i-fz);
    line-height: var(--table-tr-td-expand-icon-i-lh);
}

.Table.Table-no-header tr td {
    padding: var(--table-no-header-tr-td-p);
    line-height: var(--table-no-header-tr-td-lh);
}

.Table tr td.no-data {
    color: var(--table-tr-td-no-data-color);
    text-align: center;
}

.Table tr td .expand-cell {
    margin-right: calc(var(--table-cell-expand-size) * -1);
    width: 100%;
}

/* Table foot */
.Table tfoot > tr {
    position: relative;
    transition: var(--table-tfoot-tr-transition);
    border-top: var(--table-tfoot-border);
}

.Table tfoot > tr th {
    padding: var(--table-tfoot-tr-th-p);
    color: var(--table-primary-color);
    font-size: var(--table-tfoot-tr-th-fz);
    line-height: var(--table-tfoot-tr-th-lh);
    border-bottom: none;
    transition: var(--table-tfoot-tr-transition);
}

.Table tfoot > tr th:not(:last-child) {
    padding-right: var(--table-tfoot-tr-th-pr);
}

.Table tfoot > tr th.text-right.cell-sortable {
    padding-right: calc(var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th:last-child.text-right.cell-sortable {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table tfoot > tr th:not(:last-child).text-right.cell-sortable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-sort-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th.text-right.cell-filterable {
    padding-right: calc(var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th:last-child.text-right.cell-filterable {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table tfoot > tr th:not(:last-child).text-right.cell-filterable {
    padding-right: calc(var(--table-cell-horizontal-indents) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th.text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding));
}

.Table tfoot > tr th:last-child.text-right.cell-sortable.cell-filterable {
    padding-right: var(--table-cell-horizontal-padding);
}

.Table tfoot > tr th:not(:last-child).text-right.cell-sortable.cell-filterable {
    padding-right: calc(var(--table-sort-width) + var(--table-filter-width) + var(--table-cell-horizontal-padding) + var(--table-cell-horizontal-indents));
}

/* Table mini */
.Table.Table-mini thead {
    display: none;
}

.Table.Table-mini tr td {
    padding: var(--table-mini-tr-td-p);
    line-height: var(--table-mini-tr-td-lh);
}

.Table.Table-mini tr td:first-child {
    display: table-cell;
    padding-left: var(--table-mini-tr-td-first-pl) !important;
}

.Table.Table-mini tr td:last-child {
    padding-right: var(--table-mini-tr-td-last-pr) !important;
}

.Table.Table-mini tr td:not(:last-child) {
    padding-right: var(--table-mini-tr-td-not-last-pr);
}

/* Table header mini */
.Table-header-mini {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: var(--table-mini-cell-vertical-indents) var(--padding-base);
    color: var(--table-primary-color);
    font-weight: normal;
    font-size: var(--table-header-mini-fz);
    line-height: var(--table-header-mini-lh);
    border-bottom: var(--table-border);
}

.Table-header-mini > span {
    padding-right: var(--table-header-mini-span-pr);
    font-weight: normal;
    line-height: var(--table-header-mini-span-lh);
}

.Table-header-mini .form-group > .select {
    min-width: auto;
}

.Table-header-mini .select .SelectButton {
    padding: 0;
    border: 0;
    color: var(--table-primary-color);
    cursor: pointer;
    background-color: transparent;
}

.Table-header-mini .select__dropdown {
    min-width: var(--table-header-mini-select-dd-w);
    border-radius: var(--border-radius-count-box);
}

.Table-header-mini .select .selected__values {
    width: 100%;
    padding-right: var(--table-header-mini-select-values-pr);
}

.Table-header-mini .form-group .select .anticon__arrow {
    color: var(--table-primary-color);
}

.Table-header-mini .form-group .select__option .checkbox-wrapper {
    padding: var(--table-header-mini-checkbox-wrapper-p);
}

.Table-header-mini button {
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
}

.Table-header-mini .table-cell__sorter-icon {
    margin: var(--table-header-mini-sorter-icon-m);
}

.Table-header-mini .table-cell__sorter-asc {
    transform: scaleY(-1);
}

/* Table draggable */
.Table.Table-draggable {
    position: relative;
    overflow: hidden;
}

.Table.Table-draggable th {
    transition: var(--table-draggable-th-transition);
}

.Table.Table-draggable th[draggable=true] {
    cursor: grab;
}

.Table.Table-draggable th[draggable=false] {
    cursor: not-allowed;
}

.Table.Table-draggable th.draggable {
    opacity: 0.5;
}

.Table.Table-draggable > .drag-element {
    position: absolute;
    height: var(--table-draggable-drag-element-h);
    padding: var(--table-draggable-drag-element-p);
    color: var(--table-draggable-drag-element-color);
    border: var(--table-draggable-drag-element-border);
    box-shadow: none;
    text-align: center;
    min-width: var(--table-draggable-drag-min-w);
    margin-top: -50%;
    margin-left: -50%;
    background-color: var(--table-draggable-drag-bc);
    border-radius: var(--border-radius-base);
    transform: translate(-50%, -50%);
    font-weight: bold;
}

/* Renders */
.Table tbody .table-cell {
    max-width: 100%;
}

.Table tbody .table-cell_with-subtext {
    display: flex;
    justify-content: normal;
    align-items: stretch;
    flex-direction: column;
    min-width: 0;
    max-width: 100%;
}

.Table tbody .table-cell_with-subtext > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Table tbody .table-cell_with-current-new div {
    display: flex;
    justify-content: flex-end;
}

.Table tbody .table-cell_with-current-new div:first-child {
    margin-bottom: var(--table-tbody-cell-current-new-first-mb);
}

.Table tbody .table-cell_with-current-new div > span:first-child {
    padding-right: var(--table-tbody-cell-current-new-span-first-pr);
}

.Table tbody .table-cell_tooltipped {
    max-width: 100%;
    display: flex;
    align-items: center;
    min-width: calc(100% - var(--table-cell-expand-size));
}

.Table.scrollable tbody .table-cell_tooltipped {
    padding-right: var(--table-tbody-cell-tooltipped-pr);
}

.Table tbody .table-cell_tooltipped-show {
    color: var(--table-primary-color);
}

.Table tbody .table-cell_tooltipped span {
    margin-right: var(--table-tbody-cell-tooltipped-span-mr);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Table tbody .table-cell_linked {
    max-width: 100%;
}

.Table tbody .table-cell_linked span {
    color: var(--table-linked);
    text-decoration: underline;
}

.Table tbody .table-cell_linked:hover span {
    color: var(--table-linked-hover);
}

.Table tbody > tr .table-cell_positive,
.Table tbody > tr .table-cell_highlighted,
.Table tfoot > tr .table-cell_positive,
.Table tfoot > tr .table-cell_highlighted {
    color: var(--table-tbody-tr-cell-positive-color);
    margin-bottom: 0 !important;
}

.Table tbody > tr .table-cell_negative,
.Table tfoot > tr .table-cell_negative {
    color: var(--table-tbody-tr-cell-negative-color);
    margin-bottom: 0 !important;
}

.Table tbody .table-cell_icon {
    color: var(--table-tbody-cell-icon-color);
}

.Table tbody .table-cell_icon i + i {
    margin-left: var(--table-tbody-cell-icon-i-ml);
}

.Table tbody .table-cell_with-button-icon button {
    padding: 0;
    border: 0;
    background-color: transparent;
}

.Table tbody .table-cell_with-button-icon button .table-cell_icon {
    padding-top: var(--table-tbody-cell-with-button-icon-btn-pt);
}

.Table tbody .table-cell_with-button-icon button:hover {
    cursor: pointer;
}

.Table tbody .table-cell_with-checkbox {
    display: table-cell;
    vertical-align: middle;
    padding-top: var(--table-tbody-cell_with-checkbox-pt);
    padding-right: var(--table-tbody-cell_with-checkbox-pr);
}

.Table tbody .table-cell_with-checkbox .checkbox-input {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
}

.Table tbody .table-cell_with-checkbox .checkbox-inner {
    width: var(--table-tbody-cell_with-checkbox-inner-size);
    height: var(--table-tbody-cell_with-checkbox-inner-size);
}

.Table tbody .table-cell_with-checkbox .checkbox-inner:after {
    width: var(--table-tbody-cell_with-checkbox-inner-after-size);
    height: var(--table-tbody-cell_with-checkbox-inner-after-size);
}

.Table tbody .table-cell_with_message p {
    font-size: var(--table-tbody-cell_with-message-p-fz);
    margin-bottom: 0;
}

.Table tbody .table-cell_with_message.table-cell_with_message-new {
    margin-top: var(--table-tbody-cell_with-message-new-mt);
}

.Table tbody .table-cell_with_message.table-cell_with_message-new p {
    font-weight: bold;
}

.Table tbody .table-cell_with_message.table-cell_with_message-new:before {
    position: relative;
    top: 37px;
    left: -11px;
    display: block;
    width: var(--table-tbody-cell_with-message-new-before-size);
    height: var(--table-tbody-cell_with-message-new-before-size);
    content: '';
    border-radius: var(--table-tbody-cell_with-message-new-before-border-radius);
    background-color: var(--table-tbody-cell_with-message-new-before-bc);
}

.Table tbody .table-cell_with_message_date.table-cell_with_message_date-replied i {
    display: block;
    padding-bottom: var(--table-tbody-cell_with-message-date-replied-i-pb);
    color: var(--table-tbody-cell_with-message-date-replied-i-color);
    text-align: right;
}

.Table tbody .table-cell_radio > label {
    float: left;
}

.Table tbody .cell-row-selection {
    display: flex;
    align-items: center;
}

.Table tbody .row-selection {
    display: flex;
    align-items: center;
    width: var(--table-tbody-row-selection-w);
    padding-left: var(--table-tbody-row-selection-pl);
}

.Table tbody .row-selection .radio {
    top: 0;
}

.Table tbody .cell-row-selection .radio-wrapper,
.Table tbody .cell-row-selection .checkbox-wrapper {
    margin-right: 0;
}

/* Fixed last column */
.Table-wrapper .fixed-last-column .Table {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.Table thead th.column-fixed,
.Table tbody td.cell-fixed {
    position: sticky;
    right: 0;
    top: auto;
    width: var(--table-thead-th-column-fixed-w);
    text-align: right;
}

.Table-header-mini .dropdown-title {
    position: relative;
    cursor: pointer;
    margin-right: var(--table-header-mini-dd-title-mr);
}

.Table-header-mini .dropdown-title-container {
    position: absolute;
    right: 13px;
    top: -10px;
    display: flex;
}

.Table-header-mini .dropdown-title-container span {
    white-space: nowrap;
}

.Table-header-mini .dropdown-title .Icon.dropdown-arrow {
    font-size: var(--table-header-mini-dd-title-arrow-fz);
    margin: var(--table-header-mini-dd-title-arrow-m);
}

.Table-header__dropdown .rc-tooltip-content {
    margin-top: var(--table-header-dd-tooltip-content-mt);
}

.Table-header__dropdown .dropdown .menu-item {
    min-width: var(--table-header-dd-menu-item-min-w);
}

.Table-header-mini .sort-button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.Table-header-mini .dropdown-title__opened {
    transform: translate3d(0, -50%, 0) rotate(-180deg);
    position: relative;
    top: 10px;
}

.Table-header__dropdown.rc-tooltip-placement-bottom  .rc-tooltip-inner,
.Table-header__dropdown.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
.Table-header__dropdown.rc-tooltip-placement-bottomRight .rc-tooltip-inner {
    margin-top: 0;
}

.Table-header__dropdown.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.Table-header__dropdown.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.Table-header__dropdown.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    top: 0;
}
