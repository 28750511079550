.rc-tooltip-placement-top,
.rc-tooltip-placement-topLeft,
.rc-tooltip-placement-topRight {
    padding-bottom: var(--tooltip-distance);
}

.rc-tooltip-placement-top > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-topRight > .rc-tooltip-content > .rc-tooltip-arrow {
    bottom: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--popover-border-color);
    border-bottom: 0;
}

.rc-tooltip-placement-top .rc-tooltip-arrow,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    bottom: calc(var(--tooltip-distance) - 23px);

}

.rc-tooltip-placement-top .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-topRight .rc-tooltip-arrow .rc-tooltip-content {
    transform: translateY(calc(-15px / 2));
}

.rc-tooltip-placement-top > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.rc-tooltip-placement-topLeft .rc-tooltip-arrow {
    left: var(--popover-arrow-offset-horizontal);
}

.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    right: var(--popover-arrow-offset-horizontal);
}

.rc-tooltip-placement-top .rc-tooltip-arrow:after,
.rc-tooltip-placement-topLeft .rc-tooltip-arrow:after,
.rc-tooltip-placement-topRight .rc-tooltip-arrow:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--popover-rc-tooltip-inner-bc);
    border-bottom: 0;
    bottom: 1px;
}
