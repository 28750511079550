.LineChart {
    --line-chart-color-area: var(--line-chart-color-area-theme, var(--accent-default));
    --line-chart-colors-0: var(--line-chart-colors-0-theme, var(--accent-lighter));
    --line-chart-colors-1: var(--line-chart-colors-1-theme, var(--accent-darker));
    --line-chart-colors-2: var(--line-chart-colors-2-theme, var(--negative-light));
    --line-chart-colors-3: var(--line-chart-colors-3-theme, var(--primary-default));
    --line-chart-colors-4: var(--line-chart-colors-4-theme, var(--secondary-light));
    --line-chart-colors-5: var(--line-chart-colors-5-theme, var(--secondary-default));
    --line-chart-colors-6: var(--line-chart-colors-6-theme, var(--negative-dark));
    --line-chart-colors-7: var(--line-chart-colors-7-theme, var(--positive-lighter));
    --line-chart-colors-8: var(--line-chart-colors-8-theme, var(--positive-darker));
    --line-chart-colors-9: var(--line-chart-colors-9-theme, var(--negative-lighter));
    --line-chart-goal-color: var(--line-chart-goal-color-theme, var(--accent-default));
    --line-chart-halo-fill: var(--line-chart-halo-fill-theme, var(--bright));
    --line-chart-halo-stroke: var(--line-chart-halo-stroke-theme, var(--secondary-default));
    --line-chart-axis-fz: 13px;
    --line-chart-color: var(--line-chart-color-theme, var(--primary-default));
    --line-chart-grid-stroke: var(--line-chart-grid-stroke-theme, var(--secondary-light));
    --line-chart-tooltip-bg: var(--line-chart-tooltip-bg-theme, var(--bright));
    --line-chart-tooltip-color: var(--line-chart-tooltip-color-theme, var(--primary-default));
    --line-chart-tooltip-stroke: var(--line-chart-tooltip-stroke-theme, var(--border-color));
    --line-chart-tooltip-fz: 14px;
    --line-chart-tooltip-p: 11.5px var(--margin-base);
    --line-chart-legend-fz: 16px;
    --line-chart-legend-lh: 24px;
    --line-chart-legeng-circle-size: 14px;
    overflow: visible !important;
}

.LineChart .highcharts-container {
    overflow: visible !important;
}

.LineChart .highcharts-root {
    overflow: visible !important;
}

.LineChart .highcharts-area {
    fill: var(--line-chart-color-area);
    fill-opacity: 0.1;
}

.LineChart .highcharts-background {
    fill: transparent;
}

.LineChart .highcharts-color-0 {
    stroke: var(--line-chart-colors-0);
}

.LineChart .highcharts-color-0.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-0);
}

.LineChart .highcharts-color-1 {
    stroke: var(--line-chart-colors-1);
}

.LineChart .highcharts-color-1.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-1);
}

.LineChart .highcharts-color-2 {
    stroke: var(--line-chart-colors-2);
}

.LineChart .highcharts-color-2.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-2);
}

.LineChart .highcharts-color-3 {
    stroke: var(--line-chart-colors-3);
}

.LineChart .highcharts-color-3.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-3);
}

.LineChart .highcharts-color-4 {
    stroke: var(--line-chart-colors-4);
}

.LineChart .highcharts-color-4.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-4);
}

.LineChart .highcharts-color-5 {
    stroke: var(--line-chart-colors-5);
}

.LineChart .highcharts-color-5.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-5);
}

.LineChart .highcharts-color-6 {
    stroke: var(--line-chart-colors-6);
}

.LineChart .highcharts-color-6.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-6);
}

.LineChart .highcharts-color-7 {
    stroke: var(--line-chart-colors-7);
}

.LineChart .highcharts-color-7.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-7);
}

.LineChart .highcharts-color-8 {
    stroke: var(--line-chart-colors-8);
}

.LineChart .highcharts-color-8.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-8);
}

.LineChart .highcharts-color-9 {
    stroke: var(--line-chart-colors-9);
}

.LineChart .highcharts-color-9.highcharts-legend-item:before {
    background-color: var(--line-chart-colors-9);
}

.LineChart .highcharts-axis-labels {
    font-family: var(--body-font-family);
    fill: var(--line-chart-color);
}

.LineChart .highcharts-axis-title {
    font-family: var(--body-font-family);
    fill: var(--line-chart-color);
    font-weight: bold;
}

.LineChart .highcharts-xaxis .highcharts-tick,
.LineChart .highcharts-xaxis .highcharts-axis-line {
    stroke: none;
}

.LineChart .highcharts-xaxis-labels {
    font-size: var(--line-chart-axis-fz);
}

.LineChart .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: var(--line-chart-grid-stroke);
    stroke-width: 2px;
    stroke-opacity: 0.6;
    stroke-dasharray: 5px, 4px;
}

.LineChart .highcharts-yaxis-labels {
    font-size: var(--line-chart-axis-fz);
}

.LineChart .highcharts-halo {
    fill-opacity: 1;
    stroke-width: 1px;
    fill: var(--line-chart-halo-fill);
    stroke: var(--line-chart-halo-stroke);
}

.LineChart .highcharts-markers {
    stroke-width: 0;
    fill: var(--line-chart-color);
}

.LineChart .highcharts-goal .highcharts-point {
    stroke-width: 0;
    fill: var(--line-chart-goal-color);
}

.LineChart .highcharts-goal .highcharts-halo {
    stroke: var(--line-chart-goal-color);
}

.LineChart .highcharts-duration .highcharts-graph {
    stroke: var(--line-chart-goal-color);
    stroke-width: 2px;
}

.LineChart .highcharts-duration .highcharts-point {
    fill: var(--line-chart-goal-color);
    stroke-width: 2px;
    stroke: var(--bright);
}

.LineChart .highcharts-tooltip {
    stroke: var(--line-chart-tooltip-stroke);
    filter: none !important;
}

.LineChart .highcharts-tooltip-box {
    fill: var(--line-chart-tooltip-bg);
    fill-opacity: 1;
}

.LineChart .highcharts-tooltip > span {
    font-size: var(--line-chart-tooltip-fz);
    font-family: var(--body-font-family);
    padding: var(--line-chart-tooltip-p);
    color: var(--line-chart-tooltip-color);
    white-space: normal !important;
}

.LineChart .highcharts-legend g.highcharts-legend-item {
    opacity: 0;
}

.LineChart .highcharts-legend div.highcharts-legend-item {
    color: var(--line-chart-color);
    font-family: var(--body-font-family);
    letter-spacing: 0.25px;
    font-size: var(--line-chart-legend-fz);
    line-height: var(--line-chart-legend-lh);
}

.LineChart .highcharts-legend div.highcharts-legend-item:before {
    display: inline-block;
    width: var(--line-chart-legeng-circle-size);
    height: var(--line-chart-legeng-circle-size);
    content: '';
    margin-top: calc(var(--line-chart-legeng-circle-size) / 2 + 1px);
    margin-left: calc(var(--line-chart-legeng-circle-size) * -1);
    border-radius: var(--border-radius-circle);
}
