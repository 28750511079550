.Steps-container {
    --progress-bar-animation-duration: 0.3s;
    --progress-bar-animation-duration-slow: 0.5s;
    --progress-bar-animation-duration-fast: 0.1s;
    --progress-bar-icon-size: 32px;
    --progress-bar-step-radius: 32px;
    --progress-bar-line-width: 2px;
    --progress-bar-small-step-radius: 16px;
    --progress-bar-vertical-label-width: 104px;
    --progress-bar-steps-icon-margin: 36px;
    --progress-bar-fz: 16px;
    --progress-bar-steps-icon-fz: 12px;
    --progress-bar-line-height: 1.25;
    --progress-bar-small-step-width: 44px;
    --progress-bar-small-line-width: 1px;
    --progress-bar-small-paddting-top: 9px;
    --progress-bar-shadow-color: rgba(76, 132, 118, 0.5);
    --progress-bar-type-bars-height: 3px;
    --progress-bar-type-bars-step-width: 50px;
    --progress-bar-color: var(--progress-bar-color-theme, var(--primary-default));
    --progress-bar-tail-p: 0 10px;
    --progress-bar-tail-br: var(--border-width-base);
    --progress-bar-tail-h: 1px;
    --progress-bar-icon-mr: 8px;
    --progress-bar-horizontal-wait-icon-bc: var(--progress-bar-horizontal-wait-icon-bc-theme, var(--secondary-light));
    --progress-bar-horizontal-process-icon-color: var(--progress-bar-horizontal-process-icon-color-theme, var(--primary-default));
    --progress-bar-horizontal-finish-icon-color: var(var(--progress-bar-horizontal-finish-icon-color-theme), var(--primary-default));
    --progress-bar-horizontal-title-color: var(--progress-bar-horizontal-title-color-theme, var(--primary-default));
    --progress-bar-horizontal-small-wait-icon-bc: var(--progress-bar-horizontal-small-wait-icon-bc-theme, var(--secondary-light));
    --progress-bar-horizontal-small-bars-tail-background: linear-gradient(to right, var(--progress-bar-accent-theme, var(--accent-default)) 50%, var(--progress-bar-secondary-light-theme, var(--secondary-light)) 50%) right bottom;
    --progress-bar-horizontal-tail-after-background: linear-gradient(to right, var(--progress-bar-from-bc-theme, var(--primary-default)) 50%, var(--progress-bar-to-bc-theme, var(--secondary-light)) 50%) right bottom;
    --progress-bar-horizontal-small-bars-process-tail-bs: 0 0 5px 0.5px var(--progress-bar-shadow-color);
    --progress-bar-border-color: var(--progress-bar-border-c-theme, var(--primary-default));
    --progress-bar-horizontal-tail-after-transition: all 0.5s ease-out 0s;
    --progress-bar-step-icon-color: var(--progress-bar-step-icon-color-theme, var(--primary-default));

    font-size: var(--progress-bar-fz);
    color: var(--progress-bar-color);
    line-height: var(--progress-bar-line-height);
    font-family: var(--accent-font-family);
    font-weight: var(--accent-font-weight);
    text-align: center;
}

.Steps {
    display: flex;
    box-sizing: border-box;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum';
    width: 100%;
    font-size: 0;
}

.Steps-item {
    position: relative;
    display: inline-block;
    flex: 1;
    overflow: hidden;
    vertical-align: top;
}

.Steps-item:last-child {
    flex: none;
}

.Steps .Steps-item:last-child .Steps-item-tail,
.Steps .Steps-item:last-child .Steps-item-title:after {
    display: none;
}

.Steps-item-icon,
.Steps-item-content {
    display: inline-block;
    vertical-align: top;
}

.Steps-item-icon {
    margin-right: var(--progress-bar-icon-mr);
    text-align: center;
    border-radius: var(--progress-bar-step-radius);
}

.Steps-item-icon > .Steps-icon {
    position: relative;
    top: -1px;
}

.Steps-item-icon > .Steps-icon > .Icon {
    color: var(--progress-bar-step-icon-color);
}

.Steps-item-tail {
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    padding: var(--progress-bar-tail-p);
}

.Steps-item-tail:after {
    display: inline-block;
    width: 100%;
    height: var(--progress-bar-tail-h);
    border-radius: var(--progress-bar-tail-br);
    content: '';
}

.Steps-item-title {
    position: relative;
    display: inline-block;
}

.Steps-item[role='button'] {
    outline: none;
}

.Steps-item[role='button']:not(.Steps-item-process) {
    cursor: pointer;
}

.Steps-horizontal .Steps-item-tail {
    margin-left: calc(var(--progress-bar-step-radius) * 1.63);
    padding: calc(var(--progress-bar-step-radius) / 10.5) calc(var(--progress-bar-step-radius) / 2.13);
}

.Steps-horizontal .Steps-item-tail:after {
    height: var(--progress-bar-line-width);
    background: var(--progress-bar-horizontal-tail-after-background);
    background-size: 200% 100%;
    transition: var(--progress-bar-horizontal-tail-after-transition);
}

.Steps-horizontal .Steps-item-custom .Steps-item-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--progress-bar-step-radius);
    height: var(--progress-bar-step-radius);
    border: var(--progress-bar-line-width) solid var(--progress-bar-border-color);
    transition: border-color var(--progress-bar-animation-duration-slow) ease 0.5s;
}

.Steps-horizontal .Steps-item-custom .Steps-item-icon .Steps-icon {
    color: transparent;
}

.Steps-horizontal .Steps-item-custom.Steps-item-active .Steps-item-icon .Steps-icon {
    top: 0;
    display: flex;
}

.Steps-horizontal .Steps-item-wait .Steps-item-icon {
    border-color: var(--progress-bar-horizontal-wait-icon-bc);
    transition-duration: calc(var(--progress-bar-animation-duration) / 2);
    transition-delay: 0s;
}

.Steps-horizontal .Steps-item-process.Steps-item-custom .Steps-icon {
    font-size: var(--progress-bar-steps-icon-fz);
    color: var(--progress-bar-horizontal-process-icon-color);
    transition: color var(--progress-bar-animation-duration-fast) ease var(--progress-bar-animation-duration-slow);
}

.Steps-horizontal .Steps-item-finish.Steps-item-custom .Steps-item-tail:after {
    background-position: left bottom;
}

.Steps-horizontal .Steps-item-finish.Steps-item-custom .Steps-icon {
    font-size: var(--progress-bar-fz);
    color: var(--progress-bar-horizontal-finish-icon-color);
}

.Steps-horizontal .Steps-item-title {
    font-family: var(--accent-font-family);
    font-weight: var(--accent-font-weight);
    color: var(--progress-bar-horizontal-title-color);
    font-size: var(--progress-bar-fz);
    line-height: var(--progress-bar-line-height);
}

.Steps-horizontal.Steps-small {
    justify-content: center;
    padding-top: var(--progress-bar-small-paddting-top);
}

.Steps-horizontal.Steps-small .Steps-item {
    flex: 0 0 var(--progress-bar-small-step-width);
}

.Steps-horizontal.Steps-small .Steps-item-tail {
    top: calc(calc(var(--progress-bar-small-step-radius) - var(--progress-bar-small-line-width)) / 2);
    margin-left: calc(var(--progress-bar-small-step-width) / 2);
    padding: 0 calc(calc(var(--progress-bar-small-step-radius) / 2) - var(--progress-bar-small-line-width));
}

.Steps-horizontal.Steps-small .Steps-item-tail:after {
    height: var(--progress-bar-small-line-width);
    transition-duration: var(--progress-bar-animation-duration);
}

.Steps-horizontal.Steps-small .Steps-item-content {
    display: none;
}

.Steps-horizontal.Steps-small .Steps-item-custom .Steps-item-icon {
    width: var(--progress-bar-small-step-radius);
    height: var(--progress-bar-small-step-radius);
    margin: auto;
    border: var(--progress-bar-small-line-width) solid var(--progress-bar-border-color);
    border-radius: var(--border-radius-circle);
    transition-duration: var(--progress-bar-animation-duration);
    transition-delay: var(--progress-bar-animation-duration);
}

.Steps-horizontal.Steps-small .Steps-item-wait .Steps-item-icon {
    border-color: var(--progress-bar-horizontal-small-wait-icon-bc);
    transition-duration: calc(var(--progress-bar-animation-duration) / 2);
    transition-delay: 0s;
}

.Steps-horizontal.Steps-small .Steps-item-process .Steps-icon {
    font-size: calc(var(--progress-bar-steps-icon-fz) / 2);
    transition-delay: var(--progress-bar-animation-duration);
}

.Steps-horizontal.Steps-small .Steps-item-finish .Steps-icon {
    font-size: calc(var(--progress-bar-small-step-radius) / 2);
    line-height: 1;
}

.Steps-horizontal.Steps-small.Steps-type-bars .Steps-item {
    flex-basis: var(--progress-bar-type-bars-step-width);
}

.Steps-horizontal.Steps-small.Steps-type-bars .Steps-item-icon {
    display: none;
}

.Steps-horizontal.Steps-small.Steps-type-bars .Steps-item-tail {
    margin-left: 0;
    padding: 0 calc(var(--progress-bar-type-bars-step-width) / 10);
}

.Steps-horizontal.Steps-small.Steps-type-bars .Steps-item-tail:after {
    height: var(--progress-bar-type-bars-height);
    background: var(--progress-bar-horizontal-small-bars-tail-background);
    background-size: 200% 100%;
    transition: all var(--progress-bar-animation-duration) ease-out 0s;
}

.Steps-horizontal.Steps-small.Steps-type-bars .Steps-item:last-child .Steps-item-tail {
    display: initial;
}

.Steps-horizontal.Steps-small.Steps-type-bars .Steps-item-process .Steps-item-tail:after {
    box-shadow: var(--progress-bar-horizontal-small-bars-process-tail-bs);
    background-position: left bottom;
}

.Steps-horizontal.Steps-small.Steps-type-bars .Steps-item-finish .Steps-item-tail:after {
    background-position: left bottom;
}

.Steps-horizontal:not(.Steps-label-vertical) .Steps-item {
    white-space: nowrap;
}

.Steps-horizontal:not(.Steps-label-vertical) .Steps-item:last-child {
    margin-right: 0;
}

.Steps-horizontal:not(.Steps-label-vertical) .Steps-item:last-child .Steps-item-title {
    padding-right: 0;
}

.Steps-horizontal:not(.Steps-label-vertical) .Steps-item-tail {
    display: none;
}

.Steps-small .Steps-item-custom .Steps-item-icon > .Steps-icon {
    transform: none;
}

.Steps-label-vertical .Steps-item {
    overflow: visible;
}

.Steps-label-vertical .Steps-item-content {
    display: block;
    width: var(--progress-bar-vertical-label-width);
    margin-top: calc(var(--progress-bar-small-step-radius) / 2);
    text-align: center;
}

.Steps-label-vertical .Steps-item-icon {
    display: inline-block;
    margin-left: var(--progress-bar-steps-icon-margin);
}

.Steps-label-vertical .Steps-item-title {
    padding-right: 0;
}

.Steps-label-vertical .Steps-item-title:after {
    display: none;
}
