.TextInput-group-wrapper {
    --text-input-horizontal-padding: 12px;
    --text-input-color: var(--text-input-color-theme, var(--primary-default));
    --text-input-disabled-color: var(--text-input-disabled-color-theme, var(--primary-default));
    --text-input-error-color: var(--text-input-error-color-theme, var(--negative-default));
    --text-input-error-hover-color: var(--text-input-error-hover-color-theme, var(--negative-dark));
    --text-input-border-color: var(--input-border-color-base-theme, var(--border-color));
    --text-input-height-base: var(--text-input-height-base-theme, var(--input-height-base));
    --text-input-border: var(--border-width-base) solid var(--text-input-border-color-theme, var(--input-border-color-base));
    --text-input-fz: var(--input-font-size-base);
    --text-input-lh: var(--input-line-height-base);
    --text-input-bc: transparent;
    --text-input-affix-suffix-color: var(--text-input-affix-suffix-color-theme, var(--primary-default));
    --text-input-transition: background-color 5000s ease-in-out 0s;
    --text-input-affix-prefix-input-pl: calc(var(--margin-base) * 2);
    --text-input-affix-prefix-icon-error-mt: 3px;
    --text-input-affix-prefix-icon-search-mt: 27px;
    --text-input-disabled-affix-suffix-color: var(--text-input-disabled-affix-suffix-color-theme, var(--secondary-default));
    --text-input-group-lh: 1.5;
    --text-input-group-hover-border-color: var(--text-input-group-hover-border-color-theme, var(--secondary-default));
    --text-input-group-disabled-hover-border-color: var(--text-input-group-disabled-hover-border-color-theme, var(--secondary-light));
    --text-input-group-addon-color: var(--text-input-group-addon-color-theme, var(--secondary-default));
    --text-input-group-addon-p: 0 10px;
    --text-input-group-addon-lh: 17px;
    --text-input-group-addon-border-before-h: calc(var(--text-input-height-base) - 12px);
    --text-input-group-zi: 1;
    --text-input-affix-wrapper-lh: 1.5;
    --text-input-affix-wrapper-zi: 2;
    --text-input-affix-wrapper-prefix-lh: 1;
    --text-input-affix-wrapper-pr: 30px;
    --text-input-password-icon-transition: all 0.3s;
    --text-input-placeholder-color: var(--text-input-placeholder-color-theme, var(--primary-default));

    display: inline-block;
    width: 100%;
    vertical-align: top;
    text-align: start;
}

.TextInput {
    padding: 0 var(--text-input-horizontal-padding);
    height: calc(var(--text-input-height-base) - 2px);
    background-color: var(--text-input-bc);
    border: var(--text-input-border);
    font-size: var(--text-input-fz);
    color: var(--text-input-color);
    line-height: var(--text-input-lh);
}

.TextInput:-webkit-autofill,
.TextInput:-webkit-autofill:hover,
.TextInput:-webkit-autofill:focus-visible {
    -webkit-text-fill-color: var(--text-input-color);
    transition: var(--text-input-transition);
}

/* -------------------------------------------------------------------------------- */

.TextInput-affix-wrapper .rc-tooltiped-text {
    margin-right: 0;
}

.form-group_disabled .TextInput-affix-wrapper {
    opacity: 0.5;
    pointer-events: none;
}

.TextInput:focus-visible,
.TextInput_focused,
.TextInput_focused .TextInput-input,
.TextInput_focused .TextInput-group:hover {
    box-shadow: none;
}

.TextInput_invalid input {
    border-color: var(--text-input-error-color);
}

.TextInput::placeholder {
    color: var(--text-input-placeholder-color);
}

.TextInput_invalid .rc-tooltiped-text {
    color: var(--text-input-error-color);
}

.TextInput[disabled],
.form-group_disabled .TextInput,
.TextInput_disabled {
    color: var(--text-input-disabled-color);
    box-shadow: none;
    background: var(--text-input-bc);
}

.TextInput-group {
    align-items: center;
    border: var(--text-input-border);
    border-radius: var(--border-radius-base);
    box-sizing: border-box;
    display: flex;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    font-variant: tabular-nums;
    line-height: var(--text-input-group-lh);
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    text-align: start;
    width: 100%;
}

.form-group_disabled .TextInput-group-wrapper .TextInput-group {
    cursor: not-allowed;
}

.TextInput_invalid .TextInput-group {
    border-color: var(--text-input-error-color);
}

.TextInput-group input {
    border: 0;
}

.TextInput-group-addon {
    position: relative;
    padding: var(--text-input-group-addon-p);
    color: var(--text-input-group-addon-color);
    border: 0;
    line-height: var(--text-input-group-addon-lh);
    background-color: var(--text-input-bc);
}

.TextInput-group-addon button {
    font-size: var(--text-input-fz);
}

.TextInput .addon-after-border:before {
    position: absolute;
    top: 5px;
    left: 0;
    z-index: var(--text-input-group-zi);
    display: inline-block;
    width: 1px;
    height: var(--text-input-group-addon-border-before-h);
    content: '';
    background-color: var(--text-input-border-color);
}

.TextInput:focus-visible {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.with-select-box .select {
    height: calc(var(--text-input-height-base) - 2px);
}

.with-select-box .SelectButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    padding: 0;
    text-align: center;
    text-decoration: none;
}

/* -------------------------------------------------------------------------------- */

.TextInput-group-addon,
.TextInput-group-wrap,
.TextInput-group > .TextInput {
    display: table-cell;
}

.TextInput-group-addon:not(:first-child):not(:last-child),
.TextInput-group-wrap:not(:first-child):not(:last-child),
.TextInput-group > .TextInput:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.TextInput-group-addon,
.TextInput-group-wrap {
    width: auto;
    vertical-align: middle;
    white-space: nowrap;
}

.TextInput-group-wrap > * {
    display: block !important;
}

.TextInput-group .TextInput {
    width: 100%;
    margin-bottom: 0;
    text-align: inherit;
}

.TextInput-group .TextInput:focus {
    z-index: var(--text-input-group-zi);
    border-right-width: var(--border-width-base);
}

.TextInput-group .TextInput:hover {
    z-index: var(--text-input-group-zi);
    border-right-width: var(--border-width-base);
}

/* -------------------------------------------------------------------------------- */

.TextInput-affix-wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(var(--text-input-height-base) - 2px);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    line-height: var(--text-input-affix-wrapper-lh);
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
    text-align: start;
}

.TextInput-group .TextInput-affix-wrapper {
    display: flex;
    align-items: center;
}

.TextInput-affix-wrapper .TextInput {
    position: relative;
    text-align: inherit;
    min-height: 100%;
}

.TextInput-affix-wrapper .TextInput:not(:last-child) {
    padding-right: var(--text-input-affix-wrapper-pr);
}

/* -------------------------------------------------------------------------------- */

.TextInput-prefix {
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* -------------------------------------------------------------------------------- */

.TextInput-suffix {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.TextInput-affix-wrapper:not(:last-child) .TextInput-suffix {
    padding-right: 0;
}

.form-group_invalid .TextInput-suffix {
    color: var(--text-input-error-color);
}

.form-group_disabled .TextInput-suffix {
    color: var(--text-input-disabled-affix-suffix-color);
}

/* -------------------------------------------------------------------------------- */

.TextInput-password-icon {
    cursor: pointer;
    transition: var(--text-input-password-icon-transition);
}

.Icon-questionmark,
.Icon-error {
    cursor: pointer;
}
