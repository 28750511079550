.PageVariant.wrapper {
    --page-variant-main-mw: 354px;
    --page-variant-content-box-p: var(--padding-base);
    --page-variant-i-mb: var(--margin-base);
    --page-variant-success-i-color: var(--page-variant-success-i-color-theme, var(--positive-default));
    --page-variant-error-i-color: var(--page-variant-error-i-color, var(--negative-default));
    --page-variant-p-mb: var(--margin-base);
    --page-variant-p-color: var(--page-variant-p-color-theme, var(--secondary-default));
    --page-variant-btn-mt: var(--margin-base);
    --page-variant-buttons-horizontal-btn-p: 0 var(--indent-base);
    --page-variant-buttons-horizontal-btn-first-ml: calc(var(--margin-base) / 2);
    --page-variant-buttons-horizontal-btn-last-mr: calc(var(--margin-base) / 2);

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.PageVariant .main {
    max-width: var(--page-variant-main-mw);
    width: 100%;
    margin: 0 auto;
    background: transparent;
    text-align: center;
}

.PageVariant .Content-box {
    display: inline-block;
    padding: var(--page-variant-content-box-p) !important;
    vertical-align: top;
    width: 100%;
    text-align: left;
}

.PageVariant i {
    margin-bottom: var(--page-variant-i-mb);
    cursor: inherit;
}

.PageVariant .error > i {
    color: var(--page-variant-error-i-color) !important;
}

.PageVariant .success > i {
    color: var(--page-variant-success-i-color) !important;
}

.PageVariant p {
    margin-bottom: var(--page-variant-p-mb);
    color: var(--page-variant-p-color);
}

.PageVariant button {
    width: 100%;
}

.PageVariant button + button {
    margin-top: var(--page-variant-btn-mt);
}

.PageVariant .PageVariant-buttons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.PageVariant .PageVariant-buttons-horizontal {
    flex-direction: row-reverse;
}

.PageVariant .PageVariant-buttons-horizontal button + button {
    margin-top: 0;
    padding: var(--page-variant-buttons-horizontal-btn-p);
}

.PageVariant .PageVariant-buttons-horizontal button {
    min-width: 0;
    width: 100%;
    padding: var(--page-variant-buttons-horizontal-btn-p);
}

.PageVariant .PageVariant-buttons-horizontal button:first-child {
    margin-left: var(--page-variant-buttons-horizontal-btn-first-ml);
}

.PageVariant .PageVariant-buttons-horizontal button:last-child {
    margin-right: var(--page-variant-buttons-horizontal-btn-last-mr);
}
