.calendar {
    --calendar-h: 720px;
    --calendar-bg: var(--calendar-bg-theme, var(--bright));
    --calendar-color: var(--calendar-color-theme, var(--primary-default));
    --calendar-border-color: var(--calendar-border-color-theme, var(--secondary-light));
    --calendar-extra-btn-min-w: 20px;
    --calendar-extra-btn-m: var(--indent-base);
    --calendar-extra-picker-size: 18px;
    --calendar-extra-picker-color: var(--secondary-default);
    --calendar-title-m: var(--indent-base) 0 calc(var(--margin-base) / 2);
    --calendar-paragraph-m: 0 0 var(--indent-base) 0;
    --calendar-btn-p: 0 calc(var(--margin-base) / 2);
    --calendar-btn-h: 20px;
    --calendar-btn-min-w: 70px;
    --calendar-btn-fz: 12px;
    --calendar-time-border-bottom-color: var(--calendar-time-border-bottom-color-theme, var(--secondary-lighter));
    --calendar-time-mt: 25px;
    --calendar-time-date-mb: var(--indent-base);
    --calendar-time-header-h: 24px;
    --calendar-content-header-p: 0 5px var(--indent-base);
    --calendar-content-header-fz: 16px;
    --calendar-content-header-lh: 1.375;
    --calendar-content-active-color: var(--calendar-content-active-color-theme, var(--accent-default));
    --calendar-content-cell-p: 4px 7px 0 7px;
    --calendar-content-cell-header: 97px;
    --calendar-content-date-fz: 14px;
    --calendar-content-date-lh: 22px;
    --calendar-content-cell-number-active-size: 19px;
    --calendar-content-cell-fz: 13px;
    --calendar-content-cell-lh: 15px;
    --calendar-content-cell-more-lh: 22px;
    --calendar-content-cell-more-color: var(--calendar-content-cell-more-color-theme, var(--secondary-default));
    --calendar-content-cell-event-hz-p: 3px;
    --calendar-content-cell-event-p: 3px 5px;
    --calendar-content-cell-event-border-left-width: 3px;
    --calendar-dot-size: 5px;
    --calendar-dot-m: 5px;
    --calendar-content-time-cell-header-min-h: 49px;
    --calendar-current-time-w: 60px;
    --calendar-indicator-size: 11px;

    height: var(--calendar-h);
}

.calendar .Tabs-bar {
    margin-bottom: 0;
}

.calendar .Tabs-extra__item {
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar .Tabs-bar__extra .button.button_icon {
    min-width: var(--calendar-extra-btn-min-w);
    padding: 0;
    border: none;
}

.calendar .Tabs-bar__extra .button.button_icon.arrow_left {
    margin-right: var(--calendar-extra-btn-m);
}

.calendar .Tabs-bar__extra .button.button_icon.arrow_right {
    margin-left: var(--calendar-extra-btn-m);
}

.calendar .Tabs-bar__extra .button.button_icon:after {
    opacity: 0;
}

.calendar .Tabs-bar__extra .datepicker-form-group {
    margin-right: var(--calendar-extra-btn-m);
}

.calendar .Tabs-bar__extra .additiv-picker {
    margin: 0;
    padding: 0;
    border: none;
    cursor: pointer;
    min-width: var(--calendar-extra-btn-min-w);
}

.calendar .Tabs-bar__extra .additiv-picker input {
    z-index: -1;
    position: relative;
    width: 0;
    height: 0;
    border: none;
    cursor: pointer;
}

.calendar .Tabs-bar__extra .additiv-picker .additiv-picker-suffix {
    position: relative;
    margin: 0;
    width: var(--calendar-extra-picker-size);
    height: var(--calendar-extra-picker-size);
    font-size: var(--calendar-extra-picker-size);
    line-height: 1;
}

.calendar .Tabs-bar__extra .additiv-picker .Icon {
    padding: 0;
    color: var(--calendar-extra-picker-color);
    font-size: var(--calendar-extra-picker-size);
}

.calendar .calendar-header-panel h3 {
    margin: var(--calendar-title-m);
}

.calendar .calendar-header-panel p {
    margin: var(--calendar-paragraph-m);
}

.calendar .calendar-header-panel .button {
    height: var(--calendar-btn-h);
    min-width: var(--calendar-btn-min-w);
    padding: var(--calendar-btn-p);
    text-align: center;
    font-size: var(--calendar-btn-fz);
    line-height: var(--calendar-btn-h);
}

.calendar .rbc-month-view {
    border-top: none;
    border-color: var(--calendar-border-color);
}

.calendar .rbc-month-view .rbc-month-header {
    position: relative;
    flex: 1 0 auto;
}

.calendar .rbc-month-view .rbc-month-header:before {
    position: absolute;
    top: -1px;
    left: -1px;
    width: 1px;
    height: 100%;
    content: '';
    background-color: var(--calendar-bg);
}

.calendar .rbc-month-view .rbc-month-header:after {
    position: absolute;
    top: -1px;
    right: -1px;
    width: 1px;
    height: 100%;
    content: '';
    background-color: var(--calendar-bg);
}

.calendar .rbc-month-view .rbc-header {
    border-color: var(--calendar-border-color);
    border-left: none;
    padding: var(--calendar-content-header-p);
    color: var(--calendar-color);
    text-align: center;
    font-size: var(--calendar-content-header-fz);
    line-height: var(--calendar-content-header-lh);
    font-weight: normal;
}

.calendar .rbc-month-view .rbc-month-row {
    flex: auto;
    overflow: visible;
    height: 100%;
    flex-basis: auto;
}

.calendar .rbc-month-view .rbc-month-row + .rbc-month-row {
    border-top: var(--border-width-base) var(--border-style-base) var(--calendar-border-color);
}

.calendar .rbc-month-view .rbc-row-bg {
    position: relative;
    overflow: visible;
    height: 100%;
}

.calendar .rbc-month-view .rbc-row-content {
    position: absolute;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    height: 100%;
    min-height: var(--calendar-content-cell-header);
    width: 100%;
}

.calendar .rbc-month-view .rbc-row-content .rbc-show-more {
    background-color: transparent;
    margin-left: calc(var(--calendar-dot-m) * 2 + var(--calendar-dot-size));
    color: var(--calendar-content-cell-more-color);
    font-weight: normal;
    font-size: var(--calendar-content-cell-fz);
    line-height: var(--calendar-content-cell-more-lh);
}

.calendar .rbc-month-view .rbc-date-cell {
    padding: var(--calendar-content-cell-p);
    color: var(--calendar-content-cell-more-color);
    font-size: var(--calendar-content-date-fz);
}

.calendar .rbc-month-view .rbc-date-cell a {
    display: inline-block;
    font-size: var(--calendar-content-date-fz);
    line-height: var(--calendar-content-date-fz);
}

.calendar .rbc-month-view .rbc-date-cell.rbc-current a {
    position: relative;
    left: -3px;
    display: inline-block;
    color: var(--calendar-bg);
    font-weight: bold;
    letter-spacing: -2px;
    text-indent: -2px;
}

.calendar .rbc-month-view .rbc-date-cell.rbc-current a:before {
    position: absolute;
    top: calc(50% - var(--calendar-content-cell-number-active-size) / 2 - 1px);
    left: calc(50% - var(--calendar-content-cell-number-active-size) / 2);
    z-index: -1;
    display: inline-block;
    width: var(--calendar-content-cell-number-active-size);
    height: var(--calendar-content-cell-number-active-size);
    content: '';
    border-radius: var(--border-radius-circle);
    background-color: var(--calendar-content-active-color);
}

.calendar .rbc-month-view .rbc-day-bg {
    position: relative;
    display: block;
}

.calendar .rbc-month-view .rbc-day-bg + .rbc-day-bg {
    border-left: var(--border-width-base) var(--border-style-base) var(--calendar-border-color);
}

.calendar .rbc-day-slot .rbc-events-container {
    margin-right: 0;
}

.calendar .rbc-row-segment {
    padding: 0;
}

.calendar .rbc-event {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    border: none;
    color: var(--event-primary);
    font-size: var(--calendar-content-cell-fz);
    line-height: var(--calendar-content-cell-lh);
    font-weight: bold;
    border-radius: 0;
    background-color: transparent;
}

.calendar .rbc-event:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.calendar .rbc-event .rbc-event-content {
    background-color: var(--event-primary-bg);
    padding: var(--calendar-content-cell-event-p);
    width: 100%;
}

/* Table event colors start */
.calendar .rbc-event.event-purple {
    color: var(--event-purple);
}

.calendar .rbc-event.event-purple .rbc-event-content {
    background-color: var(--event-purple-bg);
}

.calendar .rbc-event.event-purple.rbc-selected.ant-popover-open .rbc-event-content {
    background-color: var(--event-purple);
}

.calendar .rbc-event.event-orange {
    color: var(--event-orange);
}

.calendar .rbc-event.event-orange .rbc-event-content {
    background-color: var(--event-orange-bg);
}

.calendar .rbc-event.event-orange.rbc-selected.ant-popover-open .rbc-event-content {
    background-color: var(--event-orange);
}

.calendar .rbc-event.event-green {
    color: var(--event-green);
}

.calendar .rbc-event.event-green .rbc-event-content {
    background-color: var(--event-green-bg);
}

.calendar .rbc-event.event-green.rbc-selected.ant-popover-open .rbc-event-content {
    background-color: var(--event-green);
}

.calendar .rbc-event.event-turquoise {
    color: var(--event-turquoise);
}

.calendar .rbc-event.event-turquoise .rbc-event-content {
    background-color: var(--event-turquoise-bg);
}

.calendar .rbc-event.event-turquoise.rbc-selected.ant-popover-open .rbc-event-content {
    background-color: var(--event-turquoise);
}

.calendar .rbc-event.event-yellow {
    color: var(--event-yellow);
}

.calendar .rbc-event.event-yellow .rbc-event-content {
    background-color: var(--event-yellow-bg);
}

.calendar .rbc-event.event-yellow.rbc-selected.ant-popover-open .rbc-event-content {
    background-color: var(--event-yellow);
}

/* Table event colors end */

.calendar .rbc-event.rbc-selected.ant-popover-open .rbc-event-content {
    background-color: var(--event-primary);
    color: var(--calendar-bg);
}

.calendar .rbc-month-view .rbc-event:not(.rbc-event-allday):not(.ant-popover-open) .rbc-event-content {
    background-color: transparent;
}

.calendar .rbc-month-view .rbc-event:not(.rbc-event-allday) .rbc-event-content:before {
    position: relative;
    top: -2px;
    display: inline-block;
    margin: 0 var(--calendar-dot-m);
    width: var(--calendar-dot-size);
    height: var(--calendar-dot-size);
    content: '';
    background-color: var(--event-primary);
    border-radius: var(--border-radius-circle);
}

/* Table event colors start */
.calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-purple .rbc-event-content:before {
    background-color: var(--event-purple);
}

.calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-orange .rbc-event-content:before {
    background-color: var(--event-orange);
}

.calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-green .rbc-event-content:before {
    background-color: var(--event-green);
}

.calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-turquoise .rbc-event-content:before {
    background-color: var(--event-turquoise);
}

.calendar .rbc-month-view .rbc-event:not(.rbc-event-allday).event-yellow .rbc-event-content:before {
    background-color: var(--event-yellow);
}

/* Table event colors end */

.calendar .rbc-month-view .rbc-event.rbc-selected.ant-popover-open .rbc-event-content:before {
    background-color: var(--calendar-bg);
}

.calendar .rbc-month-view .rbc-event.rbc-event-allday {
    width: calc(100% - 2px);
    margin: 0 1px;
}

.calendar .rbc-month-view .rbc-event.rbc-event-allday:before {
    display: none;
}

.calendar .rbc-time-view .rbc-event .rbc-event-label {
    display: none;
}

.calendar .rbc-time-view .rbc-event .rbc-event-content {
    overflow: hidden;
    padding: var(--calendar-content-cell-event-p);
    margin-bottom: 1px;
    border-left: var(--calendar-content-cell-event-border-left-width) var(--border-style-base) var(--event-primary);
    font-size: var(--calendar-content-cell-fz);
    line-height: var(--calendar-content-cell-lh);
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: bold;
}

.calendar .rbc-time-view .rbc-event .rbc-event-content .event-start {
    font-weight: normal;
}

.calendar .rbc-time-view .rbc-event.rbc-event-allday .rbc-event-content {
    margin-top: 2px;
}

/* Table event colors start */
.calendar .rbc-time-view .rbc-event.rbc-selected.ant-popover-open .rbc-event-content {
    border-color: var(--event-primary);
}

.calendar .rbc-time-view .rbc-event.event-purple .rbc-event-content {
    border-color: var(--event-purple);
}

.calendar .rbc-time-view .rbc-event.event-purple.rbc-selected.ant-popover-open .rbc-event-content {
    border-color: var(--event-purple);
}

.calendar .rbc-time-view .rbc-event.event-orange .rbc-event-content {
    border-color: var(--event-orange);
}

.calendar .rbc-time-view .rbc-event.event-orange.rbc-selected.ant-popover-open .rbc-event-content {
    border-color: var(--event-orange);
}

.calendar .rbc-time-view .rbc-event.event-green .rbc-event-content {
    border-color: var(--event-green);
}

.calendar .rbc-time-view .rbc-event.event-green.rbc-selected.ant-popover-open .rbc-event-content {
    border-color: var(--event-green);
}

.calendar .rbc-time-view .rbc-event.event-turquoise .rbc-event-content {
    border-color: var(--event-turquoise);
}

.calendar .rbc-time-view .rbc-event.event-turquoise.rbc-selected.ant-popover-open .rbc-event-content {
    border-color: var(--event-turquoise);
}

.calendar .rbc-time-view .rbc-event.event-yellow .rbc-event-content {
    border-color: var(--event-yellow);
}

.calendar .rbc-time-view .rbc-event.event-yellow.rbc-selected.ant-popover-open .rbc-event-content {
    border-color: var(--event-yellow);
}

/* Table event colors end */

.calendar.calendar-week .rbc-current-time-indicator {
    display: none;
    background-color: var(--calendar-content-active-color);
}

.calendar.calendar-day .rbc-time-view .rbc-time-header:after {
    top: 0;
}

.calendar.calendar-day .rbc-time-view .rbc-time-header .rbc-allday-cell .rbc-row {
    min-height: 0;
}

.calendar.calendar-day .rbc-time-view .current-time-dot {
    left: -1px;
    margin-top: calc(var(--calendar-indicator-size) / -2);
}

.calendar.calendar-day .rbc-time-view .rbc-current-time-indicator {
    display: none;
}

.calendar .rbc-time-view {
    position: relative;
    border: none;
    border-bottom: var(--border-width-base) var(--border-style-base) var(--calendar-time-border-bottom-color);
}

.calendar .rbc-time-view .rbc-time-content {
    overflow-x: hidden;
    border-color: var(--calendar-border-color);
}

.calendar .rbc-time-view .rbc-today {
    background-color: transparent;
}

.calendar .rbc-time-view .rbc-day-slot .rbc-time-slot {
    border: none;
}

.calendar .rbc-time-view .rbc-day-slot .rbc-timeslot-group {
    border-top: var(--border-width-base) var(--border-style-base) var(--calendar-border-color);
    border-bottom: none;
}

.calendar .rbc-time-view .rbc-time-column {
    margin-top: var(--calendar-time-mt);
}

.calendar .rbc-time-view .rbc-time-column:not(:nth-child(1)) .current-time {
    display: none;
}

.calendar .rbc-time-view .rbc-time-column:not(:nth-child(2)) .current-time-dot {
    display: none;
}

.calendar .rbc-time-view .rbc-time-column:not(:first-child):after {
    position: absolute;
    top: calc(-1 * var(--calendar-time-mt));
    right: -1px;
    width: 1px;
    height: var(--calendar-time-mt);
    content: '';
    background-color: var(--calendar-border-color);
}

.calendar .rbc-time-view .rbc-time-column .rbc-timeslot-group {
    min-height: var(--calendar-content-time-cell-header-min-h);
}

.calendar.calendar-week .rbc-label {
    margin-top: calc(var(--calendar-content-date-lh) + var(--calendar-time-date-mb));
    font-size: var(--calendar-content-cell-fz);
    line-height: var(--calendar-content-cell-lh);
}

.calendar .rbc-time-view .rbc-time-column .rbc-time-slot {
    margin-top: calc(var(--calendar-content-cell-lh) / -2);
}

.calendar .rbc-time-view .rbc-time-content > * + * > * {
    border-color: var(--calendar-border-color);
}

.calendar .rbc-time-view .rbc-time-content > *:first-child + * > * {
    border-left: none;
}

.calendar .rbc-time-view .rbc-timeslot-group {
    border-color: var(--calendar-border-color);
}

.calendar .rbc-time-view .rbc-time-gutter.rbc-time-column {
    position: relative;
    background-color: var(--calendar-bg);
    z-index: 4;
}

.calendar .rbc-time-view .rbc-time-gutter.rbc-time-column .rbc-timeslot-group {
    color: var(--calendar-color);
    text-align: right;
    border-bottom: none;
    font-size: var(--calendar-content-cell-fz);
    line-height: var(--calendar-content-cell-fz);
}

.calendar .rbc-time-view .rbc-time-header {
    border-right: none;
}

.calendar .rbc-time-view .rbc-time-header:after {
    position: absolute;
    top: calc(var(--calendar-content-date-lh) + var(--calendar-time-date-mb));
    left: 0;
    width: 100%;
    height: 1px;
    content: '';
    background-color: var(--calendar-border-color);
}

.calendar .rbc-time-view .rbc-time-header .rbc-label {
    color: var(--calendar-color);
    text-align: right;
    font-size: var(--calendar-content-cell-fz);
    line-height: var(--calendar-time-header-h);
}

.calendar .rbc-time-view .rbc-time-header .rbc-time-header-content {
    border: none;
}

.calendar .rbc-time-view .rbc-time-header .rbc-time-header-cell {
    min-height: auto;
}

.calendar .rbc-time-view .rbc-time-header .rbc-header {
    padding: 0 0 var(--calendar-time-date-mb) 0;
    border: none;
    font-size: var(--calendar-content-date-fz);
    line-height: var(--calendar-content-date-lh);
    font-weight: normal;
}

.calendar .rbc-time-view .rbc-time-header .rbc-header.rbc-today a span,
.calendar .rbc-time-view .rbc-time-header .rbc-header a .rbc-today span {
    display: inline-block;
    width: var(--calendar-content-cell-number-active-size);
    height: var(--calendar-content-cell-number-active-size);
    color: var(--calendar-bg);
    letter-spacing: -2px;
    border-radius: var(--border-radius-circle);
    background-color: var(--calendar-content-active-color);
    text-indent: -2px;
    font-weight: bold;
    line-height: var(--calendar-content-cell-number-active-size);
}

.calendar .rbc-time-view .rbc-time-header .rbc-header a {
    color: var(--calendar-color);
}

.calendar .rbc-time-view .rbc-time-header .rbc-allday-cell .rbc-row {
    min-height: 0;
}

.calendar .rbc-time-view .rbc-time-header .rbc-day-bg {
    border-left: var(--border-width-base) var(--border-style-base) var(--calendar-border-color);
}

.calendar .rbc-time-view .rbc-time-header .rbc-day-bg:first-child {
    border-color: transparent;
}

.calendar .rbc-time-view .current-time {
    position: absolute;
    z-index: 5;
    width: var(--calendar-current-time-w);
    margin-top: calc(var(--calendar-content-date-lh) / -2 + 1px);
    background-color: var(--calendar-bg);
    color: var(--calendar-content-active-color);
    font-size: var(--calendar-content-cell-fz);
    line-height: var(--calendar-content-date-lh);
    text-align: right;
}

.calendar .rbc-time-view .current-time-dot {
    position: absolute;
    left: calc(100% - var(--calendar-indicator-size) + 1px);
    z-index: 3;
    display: block;
    width: var(--calendar-indicator-size);
    height: var(--calendar-indicator-size);
    margin-top: calc(-1 * var(--calendar-indicator-size) + 1px);
    border: var(--border-width-base) var(--border-style-base) var(--calendar-bg);
    content: '';
    background-color: var(--calendar-content-active-color);
    border-radius: var(--border-radius-circle);
}

.calendar .rbc-time-view .current-time-dot:before,
.calendar .rbc-time-view .current-time-dot:after {
    top: 4px;
    position: absolute;
    right: 100%;
    display: block;
    width: 1000px;
    height: 1px;
    content: '';
    background-color: var(--calendar-content-active-color);
}

.calendar .rbc-time-view .current-time-dot:after {
    left: 100%;
}

.event-popover .rc-tooltip-arrow {
    margin: -9px 0 0 !important;
}

.event-popover .rc-tooltip-content {
    transform: translate(0, -6px);
}

.event-popover.rc-tooltip-placement-top .rc-tooltip-content,
.event-popover.rc-tooltip-placement-topLeft .rc-tooltip-content,
.event-popover.rc-tooltip-placement-topRight .rc-tooltip-content {
    transform: translate(0, 6px);
}

.event-popover.rc-tooltip-placement-top .rc-tooltip-arrow,
.event-popover.rc-tooltip-placement-topLeft .rc-tooltip-arrow,
.event-popover.rc-tooltip-placement-topRight .rc-tooltip-arrow {
    margin: 0 0 -10px !important;
}

.event-popover.rc-tooltip-placement-left .rc-tooltip-content,
.event-popover.rc-tooltip-placement-leftTop .rc-tooltip-content,
.event-popover.rc-tooltip-placement-leftBottom .rc-tooltip-content {
    transform: translate(6px, 0);
}

.event-popover.rc-tooltip-placement-left .rc-tooltip-arrow,
.event-popover.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.event-popover.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    margin: 0 -9px 0 0 !important;
    box-shadow: 1px -1px 0 var(--box-shadow-color-base) !important;
}

.event-popover.rc-tooltip-placement-right .rc-tooltip-content,
.event-popover.rc-tooltip-placement-rightTop .rc-tooltip-content,
.event-popover.rc-tooltip-placement-rightBottom .rc-tooltip-content {
    transform: translate(-6px, 0);
}

.event-popover.rc-tooltip-placement-right .rc-tooltip-arrow,
.event-popover.rc-tooltip-placement-rightTop .rc-tooltip-arrow,
.event-popover.rc-tooltip-placement-rightBottom .rc-tooltip-arrow {
    margin: 0 0 0 -10px !important;
    box-shadow: -1px 1px 0 var(--box-shadow-color-base) !important;
}

.event-popover .ant-popover-inner .ant-popover-inner-content {
    padding: 0;
}

.event-details {
    --calendar-event-details-w: 225px;
    --calendar-event-details-p: var(--margin-base) var(--margin-base) calc(var(--margin-base) - 2px);
    --calendar-event-details-fz: 14px;
    --calendar-event-details-lh: 22px;
    --calendar-event-details-color: var(--primary-default);
    --calendar-event-details-title-mb: 7px;
    --calendar-event-details-border-color: var(--secondary-lighter);
    --calendar-event-details-contact-p: 16px 0 0 0;
    --calendar-event-details-info-m: 16px 0 0 0;

    width: var(--calendar-event-details-w);
    padding: var(--calendar-event-details-p);
}

.event-details h4 {
    margin-bottom: var(--calendar-event-details-title-mb);
}

.event-details hr {
    border-top: var(--border-width-base) var(--border-style-base) var(--calendar-event-details-border-color);
    border-left: none;
    border-bottom: none;
    border-right: none;
}

.event-details .contact-info {
    display: block;
    padding: var(--calendar-event-details-contact-p);
    border-top: var(--border-width-base) var(--border-style-base) var(--calendar-event-details-border-color);
    color: var(--calendar-event-details-color);
    font-size: var(--calendar-event-details-fz);
    line-height: var(--calendar-event-details-fz);
}

.event-details .contact-info + p.Paragraph_primary {
    margin: var(--calendar-event-details-info-m);
}

.event-details .text-underlined {
    color: var(--calendar-event-details-color);
    line-height: var(--calendar-event-details-lh);
    text-decoration: underline;
}

.event-details p.Paragraph_primary {
    display: block;
    border-top: var(--border-width-base) var(--border-style-base) var(--calendar-event-details-border-color);
    color: var(--calendar-event-details-color);
    margin-bottom: 0;
    padding-top: var(--calendar-event-details-title-mb);
}
