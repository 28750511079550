.form-group {
    --form-group-mb: 0;
    --form-group-info-text-color: var(--form-group-info-text-color-theme, var(--secondary-default));
    --form-group-info-spacing: 6px;
    --form-group-info-font-size: inherit;

    margin-bottom: var(--form-group-mb);
}

.form-group > p {
    color: var(--form-group-info-text-color);
    font-size: var(--form-group-info-font-size);
    margin-top: var(--form-group-info-spacing);
    margin-bottom: 0;
}

.form-group_disabled {
    cursor: not-allowed;
}

.form-group_disabled > p {
    opacity: 0.5;
}
