/* stylelint-disable */
@font-face {
    font-family: Inter;
    src: url('../assets/fonts/Inter-Regular.woff2') format('woff2'),
        url('../assets/fonts/Inter-Regular.woff') format('woff'),
        url('../assets/fonts/Inter-Regular.ttf') format('truetype'),
        url('../assets/fonts/Inter-Regular.svg#Inter-Regular') format('svg');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Inter;
    src: url('../assets/fonts/Inter-Bold.woff2') format('woff2'),
        url('../assets/fonts/Inter-Bold.woff') format('woff'),
        url('../assets/fonts/Inter-Bold.ttf') format('truetype'),
        url('../assets/fonts/Inter-Bold.svg#Inter-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Merriweather;
    src: url('../assets/fonts/Merriweather-Black.woff2') format('woff2'),
        url('../assets/fonts/Merriweather-Black.woff') format('woff'),
        url('../assets/fonts/Merriweather-Black.ttf') format('truetype'),
        url('../assets/fonts/Merriweather-Black.svg#Merriweather-Black')
            format('svg');
    font-weight: 800;
    font-style: normal;
}
