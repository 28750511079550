.Tooltip {
    --tooltip-max-width: 300px;
    --tooltip-inner-box-shadow: none;
    --tooltip-inner-p: var(--margin-base);
    --tooltip-inner-color: var(--tooltip-inner-color-theme, var(--primary-default));
    --tooltip-inner-border: var(--border-width-base) var(--border-style-base) var(--tooltip-inner-border-theme, var(--border-color));
    --tooltip-table-inner-p: calc(var(--margin-base) - 9px) var(--margin-base);
    --tooltip-arrow-after-zi: 1061;
    --tooltip-arrow-after-border-color: var(--tooltip-arrow-after-border-color-theme, var(--bright));
    --tooltip-dark-inner-bc: var(--tooltip-dark-inner-bc-theme, var(--secondary-default));
    --tooltip-content-arrow-border-color: var(--tooltip-content-arrow-border-color-theme, var(--secondary-default));
    --tooltip-bg: var(--tooltip-bg-theme, var(--bright));
}

.Tooltip.rc-tooltip {
    max-width: var(--tooltip-max-width);
}

.Tooltip .rc-tooltip-inner {
    padding: var(--tooltip-inner-p);
    box-shadow: var(--tooltip-inner-box-shadow);
    border: var(--tooltip-inner-border);
    color: var(--tooltip-inner-color);
    text-align: left;
    text-decoration: none;
    word-wrap: break-word;
    border-radius: var(--border-radius-base);
    background-color: var(--tooltip-bg);
}

.Tooltip .rc-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}

.Tooltip .ant-tooltiped-text:hover {
    cursor: pointer;
}

.Tooltip .rc-tooltip-table .rc-tooltip-inner {
    padding: var(--tooltip-table-inner-p);
    max-width: calc(var(--tooltip-max-width) - 50px);
}

.Tooltip .rc-tooltip-arrow:after {
    position: absolute;
    z-index: var(--tooltip-arrow-after-zi);
    display: block;
    overflow: visible;
    width: 0;
    height: 0;
    background: none;
    content: '';
    border-style: solid;
}

.Tooltip .rc-tooltip-arrow:before {
    display: none;
}

.Tooltip .rc-tooltip-placement-top .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-topLeft .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-topRight .rc-tooltip-arrow:after {
    bottom: calc(var(--tooltip-distance) - var(--tooltip-arrow-width));
    border-right-width: var(--tooltip-arrow-width);
    border-left-width: var(--tooltip-arrow-width);
    border-top-width: calc(var(--tooltip-arrow-width) + 1px);
    border-bottom-width: 0;
    border-top-color: var(--tooltip-arrow-after-border-color);
    top: calc(calc(var(--tooltip-arrow-width) + 2px) * -1);
    left: calc(var(--tooltip-arrow-width) * -1);
}

.Tooltip .rc-tooltip-placement-right .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-rightTop .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-rightBottom .rc-tooltip-arrow:after {
    border-width: var(--tooltip-arrow-width) var(--tooltip-arrow-width) var(--tooltip-arrow-width) 0;
    border-right-color: var(--tooltip-arrow-after-border-color);
    left: 1px;
    top: calc(var(--tooltip-arrow-width) * -1);
}

.Tooltip .rc-tooltip-placement-left .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-leftTop .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-leftBottom .rc-tooltip-arrow:after {
    border-width: var(--tooltip-arrow-width) 0 var(--tooltip-arrow-width) var(--tooltip-arrow-width);
    border-left-color: var(--tooltip-arrow-after-border-color);
    top: calc(var(--tooltip-arrow-width) * -1);
    right: 1px;
}

.Tooltip .rc-tooltip-placement-bottom .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-bottomLeft .rc-tooltip-arrow:after,
.Tooltip .rc-tooltip-placement-bottomRight .rc-tooltip-arrow:after {
    border-width: 0 var(--tooltip-arrow-width) var(--tooltip-arrow-width);
    border-bottom-color: var(--tooltip-arrow-after-border-color);
    top: 1px;
    left: calc(var(--tooltip-arrow-width) * -1);
}

/* dark theme */
.rc-tooltip-dark .ant-tooltip-arrow:after {
    display: none;
}

.rc-tooltip-content .Tooltip.rc-tooltip-dark {
    border: 0;
}

.Tooltip.rc-tooltip-dark .rc-tooltip-arrow:after {
    border-color: transparent;
}

.Tooltip.rc-tooltip-dark .rc-tooltip-inner {
    color: var(--bright);
    border: 0;
    background-color: var(--tooltip-dark-inner-bc);
}

.rc-tooltip-dark > .rc-tooltip-content > .rc-tooltip-arrow {
    box-shadow: none !important;
}

.rc-tooltip-dark.rc-tooltip-placement-top > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-topLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-topRight > .rc-tooltip-content > .rc-tooltip-arrow {
    border-right-color: var(--tooltip-content-arrow-border-color);
    border-bottom-color: var(--tooltip-content-arrow-border-color);
}

.rc-tooltip-dark.rc-tooltip-placement-right > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-rightTop > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-rightBottom > .rc-tooltip-content > .rc-tooltip-arrow {
    border-bottom-color: var(--tooltip-content-arrow-border-color);
    border-left-color: var(--tooltip-content-arrow-border-color);

}

.rc-tooltip-dark.rc-tooltip-placement-left > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-leftTop > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-leftBottom > .rc-tooltip-content > .rc-tooltip-arrow {
    border-top-color: var(--tooltip-content-arrow-border-color);
    border-right-color: var(--tooltip-content-arrow-border-color);
}

.rc-tooltip-dark.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-dark.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    border-top-color: var(--tooltip-content-arrow-border-color);
    border-left-color: var(--tooltip-content-arrow-border-color);
}
