.ProjectionChart-container {
    --projection-chart-color-0: var(--projection-chart-color-0-theme, var(--accent-dark));
    --projection-chart-color-1: var(--projection-chart-color-1-theme, var(--accent-light));
    --projection-chart-color-2: var(--projection-chart-color-2-theme, var(--accent-lighter));
    --projection-chart-color-3: var(--projection-chart-color-3-theme, var(--accent-lighter));
    --projection-chart-color-4: var(--projection-chart-color-4-theme, var(--secondary-default));
    --projection-chart-color-5: var(--projection-chart-color-5-theme, var(--secondary-light));
    --projection-chart-color-6: var(--projection-chart-color-6-theme, var(--primary-default));
    --projection-chart-color-7: var(--projection-chart-color-7-theme, var(--positive-default));
    --projection-chart-color-8: var(--projection-chart-color-8-theme, var(--positive-darker));

    --projection-chart-stroke-0: var(--projection-chart-color-0-theme, var(--accent-dark));
    --projection-chart-stroke-1: var(--projection-chart-color-1-theme, var(--accent-light));
    --projection-chart-stroke-2: var(--projection-chart-color-2-theme, var(--accent-lighter));
    --projection-chart-stroke-3: var(--projection-chart-stroke-3-theme, var(--primary-default));
    --projection-chart-stroke-4: var(--projection-chart-stroke-4-theme, var(--accent-light));
    --projection-chart-stroke-5: var(--projection-chart-color-4-theme, var(--secondary-default));
    --projection-chart-stroke-6: var(--projection-chart-color-5-theme, var(--secondary-light));
    --projection-chart-stroke-7: var(--projection-chart-color-6-theme, var(--primary-default));
    --projection-chart-stroke-8: var(--projection-chart-color-7-theme, var(--positive-default));
    --projection-chart-stroke-9: var(--projection-chart-color-8-theme, var(--positive-darker));

    --projection-chart-legend-external-color-0: var(--projection-chart-legend-external-color-theme, var(--secondary-default));
    --projection-chart-legend-external-color-1: var(--projection-chart-legend-external-color-theme, var(--secondary-default));
    --projection-chart-legend-color-0: var(--projection-chart-color-2-theme, var(--accent-lighter));
    --projection-chart-legend-color-1: var(--projection-chart-color-1-theme, var(--accent-light));
    --projection-chart-legend-color-2: var(--projection-chart-color-0-theme, var(--accent-dark));

    --projection-chart-positiveNegative-color-0: var(--projection-chart-positiveNegative-color-0-theme, var(--negative-default));
    --projection-chart-positiveNegative-color-1: var(--projection-chart-positiveNegative-color-1-theme, var(--positive-default));
    --projection-chart-positiveNegative-color-2: var(--projection-chart-positiveNegative-color-2-theme, var(--secondary-lighter));
    --projection-chart-positiveNegative-color-3: var(--projection-chart-positiveNegative-color-3-theme, var(--secondary-lighter));
    --projection-chart-positiveNegative-color-4: var(--projection-chart-positiveNegative-color-4-theme, var(--secondary-lighter));
    --projection-chart-positiveNegative-color-5: var(--projection-chart-positiveNegative-color-5-theme, var(--secondary-lighter));
    --projection-chart-positiveNegative-color-6: var(--projection-chart-positiveNegative-color-6-theme, var(--secondary-default));
    --projection-chart-positiveNegative-color-7: var(--projection-chart-positiveNegative-color-7-theme, var(--secondary-default));

    --projection-chart-positiveNegative-stroke-0: var(--projection-chart-positiveNegative-color-0-theme, var(--negative-default));
    --projection-chart-positiveNegative-stroke-1: var(--projection-chart-positiveNegative-color-1-theme, var(--positive-default));
    --projection-chart-positiveNegative-stroke-2: var(--projection-chart-positiveNegative-stroke-2-theme, var(--primary-default));
    --projection-chart-positiveNegative-stroke-3: var(--projection-chart-positiveNegative-stroke-3-theme, var(--primary-default));
    --projection-chart-positiveNegative-stroke-4: var(--projection-chart-positiveNegative-color-4-theme, var(--secondary-lighter));
    --projection-chart-positiveNegative-stroke-5: var(--projection-chart-positiveNegative-color-5-theme, var(--secondary-lighter));
    --projection-chart-positiveNegative-stroke-6: var(--projection-chart-positiveNegative-color-6-theme, var(--secondary-default));
    --projection-chart-positiveNegative-stroke-7: var(--projection-chart-positiveNegative-color-7-theme, var(--secondary-default));

    --projection-chart-positiveNegative-legend-external-color-0: var(--projection-chart-legend-external-color-theme, var(--secondary-default));
    --projection-chart-positiveNegative-legend-external-color-1: var(--projection-chart-legend-external-color-theme, var(--secondary-default));

    --projection-chart-positiveNegative-legend-color-0: var(--projection-chart-positiveNegative-color-2-theme, var(--secondary-lighter));
    --projection-chart-positiveNegative-legend-color-1: var(--projection-chart-positiveNegative-color-1-theme, var(--accent-light));
    --projection-chart-positiveNegative-legend-color-2: var(--projection-chart-positiveNegative-color-0-theme, var(--negative-lighter));

    --projection-chart-grid-stroke: var(--projection-chart-grid-stroke-theme, var(--secondary-light));
    --projection-chart-color: var(--projection-chart-color-theme, var(--primary-default));
    --projection-chart-label-fz: 13px;
    --projection-chart-halo-fill: var(--bright);
    --projection-chart-halo-stroke: var(--projection-chart-halo-stroke-theme, var(--secondary-default));
    --projection-chart-tooltip-bg: var(--projection-chart-tooltip-bg-theme, var(--bright));
    --projection-chart-tooltip-color: var(--projection-chart-tooltip-color-theme, var(--primary-default));
    --projection-chart-tooltip-stroke: var(--projection-chart-tooltip-stroke-theme, var(--secondary-light));
    --projection-chart-tooltip-fz: 14px;
    --projection-chart-tooltip-p: 11.5px var(--margin-base);
    --projection-chart-legend-fz: 16px;
    --projection-chart-legend-mr: var(--margin-base);
    --projection-chart-legend-point-size: 14px;
}

.ProjectionChart-container .Highcharts-projection-chart {
    overflow: visible !important;
}

.ProjectionChart-container .highcharts-container {
    overflow: visible !important;
}

.ProjectionChart-container .highcharts-root {
    overflow: visible !important;
}

.ProjectionChart-container .highcharts-background {
    fill: transparent;
}

.ProjectionChart-container .highcharts-color-0.highcharts-series,
.ProjectionChart-container .highcharts-color-0.highcharts-markers {
    fill: var(--projection-chart-color-0);
    stroke: var(--projection-chart-stroke-0);
}

.ProjectionChart-container .highcharts-color-0.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-0.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-1.highcharts-series,
.ProjectionChart-container .highcharts-color-1.highcharts-markers {
    fill: var(--projection-chart-color-1);
    stroke: var(--projection-chart-stroke-1);
}

.ProjectionChart-container .highcharts-color-1.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-1.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-2.highcharts-series,
.ProjectionChart-container .highcharts-color-2.highcharts-markers {
    fill: var(--projection-chart-color-2);
    stroke: var(--projection-chart-stroke-2);
}

.ProjectionChart-container .highcharts-color-2.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-2.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-3.highcharts-series,
.ProjectionChart-container .highcharts-color-3.highcharts-markers {
    fill: var(--projection-chart-color-3);
    stroke: var(--projection-chart-stroke-3);
}

.ProjectionChart-container .highcharts-color-3.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-3.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-4.highcharts-series,
.ProjectionChart-container .highcharts-color-4.highcharts-markers {
    fill: var(--projection-chart-color-4);
    stroke: var(--projection-chart-stroke-4);
}

.ProjectionChart-container .highcharts-color-4.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-4.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-5.highcharts-series,
.ProjectionChart-container .highcharts-color-5.highcharts-markers {
    fill: var(--projection-chart-color-5);
    stroke: var(--projection-chart-stroke-5);
}

.ProjectionChart-container .highcharts-color-5.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-5.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-6.highcharts-series,
.ProjectionChart-container .highcharts-color-6.highcharts-markers {
    fill: var(--projection-chart-color-6);
    stroke: var(--projection-chart-stroke-6);
}

.ProjectionChart-container .highcharts-color-6.highcharts-series .highcharts-point,
.ProjectionChart-container .highcharts-color-6.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-color-5);
    fill: var(--projection-chart-color-5);
}

.ProjectionChart-container .highcharts-color-6.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-6.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-7.highcharts-series,
.ProjectionChart-container .highcharts-color-7.highcharts-markers {
    fill: var(--projection-chart-color-7);
    stroke: var(--projection-chart-stroke-7);
}

.ProjectionChart-container .highcharts-color-7.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-7.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-8.highcharts-series,
.ProjectionChart-container .highcharts-color-8.highcharts-markers {
    fill: var(--projection-chart-color-8);
    stroke: var(--projection-chart-stroke-8);
}

.ProjectionChart-container .highcharts-color-8.highcharts-series .highcharts-area,
.ProjectionChart-container .highcharts-color-8.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-color-9.highcharts-series,
.ProjectionChart-container .highcharts-color-9.highcharts-markers {
    stroke: var(--projection-chart-stroke-9);
}

.ProjectionChart-container .highcharts-graph {
    stroke-width: 1px;
}

.ProjectionChart-container .highcharts-axis-line {
    display: none;
}

.ProjectionChart-container .highcharts-grid-line {
    stroke: var(--projection-chart-grid-stroke);
}

.ProjectionChart-container .highcharts-axis-labels,
.ProjectionChart-container .highcharts-axis-title {
    font-family: var(--body-font-family);
    fill: var(--projection-chart-color);
}

.ProjectionChart-container .highcharts-axis-title {
    font-weight: bold;
}

.ProjectionChart-container .highcharts-xaxis .highcharts-axis-line,
.ProjectionChart-container .highcharts-xaxis .highcharts-tick {
    stroke: none;
}

.ProjectionChart-container .highcharts-xaxis-labels {
    font-size: var(--projection-chart-label-fz);
}

.ProjectionChart-container .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: var(--projection-chart-grid-stroke);
    stroke-width: 2px;
    stroke-opacity: 0.6;
    stroke-dasharray: 5px, 4px;
}

.ProjectionChart-container .highcharts-yaxis-labels {
    font-size: var(--projection-chart-label-fz);
}

.ProjectionChart-container .highcharts-area-series .highcharts-halo {
    fill-opacity: 1;
    stroke-width: 1px;
    fill: var(--projection-chart-halo-fill);
    stroke: var(--projection-chart-halo-stroke);
}

.ProjectionChart-container .highcharts-markers {
    stroke-width: 0;
    fill: var(--projection-chart-color);
}

.ProjectionChart-container .highcharts-series-inactive {
    opacity: 1 !important;
}

.ProjectionChart-container .highcharts-crosshair {
    stroke: var(--projection-chart-grid-stroke);
    stroke-width: 2px;
    stroke-opacity: 0.6;
    stroke-dasharray: 5px, 4px;
}

.ProjectionChart-container .highcharts-tooltip {
    stroke: var(--projection-chart-tooltip-stroke);
    filter: none !important;
    z-index: 999 !important;
}

.ProjectionChart-container .highcharts-tooltip-box {
    fill: var(--projection-chart-tooltip-bg);
    fill-opacity: 1;
}

.ProjectionChart-container .highcharts-tooltip > span {
    font-size: var(--projection-chart-tooltip-fz);
    font-family: var(--body-font-family);
    padding: var(--projection-chart-tooltip-p);
    color: var(--projection-chart-tooltip-color);
    white-space: normal !important;
}

.ProjectionChart-container .projection-legend {
    margin: 0 auto;
    width: fit-content;
}

.ProjectionChart-container .projection-legend-item {
    display: inline-flex;
    align-items: center;
    color: var(--projection-chart-color);
    letter-spacing: 0.25px;
    font-size: var(--projection-chart-legend-fz);
    line-height: 1;
}

.ProjectionChart-container .projection-legend-item:not(:last-child) {
    margin-right: var(--projection-chart-legend-mr);
}

.ProjectionChart-container .projection-legend-item .legend-point {
    width: var(--projection-chart-legend-point-size);
    height: var(--projection-chart-legend-point-size);
    margin-right: calc(var(--projection-chart-legend-point-size) / 2);
    border-radius: var(--border-radius-circle);
}

.ProjectionChart-container .projection-legend-item .legend-name {
    font-family: var(--accent-font-family);
    font-weight: var(--accent-font-weight);
}

.ProjectionChart-container .projection-legend .legend-external-item-0 .legend-point {
    background-color: var(--projection-chart-legend-external-color-0);
}

.ProjectionChart-container .projection-legend .legend-external-item-1 .legend-point {
    background-color: var(--projection-chart-legend-external-color-1);
}

.ProjectionChart-container .projection-legend .legend-item-0 .legend-point {
    background-color: var(--projection-chart-legend-color-0);
}

.ProjectionChart-container .projection-legend .legend-item-1 .legend-point {
    background-color: var(--projection-chart-legend-color-1);
}

.ProjectionChart-container .projection-legend .legend-item-2 .legend-point {
    background-color: var(--projection-chart-legend-color-2);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-series-group {
    opacity: 0.75;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-series-3 .highcharts-halo {
    fill-opacity: 0;
    stroke-width: 0;
    fill: transparent;
    stroke: transparent;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-0);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-0);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-1);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-1);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-2);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-2);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-3);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-3);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-4);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-4);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-5);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-5);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-6);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-6);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-series,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-color-7);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-series .highcharts-area,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-markers .highcharts-area {
    fill-opacity: 1;
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-series .highcharts-point,
.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-markers .highcharts-point {
    fill: var(--projection-chart-positiveNegative-color-7);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-0);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-0);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-0);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-0.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-0);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-1);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-1);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-1);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-1.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-1);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-2);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-2);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-2);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-2.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-2);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-3);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-3);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-3);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-3.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-3);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-4);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-4);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-4);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-4.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-4);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-5);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-5);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-5);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-5.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-5);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-6);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-6);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-6);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-6.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-6);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-series {
    stroke: var(--projection-chart-positiveNegative-stroke-7);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-series .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-7);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-markers {
    fill: var(--projection-chart-positiveNegative-stroke-7);
}

.ProjectionChart-container.highcharts-positiveAndNegative .highcharts-color-7.highcharts-markers .highcharts-point {
    stroke: var(--projection-chart-positiveNegative-stroke-7);
}

.ProjectionChart-container.highcharts-positiveAndNegative .projection-legend .legend-external-item-0 .legend-point {
    background-color: var(--projection-chart-positiveNegative-legend-external-color-0);
    opacity: 0.75;
}

.ProjectionChart-container.highcharts-positiveAndNegative .projection-legend .legend-external-item-1 .legend-point {
    background-color: var(--projection-chart-positiveNegative-legend-external-color-1);
    opacity: 0.75;
}

.ProjectionChart-container.highcharts-positiveAndNegative .projection-legend .legend-item-0 .legend-point {
    background-color: var(--projection-chart-positiveNegative-legend-color-0);
    opacity: 0.75;
}

.ProjectionChart-container.highcharts-positiveAndNegative .projection-legend .legend-item-1 .legend-point {
    background-color: var(--projection-chart-positiveNegative-legend-color-1);
    opacity: 0.75;
}

.ProjectionChart-container.highcharts-positiveAndNegative .projection-legend .legend-item-2 .legend-point {
    background-color: var(--projection-chart-positiveNegative-legend-color-2);
    opacity: 0.75;
}
