.container {
    --container-max-with: 1202px;
    --container-padding-horizontal: 40px;

    width: 100%;
    max-width: var(--container-max-with);
    margin: 0 auto;
    padding: 0 var(--container-padding-horizontal);
}

.row {
    --row-margin-bottom: 30px;
    --grid-column-padding: 15px;

    margin-left: calc(var(--grid-column-padding) * -1);
    margin-right: calc(var(--grid-column-padding) * -1);
    margin-bottom: var(--row-margin-bottom);
    display: grid;
    grid-template-columns: repeat(12, [col] 1fr);
}

/* stylelint-disable */
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12,
.col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-xl-2, .col-xl-1, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
    padding-left: var(--grid-column-padding);
    padding-right: var(--grid-column-padding);
    grid-column: span 12;
    min-width: 0;
}
/* stylelint-enable */

.col-1 {
    grid-column: span 1;
    min-width: 0;
}

.col-2 {
    grid-column: span 2;
    min-width: 0;
}

.col-3 {
    grid-column: span 3;
    min-width: 0;
}

.col-4 {
    grid-column: span 4;
    min-width: 0;
}

.col-5 {
    grid-column: span 5;
    min-width: 0;
}

.col-6 {
    grid-column: span 6;
    min-width: 0;
}

.col-7 {
    grid-column: span 7;
    min-width: 0;
}

.col-8 {
    grid-column: span 8;
    min-width: 0;
}

.col-9 {
    grid-column: span 9;
    min-width: 0;
}

.col-10 {
    grid-column: span 10;
    min-width: 0;
}

.col-11 {
    grid-column: span 11;
    min-width: 0;
}

.col-12 {
    grid-column: span 12;
    min-width: 0;
}

@media (min-width: 576px) {
    .col-sm-1 {
        grid-column: span 1;
        min-width: 0;
    }

    .col-sm-2 {
        grid-column: span 2;
        min-width: 0;
    }

    .col-sm-3 {
        grid-column: span 3;
        min-width: 0;
    }

    .col-sm-4 {
        grid-column: span 4;
        min-width: 0;
    }

    .col-sm-5 {
        grid-column: span 5;
        min-width: 0;
    }

    .col-sm-6 {
        grid-column: span 6;
        min-width: 0;
    }

    .col-sm-7 {
        grid-column: span 7;
        min-width: 0;
    }

    .col-sm-8 {
        grid-column: span 8;
        min-width: 0;
    }

    .col-sm-9 {
        grid-column: span 9;
        min-width: 0;
    }

    .col-sm-10 {
        grid-column: span 10;
        min-width: 0;
    }

    .col-sm-11 {
        grid-column: span 11;
        min-width: 0;
    }

    .col-sm-12 {
        grid-column: span 12;
        min-width: 0;
    }
}

@media (min-width: 768px) {
    .col-md-1 {
        grid-column: span 1;
        min-width: 0;
    }

    .col-md-2 {
        grid-column: span 2;
        min-width: 0;
    }

    .col-md-3 {
        grid-column: span 3;
        min-width: 0;
    }

    .col-md-4 {
        grid-column: span 4;
        min-width: 0;
    }

    .col-md-5 {
        grid-column: span 5;
        min-width: 0;
    }

    .col-md-6 {
        grid-column: span 6;
        min-width: 0;
    }

    .col-md-7 {
        grid-column: span 7;
        min-width: 0;
    }

    .col-md-8 {
        grid-column: span 8;
        min-width: 0;
    }

    .col-md-9 {
        grid-column: span 9;
        min-width: 0;
    }

    .col-md-10 {
        grid-column: span 10;
        min-width: 0;
    }

    .col-md-11 {
        grid-column: span 11;
        min-width: 0;
    }

    .col-md-12 {
        grid-column: span 12;
        min-width: 0;
    }
}

@media (min-width: 992px) {
    .col-lg-1 {
        grid-column: span 1;
        min-width: 0;
    }

    .col-lg-2 {
        grid-column: span 2;
        min-width: 0;
    }

    .col-lg-3 {
        grid-column: span 3;
        min-width: 0;
    }

    .col-lg-4 {
        grid-column: span 4;
        min-width: 0;
    }

    .col-lg-5 {
        grid-column: span 5;
        min-width: 0;
    }

    .col-lg-6 {
        grid-column: span 6;
        min-width: 0;
    }

    .col-lg-7 {
        grid-column: span 7;
        min-width: 0;
    }

    .col-lg-8 {
        grid-column: span 8;
        min-width: 0;
    }

    .col-lg-9 {
        grid-column: span 9;
        min-width: 0;
    }

    .col-lg-10 {
        grid-column: span 10;
        min-width: 0;
    }

    .col-lg-11 {
        grid-column: span 11;
        min-width: 0;
    }

    .col-lg-12 {
        grid-column: span 12;
        min-width: 0;
    }
}

@media (min-width: 1200px) {
    .col-xl-1 {
        grid-column: span 1;
        min-width: 0;
    }

    .col-xl-2 {
        grid-column: span 2;
        min-width: 0;
    }

    .col-xl-3 {
        grid-column: span 3;
        min-width: 0;
    }

    .col-xl-4 {
        grid-column: span 4;
        min-width: 0;
    }

    .col-xl-5 {
        grid-column: span 5;
        min-width: 0;
    }

    .col-xl-6 {
        grid-column: span 6;
        min-width: 0;
    }

    .col-xl-7 {
        grid-column: span 7;
        min-width: 0;
    }

    .col-xl-8 {
        grid-column: span 8;
        min-width: 0;
    }

    .col-xl-9 {
        grid-column: span 9;
        min-width: 0;
    }

    .col-xl-10 {
        grid-column: span 10;
        min-width: 0;
    }

    .col-xl-11 {
        grid-column: span 11;
        min-width: 0;
    }

    .col-xl-12 {
        grid-column: span 12;
        min-width: 0;
    }
}
