.Footer__social {
    --footer-social-hover-color: var(--footer-social-hover-color-theme, var(--secondary-light));
    white-space: nowrap;
}

.Footer__social a {
    text-decoration: none;
}

.Footer__social a:hover {
    color: var(--footer-social-hover-color);
}
