.rc-tooltip-placement-left,
.rc-tooltip-placement-leftTop,
.rc-tooltip-placement-leftBottom {
    padding-right: var(--tooltip-distance);
}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    right: calc(var(--tooltip-distance) - 23px);
    box-shadow: 1px -1px 0 var(--popover-box-shadow-theme) !important;
}

.rc-tooltip-placement-left > .rc-tooltip-content > .rc-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
}

.rc-tooltip-placement-leftTop > .rc-tooltip-content > .rc-tooltip-arrow {
    transform: translateY(-50%) rotate(45deg);
}

.rc-tooltip-placement-leftBottom > .rc-tooltip-content > .rc-tooltip-arrow {
    transform: translateY(50%) rotate(45deg);
}

.rc-tooltip-placement-left > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom > .rc-tooltip-content > .rc-tooltip-arrow {
    right: 6px;
    border-top-color: var(--popover-rc-tooltip-inner-bc);
    border-right-color: var(--popover-rc-tooltip-inner-bc);
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.rc-tooltip-placement-left .rc-tooltip-arrow {
    top: 50%;
    transform: translateY(-50%);
}

.rc-tooltip-placement-leftTop .rc-tooltip-arrow {
    top: var(--popover-arrow-offset-vertical);
}

.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
    bottom: var(--popover-arrow-offset-vertical);
}
