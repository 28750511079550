.Toggle,
.Toggle input.switch-round:checked + label:before,
.toggle-error input.switch-round + label:before,
.Toggle input.switch + label:before,
.Toggle input.switch + label:after {
    --toogle-color: var(--toogle-color-theme, var(--accent-default));
    --toggle-circle-width: 16px;
    --toggle-duration: 0.2s;
    --toggle-width: 40px;
    --toggle-height: 20px;
    --toggle-border-radius: 100px;
    --toggle-padding: 3px;
    --toggle-error-bc: var(--toggle-error-bc-theme, var(--negative-default));
    --toggle-switch-bc: var(--togle-switch-bg-theme, var(--secondary-default));

    display: inline-block;
}

.Toggle {
    display: inline-block;
}

.Toggle input.switch {
    display: none;
}

.Toggle input.switch + label {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    border-radius: var(--toggle-border-radius);
}

.Toggle input.switch + label:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.Toggle input.switch + label span {
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.Toggle input.switch-round + label {
    display: flex;
    align-items: center;
    width: var(--toggle-width);
    height: var(--toggle-height);
    background-color: var(--toggle-switch-bc);
    border-radius: var(--toggle-border-radius);
}

.Toggle input.switch-round + label:before,
.Toggle input.switch-round + label:after {
    position: absolute;
    display: block;
    content: '';
    transition: var(--toggle-duration);
}

.Toggle input.switch-round + label:before {
    width: 100%;
    height: 100%;
    border-radius: var(--toggle-border-radius);
}

.Toggle input.switch-round + label:after {
    --toggle-padding: 3px;
    --toggle-switch-round-bc: var(--togle-switch-circle-bg-theme, var(--background-content));

    position: absolute;
    left: var(--toggle-padding);
    width: var(--toggle-circle-width);
    height: var(--toggle-circle-width);
    background-color: var(--toggle-switch-round-bc);
    border-radius: 100%;
}

.Toggle input.switch-round:checked + label:before {
    --toggle-checked-border: var(--border-width-base) var(--border-style-base) var(--togle-checked-switch-border-color-theme, var(--accent-dark));
    --toogle-color: var(--toogle-color-theme, var(--accent-default));

    border: var(--toggle-checked-border);
    background-color: var(--toogle-color);
}

.Toggle input:disabled.switch-round + label {
    opacity: 0.5;
    cursor: not-allowed;
}

.Toggle.toggle-error input.switch-round + label:before {
    --toggle-error-bc: var(--toggle-error-bc-theme, var(--negative-default));
    --toggle-error-border: var(--border-width-base) var(--border-style-base) var(--toggle-error-border-theme, var(--negative-light));

    border: var(--toggle-error-border);
    background-color: var(--toggle-error-bc);
}

.Toggle input.switch-round:checked + label:after {
    left: calc(100% - var(--toggle-padding) - var(--toggle-circle-width));
}
