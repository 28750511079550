.Link {
    --link-fz: 14px;
    --link-lh: 1.57142857;
    --link-hover-color: var(--link-hover-color-theme, var(--secondary-dark));
    --link-active-color: var(--link-active-color-theme, var(--secondary-darker));
    --link-disabled-color: var(--link-disabled-color-theme, var(--secondary-light));
    --link-secondary-color: var(--link-secondary-color-theme, var(--secondary-default));
    --link-secondary-hover-color: var(--link-secondary-hover-color-theme, var(--secondary-dark));
    --link-color: var(--link-color-theme, var(--secondary-default));

    height: auto;
    border: none;
    color: var(--link-color);
    font-size: var(--link-fz);
    line-height: var(--link-lh);
    text-decoration: underline;
    cursor: pointer;
}

.Link:hover {
    color: var(--link-hover-color);
}

.Link:active {
    color: var(--link-active-color);
}

.Link:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.Link-disabled {
    color: var(--link-disabled-color);
    pointer-events: none;
}

.Link_secondary {
    color: var(--link-secondary-color);
}

.Link_secondary:hover {
    color: var(--link-secondary-hover-color);
}
