.Highcharts-bar-chart {
    --bar-chart-point-w: 25px;
    --bar-chart-color: var(--bar-chart-color-theme, var(--primary-default));
    --bar-chart-label-mb: 5px;
    --bar-chart-label-pr: calc(var(--margin-base) / 2);
    --bar-chart-label-fz: 16px;
    --bar-chart-label-lh: 22px;
    --bar-chart-color-0: var(--bar-chart-color-0-theme, var(--accent-default));
    --bar-chart-color-1: var(--bar-chart-color-1-theme, var(--accent-lighter));
    --bar-chart-color-2: var(--bar-chart-color-2-theme, var(--accent-light));
    --bar-chart-color-3: var(--bar-chart-color-3-theme, var(--accent-dark));
    --bar-chart-color-4: var(--bar-chart-color-4-theme, var(--secondary-default));
    --bar-chart-color-5: var(--bar-chart-color-5-theme, var(--secondary-light));
    --bar-chart-color-6: var(--bar-chart-color-6-theme, var(--primary-default));
    --bar-chart-color-7: var(--bar-chart-color-7-theme, var(--positive-lighter));
    --bar-chart-color-8: var(--bar-chart-color-8-theme, var(--positive-default));
    --bar-chart-color-9: var(--bar-chart-color-9-theme, var(--positive-darker));
}

.Highcharts-bar-chart .highcharts-axis-line {
    stroke: var(--bar-chart-color);
}

.Highcharts-bar-chart .highcharts-color-0.highcharts-point {
    fill: var(--bar-chart-color-0);
    stroke: var(--bar-chart-color-0);
}

.Highcharts-bar-chart .highcharts-color-1.highcharts-point {
    fill: var(--bar-chart-color-1);
    stroke: var(--bar-chart-color-1);
}

.Highcharts-bar-chart .highcharts-color-2.highcharts-point {
    fill: var(--bar-chart-color-2);
    stroke: var(--bar-chart-color-2);
}

.Highcharts-bar-chart .highcharts-color-3.highcharts-point {
    fill: var(--bar-chart-color-3);
    stroke: var(--bar-chart-color-3);
}

.Highcharts-bar-chart .highcharts-color-4.highcharts-point {
    fill: var(--bar-chart-color-4);
    stroke: var(--bar-chart-color-4);
}

.Highcharts-bar-chart .highcharts-color-5.highcharts-point {
    fill: var(--bar-chart-color-5);
    stroke: var(--bar-chart-color-5);
}

.Highcharts-bar-chart .highcharts-color-6.highcharts-point {
    fill: var(--bar-chart-color-6);
    stroke: var(--bar-chart-color-6);
}

.Highcharts-bar-chart .highcharts-color-7.highcharts-point {
    fill: var(--bar-chart-color-7);
    stroke: var(--bar-chart-color-7);
}

.Highcharts-bar-chart .highcharts-color-8.highcharts-point {
    fill: var(--bar-chart-color-8);
    stroke: var(--bar-chart-color-8);
}

.Highcharts-bar-chart .highcharts-color-9.highcharts-point {
    fill: var(--bar-chart-color-9);
    stroke: var(--bar-chart-color-9);
}

.Highcharts-bar-chart .highcharts-point {
    width: var(--bar-chart-point-w);
}

.Highcharts-bar-chart .highcharts-background {
    fill: transparent;
}

.Highcharts-bar-chart .highcharts-axis-labels {
    font-family: var(--body-font-family);
}

.Highcharts-bar-chart .highcharts-axis-labels .Form-control-label {
    margin-bottom: var(--bar-chart-label-mb);
}

.Highcharts-bar-chart .highcharts-axis-labels .bar-chart__label {
    min-width: 100px;
    line-height: var(--bar-chart-label-lh);
    white-space: initial;
}

.Highcharts-bar-chart .highcharts-axis-labels > span {
    padding-right: var(--bar-chart-label-pr);
    color: var(--bar-chart-color);
    font-size: var(--bar-chart-label-fz);
    line-height: var(--bar-chart-label-lh);
}
