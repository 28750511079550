.Skeleton {
    --skeleton-min-h: var(--indent-base);
    --skeleton-background: linear-gradient(-90deg, var(--skeleton-background-theme, var(--secondary-lighter)) 0%, var(--white) 50%, var(--skeleton-background-theme, var(--secondary-lighter)) 100%);
    --skeleton-animation: left-to-right 1.2s ease-in-out infinite;
    --skeleton-line-border-radius: 4px;
    --skeleton-before-background: linear-gradient(-90deg, var(--skeleton-background-theme, var(--secondary-lighter)) 0%, var(--white) 50%, var(--skeleton-background-theme, var(--secondary-lighter)) 100%);
    --skeleton-before-animation: left-to-right 1.2s ease-in-out infinite;

    position: relative;
    display: inline-block;
    height: 100%;
    width: 100%;
    min-height: var(--skeleton-min-h);
    background: var(--skeleton-background);
    background-size: 400% 400%;
    animation: var(--skeleton-animation);
}

.Skeleton.line {
    display: inline-block;
    border-radius: var(--skeleton-line-border-radius);
}

.Skeleton.circle {
    border-radius: 100%;
    display: block;
}

.Skeleton:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: var(--skeleton-before-background);
    background-size: 400% 400%;
    content: '';
    border-radius: inherit;
    animation: var(--skeleton-before-animation);
}
@keyframes left-to-right {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: -135% 0;
    }
}
