.ColumnFilter {
    --table-column-filter-button-bg: var(--table-column-filter-button-bg-theme, var(--secondary-lighter));
    --table-column-filter-button-icon-color: var(--table-column-filter-button-icon-color-theme, var(--primary-default));
    --table-column-filter-button-opened-icon-color: var(--table-column-filter-button-opened-icon-color-theme, var(--primary-default));

    position: relative;
    width: var(--table-filter-width);
}

.Table th.column-sort.column-filter .ColumnFilter {
    margin-right: var(--table-cell-horizontal-padding);
}

.ColumnFilter .SelectButton {
    display: inline-block;
    padding: 0;
    opacity: 0;
    border: 1px solid transparent;
    outline: none;
    cursor: pointer;
    transition: 0s;
    background-color: var(--table-column-filter-button-bg);
}

.ColumnFilter .selected__values {
    text-indent: 9999em;
}

.ColumnFilter .SelectButton:hover {
    border-color: var(--secondary-default);
}

.ColumnFilter .SelectButton.opened {
    border-color: transparent;
}

.ColumnFilter .SelectButton.opened:hover {
    border-color: var(--secondary-default);
}

.ColumnFilter .SelectButton .Icon {
    color: var(--table-column-filter-button-icon-color);
}

.ColumnFilter .SelectButton.opened .Icon {
    color: var(--table-column-filter-button-opened-icon-color);
    transform: translate3d(-50%, -50%, 0) rotate(-180deg);
}

.ColumnFilter .filter .SelectButton {
    opacity: 1;
    background-color: var(--accent-default);
    border-color: var(--accent-default);
}

.Table th.filtered .ColumnFilter .SelectButton {
    opacity: 1;
    background-color: var(--accent-default);
    border-color: var(--accent-default);
}

.Table th.filtered .ColumnFilter .SelectButton .Icon {
    color: var(--white);
}

.Table th:hover .ColumnFilter .SelectButton,
.Table th .ColumnFilter .SelectButton.opened {
    opacity: 1;
}

.ColumnFilter.ColumnConfig {
    align-items: center;
    background-color: var(--secondary-lighter);
    border-radius: 4px;
    color: var(--primary-default);
    cursor: pointer;
    display: block;
    float: right;
    font-size: 10px;
    height: 20px;
    justify-content: center;
    margin: 0;
    width: 20px;
}

.ColumnFilter.ColumnConfig .Icon {
    position: relative;
    right: 0;
}

.ColumnFilter .select__dropdown__wrapper {
    right: -5px;
    width: max-content;
}

.Table th:first-child .ColumnFilter .select__dropdown__wrapper {
    left: -5px;
}

.ColumnFilter .select__dropdown__wrapper .select__dropdown {
    margin-top: 5px;
}
