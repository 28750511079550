.Title {
    --title-1-margin-bottom: 34px;
    --title-1-fz: 28px;
    --title-1-lh: 1.214285;
    --title-2-margin-bottom: 28px;
    --title-2-fz: 22px;
    --title-2-lh: 1.27272727;
    --title-3-margin-bottom: 24px;
    --title-3-fz: 18px;
    --title-3-lh: 18px;
    --title-4-margin-bottom: 22px;
    --title-4-fz: 16px;
    --title-4-lh: 1.375;
    --titile-color: var(--titile-color-theme, var(--primary-default));
    --titile-a-hover-color: var(--titile-a-hover-color-theme, var(--primary-dark));
    --title-icon-fz: 12px;
    --title-icon-ml: 7px;
    --title-content-box-first-m: 0 var(--padding-base);
    --title-content-box-first-p: 0 var(--padding-base) var(--padding-base);
    --title-content-box-first-mt: -22px;
    --title-content-box-title-first-border-bottom: var(--border-width-base) var(--border-style-base) var(--title-content-box-title-first-border-bottom-color-theme, var(--secondary-lighter));

    color: var(--titile-color);
}

.Title a {
    color: inherit;
}

.Title a:hover {
    color: var(--titile-a-hover-color);
}

.Title .Icon {
    display: inline-block;
    font-size: var(--title-icon-fz);
    line-height: inherit;
    margin-left: var(--title-icon-ml);
    vertical-align: middle;
}

.Title .Content-box>.Title :first-child {
    margin: var(--title-content-box-first-m);
    padding: var(--title-content-box-first-p);
}

.Title .Content-box>.Title:first-child+.ant-tabs,
.Title .Content-box>.Title:first-child+.calendar,
.Title .Content-box>.Title:first-child+.table-wrapper-no-header {
    margin-top: var(--title-content-box-first-mt);
}

.Title .Content-box_title>.Title :first-child {
    border-bottom: 1px solid var(--title-content-box-title-color-theme, var(--secondary-lighter));
}

/* H1 */
h1,
.title-1 {
    font-family: var(--accent-font-family);
    font-size: var(--title-1-fz);
    font-weight: var(--accent-font-weight);
    line-height: var(--title-1-lh);
    margin-bottom: var(--title-1-margin-bottom);
}

/* H2 */
h2,
.title-2 {
    font-family: var(--accent-font-family);
    font-size: var(--title-2-fz);
    font-weight: var(--accent-font-weight);
    line-height: var(--title-2-lh);
    margin-bottom: var(--title-2-margin-bottom);
}

/* H3 */
h3,
.title-3 {
    font-family: var(--body-font-family);
    font-size: var(--title-3-fz);
    font-weight: var(--accent-font-weight);
    line-height: var(--title-3-lh);
    margin-bottom: var(--title-3-margin-bottom);
}

/* H4 */
h4,
.title-4 {
    font-size: var(--title-4-fz);
    font-family: var(--body-font-family);
    font-weight: var(--accent-font-weight);
    line-height: var(--title-4-lh);
    margin-bottom: var(--title-4-margin-bottom);
}
