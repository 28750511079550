.Notification {
    --notification-width: 350px;
    --notification-height-slide: 50px;
    --notification-zindex: 9999;
    --notification-margin: var(--margin-base);
    --notification-notice-p: 12px 15px;
    --notification-notice-min-width: 250px;
    --notification-notice-max-width: 350px;
    --notification-notice-border-radius: 3px;
    --notification-notice-content-fz: 14px;
    --notification-notice-content-lh: 22px;
    --notification-notice-close-button-fz: 14px;
    --notification-notice-close-button-p: 5px;
    --notification-notice-close-button-ml: 6px;
    --notification-notice-bc: var(--notification-notice-bc-theme, var(--background-content));
    --notification-color: var(--notification-color-theme, var(--primary-default));
    --notification-notice-box-shadow: var(--notification-box-shadow-theme);

    position: fixed;
    top: var(--notification-margin);
    right: var(--notification-margin);
    bottom: var(--notification-margin);
    left: var(--notification-margin);
    z-index: var(--notification-zindex);
}

.Notification > div {
    display: flex;
    flex-direction: column;
}

.Notification-topLeft {
    right: unset;
    bottom: unset;
}

.Notification-top {
    left: 50%;
    right: unset;
    bottom: unset;
    transform: translateX(-50%);
}

.Notification-top > div {
    align-items: center;
}

.Notification-topRight {
    left: unset;
    bottom: unset;
}

.Notification-topRight > div {
    align-items: flex-end;
}

.Notification-bottomLeft {
    top: unset;
    right: unset;
}

.Notification-bottom {
    left: 50%;
    right: unset;
    top: unset;
    transform: translateX(-50%);
}

.Notification-bottom > div {
    align-items: center;
}

.Notification-bottomRight {
    top: unset;
    left: unset;
}

.Notification-bottomRight > div {
    align-items: flex-end;
}

.Notification-notice {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: fit-content;
    min-width: var(--notification-notice-min-width);
    max-width: var(--notification-notice-max-width);
    padding: var(--notification-notice-p);
    background-color: var(--notification-notice-bc);
    border-radius: var(--notification-notice-border-radius);
    box-shadow: var(--notification-notice-box-shadow);
}

.Notification-notice:not(:last-child) {
    margin-bottom: var(--notification-margin);
}

.Notification-notice-content {
    display: flex;
    align-items: center;
    min-height: calc(var(--notification-notice-close-button-fz) + 2 * var(--notification-notice-close-button-p));
    color: var(--notification-color);
    font-size: var(--notification-notice-content-fz);
    line-height: var(--notification-notice-content-lh);
}

.Notification-notice-close {
    padding: var(--notification-notice-close-button-p);
    margin-left: var(--notification-notice-close-button-ml);
    border: none;
    background: transparent;
    color: var(--notification-color);
    font-size: var(--notification-notice-close-button-fz);
    line-height: 0.8;
    cursor: pointer;
}

.Notification-fade-enter,
.Notification-fade-appear,
.Notification-fade-leave {
    animation-duration: 0.3s;
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-fill-mode: both;
}

.Notification-fade-enter,
.Notification-fade-appear {
    opacity: 0;
    animation-play-state: paused;
}

.Notification-fade-leave {
    animation-play-state: paused;
}

.Notification-fade-enter.Notification-fade-enter-active,
.Notification-fade-appear.Notification-fade-appear-active {
    animation-name: NotificationSlideIn;
    animation-play-state: running;
}

.Notification-top .Notification-fade-enter.Notification-fade-enter-active,
.Notification-top .Notification-fade-appear.Notification-fade-appear-active {
    animation-name: NotificationTopSlideIn;
}

.Notification-bottom .Notification-fade-enter.Notification-fade-enter-active,
.Notification-bottom .Notification-fade-appear.Notification-fade-appear-active {
    animation-name: NotificationBottomSlideIn;
}

.Notification-topLeft .Notification-fade-enter.Notification-fade-enter-active,
.Notification-topLeft .Notification-fade-appear.Notification-fade-appear-active,
.Notification-bottomLeft .Notification-fade-enter.Notification-fade-enter-active,
.Notification-bottomLeft .Notification-fade-appear.Notification-fade-appear-active {
    animation-name: NotificationLeftSlideIn;
}

.Notification-fade-leave.Notification-fade-leave-active {
    animation-name: NotificationSlideOut;
    animation-play-state: running;
}

.Notification-top .Notification-fade-leave.Notification-fade-leave-active {
    animation-name: NotificationTopSlideOut;
}

.Notification-bottom .Notification-fade-leave.Notification-fade-leave-active {
    animation-name: NotificationBottomSlideOut;
}

.Notification-topLeft .Notification-fade-leave.Notification-fade-leave-active,
.Notification-bottomLeft .Notification-fade-leave.Notification-fade-leave-active {
    animation-name: NotificationLeftSlideOut;
}

@keyframes NotificationSlideIn {
    0% {
        left: var(--notification-width);
        opacity: 0;
    }

    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes NotificationSlideOut {
    0% {
        left: 0;
        opacity: 1;
    }

    100% {
        left: var(--notification-width);
        margin-bottom: 0;
        opacity: 0;
    }
}

@keyframes NotificationLeftSlideIn {
    0% {
        right: var(--notification-width);
        opacity: 0;
    }

    100% {
        right: 0;
        opacity: 1;
    }
}

@keyframes NotificationLeftSlideOut {
    0% {
        right: 0;
        opacity: 1;
    }

    100% {
        right: var(--notification-width);
        margin-bottom: 0;
        opacity: 0;
    }
}

@keyframes NotificationTopSlideIn {
    0% {
        bottom: var(--notification-height-slide);
        opacity: 0;
    }

    100% {
        bottom: 0;
        opacity: 1;
    }
}

@keyframes NotificationTopSlideOut {
    0% {
        bottom: 0;
        opacity: 1;
    }

    100% {
        bottom: var(--notification-height-slide);
        margin-bottom: 0;
        opacity: 0;
    }
}

@keyframes NotificationBottomSlideIn {
    0% {
        top: var(--notification-height-slide);
        opacity: 0;
    }

    100% {
        top: 0;
        opacity: 1;
    }
}

@keyframes NotificationBottomSlideOut {
    0% {
        top: 0;
        opacity: 0;
    }

    100% {
        top: var(--notification-height-slide);
        opacity: 1;
    }
}
