.Icon {
    display: inline-block;
    vertical-align: -0.125em;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

.Icon svg {
    display: inline-block;
}

.Icon > * {
    line-height: 1;
}