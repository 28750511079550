.Modal-root {
    --zindex-modal: 1099;
    --zindex-modal-mask: 1099;
}

.Modal {
    --modal-color: var(--modal-color-theme, var(--primary-default));
    --modal-p: 0 0 24px 0;
    --modal-fz: var(--font-size-base);
    --modal-lh: var(--line-height-base);
    --modal-bg: var(--bright);
    --modal-title-fz: 22px;
    --modal-title-lh: 1.27272727;
    --modal-box-shadow: var(--shadow-color);
    --modal-content-p: var(--padding-base);
    --modal-content-m: var(--margin-base);
    --modal-body-p: var(--margin-base) 0 0;
    --modal-body-lh: 22px;
    --modal-body-color: var(--modal-body-color-theme, var(--secondary-default));
    --modal-footer-p: var(--margin-base) 0 0;
    --modal-btn-p: 0 var(--indent-base);
    --modal-animation-duration: 0.3s;
    --modal-title-font-family: var(--accent-font-family);

    position: relative;
    top: 0;
    display: inline-block;
    width: auto;
    margin: 0 auto;
    padding: var(--modal-p);
    vertical-align: middle;
    box-sizing: border-box;
    color: var(--modal-color);
    list-style: none;
    font-size: var(--modal-fz);
    line-height: var(--modal-lh);
    font-variant: var(--font-variant-base);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
    text-align: left;
}

.Modal-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--zindex-modal);
    display: inline-flex;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    justify-content: center;
    outline: 0;
}

.Modal-title {
    margin: 0;
    word-wrap: break-word;
    color: var(--modal-color);
    font-size: var(--modal-title-fz);
    line-height: var(--modal-title-lh);
    font-family: var(--modal-title-font-family);
}

.Modal-content {
    position: relative;
    background-color: var(--modal-bg);
    padding: var(--modal-content-p);
    border: 0;
    box-shadow: var(--modal-box-shadow);
    border-radius: 0;
    background-clip: inherit;
}

.Modal-header {
    color: var(--modal-color);
    background: var(--modal-bg);
    border-bottom: 0;
    border-radius: 0;
}

.Modal-body {
    word-wrap: break-word;
    padding: var(--modal-body-p);
    color: var(--modal-body-color);
    font-size: var(--modal-fz);
    line-height: var(--modal-body-lh);
}

.Modal-footer {
    padding: var(--modal-footer-p);
    background: var(--modal-bg);
    text-align: right;
    border-top: 0;
    border-radius: 0;
}

.Modal.zoom-center,
.Modal.zoom-appear {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    opacity: 0;
    -webkit-animation-duration: var(--modal-animation-duration);
    animation-duration: var(--modal-animation-duration);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Modal-mask {
    --modal-mask-bg: var(--modal-mask-bg-theme, var(--primary-default));
    --modal-mask-opacity: 0.5;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: var(--zindex-modal-mask);
    height: 100%;
    filter: alpha(opacity=50);
    background-color: var(--modal-mask-bg);
    opacity: var(--modal-mask-opacity);
}

.Modal-confirm .Modal-close,
.Modal-mask-hidden {
    display: none;
}

.Modal-open {
    overflow: hidden;
}

.Modal-centered {
    text-align: center;
}

.Modal-centered:before {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: '';
}

.Modal-centered .Modal {
    top: 0;
    display: inline-block;
    vertical-align: middle;
    text-align: left;
}

.Modal-confirm-body-wrapper:before,
.Modal-confirm-body-wrapper:after {
    display: table;
    content: '';
}

.Modal-confirm-body-wrapper:after {
    clear: both;
}

.Modal-confirm-body .Modal-confirm-title {
    display: block;
    overflow: hidden;
    color: var(--modal-color);
    font-size: var(--modal-title-fz);
    line-height: var(--modal-title-lh);
    font-family: var(--modal-title-font-family);
}

.Modal-confirm .Modal-body {
    padding: 0;
}

.Modal-confirm-body .Modal-confirm-content {
    padding: var(--modal-body-p);
    color: var(--modal-body-color);
    font-size: var(--modal-fz);
    line-height: var(--modal-body-lh);
}

.Modal-confirm .Modal-confirm-btns {
    float: none !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    padding: var(--modal-footer-p);
    margin-left: calc(var(--modal-content-m) / -4);
    margin-right: calc(var(--modal-content-m) / -4);
}

.Modal-footer > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    margin-left: calc(var(--modal-content-m) / -4);
    margin-right: calc(var(--modal-content-m) / -4);
}

.Modal-footer button,
.Modal-confirm .Modal-confirm-btns button {
    flex-grow: 1;
    min-width: calc(100% - var(--modal-content-m) / 2);
    padding: var(--modal-btn-p);
    margin-left: calc(var(--modal-content-m) / 4);
    margin-right: calc(var(--modal-content-m) / 4);
}

.Modal-footer button:not(:first-child),
.Modal-confirm .Modal-confirm-btns button:not(:first-child) {
    margin-top: var(--modal-content-m);
}

@media (max-width: 768px) {
    .Modal {
        --modal-max-w: calc(100vw - 16px);

        max-width: var(--modal-max-w);
        margin: 8px auto;
    }

    .Modal-centered .Modal {
        flex: 1;
    }
}
