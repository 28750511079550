.additiv-picker {
    --datepicker-input-p: 7px 12px;
    --datepicker-input-bg: transparent;
    --datepicker-input-border-color: var(--datepicker-border-color-theme, var(--secondary-light));
    --datepicker-input-border: var(--border-width-base) var(--border-style-base) var(--datepicker-input-border-color);
    --datepicker-input-fz: var(--input-font-size-base);
    --datepicker-input-lh: var(--input-line-height-base);
    --datepicker-input-color: var(--datepicker-input-color-theme, var(--primary-default));
    --datepicker-input-error-color: var(--datepicker-input-error-color-theme, var(--negative-default));
    --datepicker-input-label-inside-h: 30px;
    --datepicker-input-label-inside-p: var(--indent-base) 0 0 0;
    --datepicker-input-placeholder-color: var(--datepicker-input-placeholder-color-theme, var(--primary-default));
    --datepicker-input-height-base: var(--text-input-height-base-theme, var(--input-height-base));

    height: var(--datepicker-input-height-base);
    width: 100%;
    padding: var(--datepicker-input-p);
    border: var(--datepicker-input-border);
    border-radius: var(--border-radius-base);
    background-color: var(--datepicker-input-bg);
}

.additiv-picker-input > input {
    color: var(--datepicker-input-color);
    font-size: var(--datepicker-input-fz);
    line-height: var(--datepicker-input-lh);
}

.additiv-picker-input > input::placeholder {
    color: var(--datepicker-input-placeholder-color);
}

.additiv-picker,
.additiv-picker:hover {
    border-color: var(--datepicker-input-border-color);
}

.additiv-picker-focused {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
    box-shadow: none;
}

.additiv-picker-suffix .Icon {
    color: var(--datepicker-input-color);
}

.additiv-picker.additiv-picker-disabled {
    background-color: var(--datepicker-input-bg);
}

.additiv-input-disabled input {
    color: var(--datepicker-input-color);
}

.additiv-picker-disabled .additiv-picker-suffix {
    opacity: 0.5;
}

.additiv-picker .additiv-input-disabled + .additiv-picker-suffix,
.additiv-picker .additiv-input-disabled + .additiv-picker-suffix:hover {
    cursor: not-allowed;
}

.additiv-picker.ant-input_invalid {
    border-color: var(--datepicker-input-error-color);
}

.additiv-picker.ant-input_invalid .rc-tooltiped-text {
    color: var(--datepicker-input-error-color);
}

.datepicker {
    --datepicker-w: 322px;
    --datepicker-fz: 16px;
    --datepicker-lh: 19px;
    --datepicker-bg: transparent;
    --datepicker-panel-p: 0 var(--margin-base) var(--margin-base);
    --datepicker-panel-bg: var(--datepicker-panel-bg-theme, var(--background-content));
    --datepicker-panel-h: 38px;
    --datepicker-panel-lh: 22px;
    --datepicker-header-h: 50px;
    --datepicker-header-fz: 20px;
    --datepicker-body-p: calc(var(--margin-base) / 2) 0 var(--margin-base);
    --datepicker-week-body-p: calc(var(--margin-base) / 2) 0 0;
    --datepicker-other-body-p: calc(var(--margin-base) / 2) 0 0;
    --datepicker-other-cell-p: 7px 5px;
    --datepicker-th-p: 5px 0 calc(var(--margin-base) / 2);
    --datepicker-cell-p: 5px 0;
    --datepicker-cell-h: 28px;
    --datepicker-cell-w: 40px;
    --datepicker-week-cell-w: 35px;
    --datepicker-cell-bg: var(--datepicker-cell-bg-theme, var(--background-content));
    --datepicker-cell-hover-color: var(--datepicker-cell-hover-color-theme, var(--text-color));
    --datepicker-week-cell-color: var(--datepicker-week-cell-color-theme, var(--white));
    --datepicker-border-color: var(--datepicker-border-color-theme, var(--border-color));
    --datepicker-border-radius: 5px;
    --datepicker-date-color: var(--datepicker-date-color-theme, var(--secondary-default));
    --datepicker-header-color: var(--datepicker-header-color-theme, var(--primary-default));
    --datepicker-last-next-month-color: var(--datepicker-last-next-month-color-theme, var(--secondary-lighter));
    --datepicker-selected-date-bg-color: var(--datepicker-selected-date-bg-color-theme, var(--accent-default));
    --datepicker-weekend-color: var(--datepicker-weekend-color-theme, var(--secondary-light));
    --datepicker-divider-bg: var(--datepicker-border-color-theme, var(--border-color));
    --datepicker-footer-p: var(--indent-base) 0 0;
    --datepicker-footer-select-min-w: 80px;
    --datepicker-footer-btn-h: 30px;
    --datepicker-footer-btn-m: calc(var(--margin-base) / 2);
    --datepicker-footer-btn-extra-lh: 20px;
    --datepicker-footer-btn-extra-p: var(--indent-base) 0 0;
    --datepicker-weekend-bg-color: var(--datepicker-weekend-bg-color-theme, var(--secondary-lighter));
}

.datepicker .additiv-picker-panel {
    margin-top: 0;
    padding: var(--datepicker-panel-p);
    background-color: var(--datepicker-panel-bg);
    border: var(--border-width-base) var(--border-style-base) var(--datepicker-border-color);
    border-radius: var(--datepicker-border-radius);
    font-size: var(--datepicker-fz);
    line-height: var(--datepicker-lh);
}

.additiv-picker-date-panel .additiv-picker-content {
    width: 100%;
}

.additiv-picker-panel-container {
    overflow: visible;
    width: var(--datepicker-w);
    background-color: var(--datepicker-bg);
    border: 0;
    box-shadow: none;
}

.additiv-picker-header {
    padding: 0;
    height: var(--datepicker-header-h);
    border-bottom-color: var(--datepicker-divider-bg);
}

.additiv-picker-header > a,
.additiv-picker-header div > a {
    padding: 0;
    font-size: var(--datepicker-header-fz);
    line-height: var(--datepicker-header-h);
}

.additiv-picker-header button,
.additiv-picker-header button:hover {
    color: var(--datepicker-header-color);
}

.additiv-picker-header-view,
.additiv-picker-header-view button,
.additiv-picker-header-view button:hover {
    color: var(--datepicker-header-color);
    font-size: var(--datepicker-fz);
    font-weight: bold;
    line-height: var(--datepicker-header-h);
}

.additiv-picker-date-panel .additiv-picker-body {
    padding: var(--datepicker-body-p);
}

.additiv-picker-week-panel .additiv-picker-body {
    padding: var(--datepicker-week-body-p);
}

.additiv-picker-content th {
    padding: var(--datepicker-th-p);
    color: var(--datepicker-header-color);
}

.additiv-picker-cell {
    padding: var(--datepicker-cell-p);
}

.additiv-picker-cell .additiv-picker-cell-inner,
.additiv-picker-cell-selected .additiv-picker-cell-inner {
    width: var(--datepicker-cell-w);
    height: var(--datepicker-cell-h);
    border-radius: 0;
    color: var(--datepicker-last-next-month-color);
    background-color: var(--datepicker-cell-bg);
    line-height: var(--datepicker-cell-h);
    font-weight: normal;
}

.additiv-picker-cell.additiv-picker-cell-in-view .additiv-picker-cell-inner {
    color: var(--datepicker-date-color);
}

.additiv-picker-week-panel .additiv-picker-cell .additiv-picker-cell-inner {
    width: var(--datepicker-week-cell-w);
}

.additiv-picker-week-panel .additiv-picker-week-panel-row-selected .additiv-picker-cell-inner {
    color: var(--datepicker-week-cell-color);
}

.additiv-picker-cell.additiv-picker-cell-week {
    color: var(--datepicker-week-color);
}

.additiv-picker-cell:hover:not(.additiv-picker-cell-in-view) .additiv-picker-cell-inner,
.additiv-picker-cell:hover:not(:first-child):not(:last-child):not(.additiv-picker-cell-selected):not(.additiv-picker-cell-range-start):not(.additiv-picker-cell-range-end):not(.additiv-picker-cell-range-hover-start):not(.additiv-picker-cell-range-hover-end) .additiv-picker-cell-inner {
    background-color: var(--datepicker-bg);
    color: var(--datepicker-cell-hover-color);
}

.additiv-picker-date-panel .additiv-picker-cell.additiv-picker-cell-weekend .additiv-picker-cell-inner,
.additiv-picker-date-panel .additiv-picker-cell.additiv-picker-cell-weekend:hover .additiv-picker-cell-inner {
    background-color: var(--datepicker-weekend-bg-color);
    color: var(--datepicker-weekend-color);
}

.additiv-picker-week-panel-row:not(.additiv-picker-week-panel-row-selected) .additiv-picker-cell.additiv-picker-cell-weekend .additiv-picker-cell-inner,
.additiv-picker-week-panel-row:not(.additiv-picker-week-panel-row-selected) .additiv-picker-cell.additiv-picker-cell-weekend:hover .additiv-picker-cell-inner {
    background-color: var(--datepicker-weekend-bg-color) !important;
    color: var(--datepicker-weekend-color);
}

.additiv-picker-week-panel-row:hover td {
    background-color: var(--datepicker-weekend-bg-color) !important;
}

.additiv-picker-cell:before {
    background-color: transparent;
}

.additiv-picker-cell.additiv-picker-cell-disabled .additiv-picker-cell-inner {
    background-color: var(--datepicker-cell-bg);
}

.additiv-picker-cell.additiv-picker-cell-disabled .additiv-picker-cell-inner:after {
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--datepicker-fz);
    height: 1px;
    margin-top: -1px;
    background-color: var(--datepicker-last-next-month-color);
    transform: translateX(-50%);
    content: '';
}

.additiv-picker-cell-in-view.additiv-picker-cell-disabled .additiv-picker-cell-inner:after {
    background-color: currentColor;
}

.additiv-picker-year-panel .additiv-picker-cell-disabled .additiv-picker-cell-inner:after {
    width: calc(100% - 50px);
}

.additiv-picker-month-panel .additiv-picker-cell-disabled .additiv-picker-cell-inner:after {
    width: calc(100% - 60px);
}

.additiv-picker-cell.additiv-picker-cell-today .additiv-picker-cell-inner:before {
    border-color: var(--datepicker-selected-date-bg-color);
    border-radius: 0;
}

.additiv-picker-cell.additiv-picker-cell-today .additiv-picker-cell-inner {
    color: var(--datepicker-selected-date-bg-color);
}

.additiv-picker-cell.additiv-picker-cell-selected .additiv-picker-cell-inner,
.additiv-picker-week-panel-row-selected td,
.additiv-picker-week-panel-row-selected:hover td {
    background-color: var(--datepicker-selected-date-bg-color) !important;
    color: var(--datepicker-week-cell-color) !important;
}

.additiv-picker-cell,
.additiv-picker-month-panel-month,
.additiv-picker-year-panel-year,
.additiv-picker-decade-panel-decade {
    height: var(--datepicker-panel-h);
    font-size: var(--datepicker-fz);
    line-height: var(--datepicker-panel-lh);
}

/* Footer */
.additiv-picker-footer {
    padding: var(--datepicker-footer-p);
    border-top: var(--border-width-base) var(--border-style-base) var(--datepicker-divider-bg);
    font-size: var(--datepicker-fz);
    line-height: var(--datepicker-lh);
}

.additiv-picker-footer a,
.additiv-picker-footer a:hover {
    color: var(--datepicker-date-color);
}

.additiv-picker-footer hr {
    border: 0;
    border-top: var(--border-width-base) var(--border-style-base) var(--datepicker-divider-bg);
}

.additiv-picker-footer .additiv-picker-manual-controls {
    height: var(--datepicker-footer-btn-h);
    line-height: var(--datepicker-footer-btn-h);
}

.additiv-picker-footer .additiv-picker-manual-controls > div {
    display: inline-block;
}

.additiv-picker-footer .additiv-picker-button {
    margin-top: var(--datepicker-footer-btn-m);
    width: calc(50% - var(--datepicker-footer-btn-m));
}

.additiv-picker-footer .additiv-picker-button:first-child {
    margin-right: calc(var(--datepicker-footer-btn-m) * 2);
}

.additiv-picker-footer .additiv-picker-reset,
.additiv-picker-footer .additiv-picker-reset:hover {
    float: right;
    display: inline-block;
    padding: 0;
    height: var(--datepicker-footer-btn-h);
    color: var(--datepicker-weekend-bg-color);
    border: 0;
    background-color: transparent;
    text-align: right;
    line-height: var(--datepicker-footer-btn-h);
}

.additiv-picker-footer-extra {
    padding: 0;
    line-height: var(--datepicker-footer-btn-extra-lh);
}

.additiv-picker-footer-extra + a {
    display: block;
    padding: var(--datepicker-footer-btn-extra-p);
}

.additiv-picker-footer .select {
    min-width: var(--datepicker-footer-select-min-w);
}

/* Month, decade, quarter, year picker */
.additiv-picker-quarter-panel .additiv-picker-body,
.additiv-picker-month-panel .additiv-picker-body,
.additiv-picker-year-panel .additiv-picker-body,
.additiv-picker-decade-panel .additiv-picker-body {
    padding: var(--datepicker-week-body-p);
}

.additiv-picker-quarter-panel .additiv-picker-cell-inner,
.additiv-picker-month-panel .additiv-picker-cell-inner,
.additiv-picker-year-panel .additiv-picker-cell-inner,
.additiv-picker-decade-panel .additiv-picker-cell-inner {
    padding: var(--datepicker-other-cell-p);
    width: 100% !important;
    height: var(--datepicker-panel-h) !important;
}

.additiv-picker-quarter-panel .additiv-picker-cell:hover:not(.additiv-picker-cell-selected) .additiv-picker-cell-inner,
.additiv-picker-month-panel .additiv-picker-cell:hover:not(.additiv-picker-cell-selected) .additiv-picker-cell-inner,
.additiv-picker-year-panel .additiv-picker-cell:hover:not(.additiv-picker-cell-selected) .additiv-picker-cell-inner,
.additiv-picker-decade-panel .additiv-picker-cell:hover:not(.additiv-picker-cell-selected) .additiv-picker-cell-inner {
    background: transparent !important;
}

.additiv-picker-quarter-panel tr:last-child .additiv-picker-cell,
.additiv-picker-month-panel tr:last-child .additiv-picker-cell,
.additiv-picker-year-panel tr:last-child .additiv-picker-cell,
.additiv-picker-decade-panel tr:last-child .additiv-picker-cell {
    padding-bottom: 0;
}

.form-group.datepicker-form-group.with-label-inside {
    position: relative;
}

.form-group.datepicker-form-group.with-label-inside .additiv-picker-input > input {
    height: var(--datepicker-input-label-inside-h);
    padding: var(--datepicker-input-label-inside-p);
}

.form-group.datepicker-form-group.with-label-inside .label-inside {
    position: absolute;
    left: 14px;
    top: 0;
}

.form-group.with-label.datepicker-form-group.with-label-inside .label-inside {
    top: var(--datepicker-input-label-inside-h);
}
