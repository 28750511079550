.Upload {
    --upload-min-w: 152px;
}

.Upload input[type='file'] {
    display: none;
}

.Upload .button {
    min-width: var(--upload-min-w);
}
