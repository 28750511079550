.Tags {
    --tags-border-radius: 5px;
    --tags-indent: 5px 9px 5px 0;
    --tags-indent-inner: 4px 11px;
    --tags-indent-button: 4px;
    --tags-fz: 16px;
    --tags-lh: 1.1875;
    --tags-mt: 7px;
    --tags-item-border: var(--border-width-base) var(--border-style-base) var(--tags-item-border-color-theme, var(--secondary-default));
    --tags-item-color: var(--tags-item-color-theme, var(--secondary-default));
    --tags-item-lh: 1.14285714;
    --tags-item-fz: 14px;
    --tags-icon-fz: 10px;
    --tags-icon-ml: 6px;
    --tags-icon-mr: -3px;
    --tags-icon-p: 3px;

    display: flex;
    align-items: flex-start;
    font-weight: bold;
    font-size: var(--tags-fz);
    line-height: var(--tags-lh);
    justify-content: space-between;
    margin-top: var(--tags-mt);
}

.tags-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}

.Tags b {
    margin: var(--tags-indent);
}

.Tags .tag-item {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--tags-indent);
    padding: var(--tags-indent-inner);
    font-weight: normal;
    border: var(--tags-item-border);
    color: var(--tags-item-color);
    font-size: var(--tags-item-fz);
    line-height: var(--tags-item-lh);
    border-radius: var(--tags-border-radius);
}

.Tags button {
    display: inline-block;
    margin: var(--tags-indent);
    margin-left: 0;
    padding: var(--tags-indent-button);
    background: transparent;
    color: var(--tags-item-color);
    border: 0;
    line-height: var(--tags-item-lh);
    white-space: nowrap;
    margin-right: 0;
    padding-right: 0;
}

.Tags button:hover {
    cursor: pointer;
    text-decoration: underline;
}

.Tags .Icon {
    font-size: var(--tags-icon-fz);
    margin-left: var(--tags-icon-ml);
    margin-right: var(--tags-icon-mr);
    padding: var(--tags-icon-p);
}

@media only screen and (max-width: 992px) {
    .Tags ul {
        flex-direction: column;
    }
}

.Tags-mobile {
    display: block;
}
