.FilesList {
    --file-list-p: calc(var(--margin-base) / 2) var(--indent-base);
    --file-list-mb: calc(var(--margin-base) + 5px);
    --file-list-border: var(--border-width-base) var(--border-style-base) var(--file-list-border-color-theme, var(--secondary-light));
}

.FilesList .upload-list {
    padding: var(--file-list-p);
    width: 100%;
    border: var(--file-list-border);
    border-radius: var(--border-radius-base);
    background-color: transparent;
    margin-bottom: var(--file-list-mb);
}
