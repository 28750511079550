.Textarea {
    --textarea-minheight: 120px;
    --textarea-maxheight: 300px;
    --textarea-vertical-padding: 10px;
    --textarea-horizontal-padding: 12px;
    --textarea-border: var(--border-width-base) var(--border-style-base) var(--text-input-border-color-theme, var(--input-border-color-base));
    --textarea-fz: var(--input-font-size-base);
    --textarea-lh: var(--input-line-height-base);
    --textarea-hover-border-color: var(--textarea-hover-border-color-theme, var(--secondary-default));
    --textarea-disabled-hover-border-color: var(--textarea-disabled-hover-border-color-theme, var(--secondary-light));
    --textarea-invalid-hover-border-color: var(--textarea-invalid-hover-border-color-theme, var(--negative-dark));
    --textarea-invalid-border-color: var(--textarea-invalid-border-color-theme, var(--negative-default));

    background-color: transparent;
    border: var(--textarea-border);
    border-radius: var(--border-radius-base);
    color: inherit;
    display: block;
    font-size: var(--textarea-fz);
    font-family: inherit;
    line-height: var(--textarea-lh);
    margin: 0;
    max-height: var(--textarea-maxheight);
    min-height: var(--textarea-minheight);
    max-width: 100%;
    min-width: 100%;
    padding: var(--textarea-vertical-padding) var(--textarea-horizontal-padding);
    width: 100%;
}

.Textarea:focus-visible {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.form-group_disabled .Textarea {
    opacity: 0.5;
    pointer-events: auto;
}

.form-group_invalid .Textarea {
    border-color: var(--textarea-invalid-border-color);
}
