.Menu .menu-item {
    --dropdown-menu-z-index: 9;
    --dropdown-menu-pv: calc(var(--margin-base) / 4);
    --dropdown-menu-ph: var(--margin-base);
    --dropdown-menu-fz: 16px;
    --dropdown-menu-lh: 1.875;
    --dropdown-menu-color: var(--dropdown-menu-text-color-theme, var(--primary-default));
    --dropdown-menu-item-border: var(--border-base);

    position: relative;
    z-index: var(--dropdown-menu-z-index);
    overflow: visible;
    padding: var(--dropdown-menu-pv) var(--dropdown-menu-ph);
    margin: calc(-1 * var(--border-width-base));
    border: var(--border-width-base) var(--border-style-base) transparent;
    color: var(--dropdown-menu-color);
    cursor: pointer;
    font-size: var(--dropdown-menu-fz);
    line-height: var(--dropdown-menu-lh);
}

.Menu .menu-item > a {
    display: block;
}

.Menu .menu-item:not(:last-child):hover:after {
    border-color: transparent;
}

.Menu .menu-item:not(:last-child):after {
    position: absolute;
    top: auto;
    left: var(--dropdown-menu-ph);
    bottom: -1px;
    display: inline-block;
    width: calc(100% - 2 * var(--dropdown-menu-ph));
    border-right: none;
    border-bottom: var(--dropdown-menu-item-border);
    opacity: 1;
    -webkit-transform: none;
    content: '';
    transform: none;
}

.Menu .menu-item:first-child {
    border-top-left-radius: var(--dropdown-inner-border-radius);
    border-top-right-radius: var(--dropdown-inner-border-radius);
}

.Menu .menu-item:last-child {
    border-bottom-left-radius: var(--dropdown-inner-border-radius);
    border-bottom-right-radius: var(--dropdown-inner-border-radius);
}
