.button {
    --btn-p: calc(var(--padding-base) / 3) var(--padding-base);
    --btn-h: 45px;
    --btn-min-w: 150px;
    --btn-fz: 16px;
    --btn-lh: calc(var(--btn-fz) + 10px);
    --btn-fw: var(--accent-font-weight);
    --btn-color: var(--btn-color-theme, var(--primary-default));
    --btn-border: var(--border-base);
    --btn-small-p: 3px 16px;
    --btn-small-h: 30px;
    --btn-small-min-w: calc(var(--btn-min-w) - 50px);
    --btn-small-fz: var(--font-size-base);
    --btn-small-lh: calc(var(--btn-small-fz) + 10px);
    --btn-border-radius: var(--btn-border-radius-theme, var(--border-radius-base));

    --btn-primary-bg: var(--btn-primary-bg-theme, var(--accent-default));
    --btn-primary-color: var(--btn-primary-color-theme, var(--white));
    --btn-primary-hover-bg: var(--btn-primary-hover-bg-theme, var(--accent-dark));
    --btn-primary-hover-border-color: var(--btn-primary-hover-border-color-theme, var(--accent-dark));
    --btn-primary-active-bg: var(--btn-primary-active-bg-theme, var(--accent-darker));
    --btn-primary-active-border-color: var(--btn-primary-active-border-color-theme, var(--accent-darker));
    --btn-primary-disabled-bg: var(--btn-primary-disabled-bg-theme, var(--secondary-lighter));
    --btn-primary-disabled-color: var(--btn-primary-disabled-color-theme, var(--secondary-default));
    --btn-primary-disabled-border-color: var(--btn-primary-disabled-border-color-theme, var(--secondary-light));

    --btn-secondary-color: var(--btn-secondary-color-theme, var(--primary-default));
    --btn-secondary-border-color: var(--btn-secondary-border-color-theme, var(--secondary-light));
    --btn-secondary-hover-bg: var(--btn-secondary-hover-bg-theme, var(--white));
    --btn-secondary-hover-color: var(--btn-secondary-hover-color-theme, var(--primary-default));
    --btn-secondary-hover-border-color: var(--btn-secondary-hover-border-color-theme, var(--secondary-default));
    --btn-secondary-focus-bg: var(--btn-secondary-focus-bg-theme, var(--bright));
    --btn-secondary-focus-border-color: var(--btn-secondary-focus-border-color-theme, var(--secondary-light));
    --btn-secondary-active-bg: var(--btn-secondary-active-bg-theme, var(--secondary-lighter));
    --btn-secondary-active-color: var(--btn-secondary-active-color-theme, var(--primary-default));
    --btn-secondary-active-border-color: var(--btn-secondary-active-border-color-theme, var(--secondary-default));
    --btn-secondary-disabled-bg: var(--btn-secondary-disabled-bg-theme, var(--secondary-lighter));
    --btn-secondary-disabled-color: var(--btn-secondary-disabled-color-theme, var(--primary-default));
    --btn-secondary-disabled-border-color: var(--btn-secondary-disabled-border-color-theme, var(--secondary-light));

    --btn-danger-bg: var(--btn-danger-bg-theme, var(--negative-default));
    --btn-danger-color: var(--btn-danger-color-theme, var(--white));
    --btn-danger-hover-bg: var(--btn-danger-hover-bg-theme, var(--negative-dark));
    --btn-danger-hover-border-color: var(--btn-danger-hover-border-color-theme, var(--negative-dark));
    --btn-danger-active-bg: var(--btn-danger-active-bg-theme, var(--negative-darker));
    --btn-danger-active-border-color: var(--btn-danger-active-border-color-theme, var(--negative-darker));

    --btn-link-color: var(--btn-link-color-theme, var(--secondary-default));
    --btn-link-hover-color: var(--btn-link-hover-color-theme, var(--secondary-dark));
    --btn-link-active-color: var(--btn-link-active-color-theme, var(--secondary-darker));
    --btn-link-disabled-color: var(--btn-link-disabled-color-theme, var(--secondary-light));

    --btn-rounded-w: 180px;
    --btn-rounded-border-radius: 22.5px;

    background-image: none;
    background-color: transparent;
    border: var(--btn-border);
    border-radius: var(--btn-border-radius);
    box-shadow: none;
    color: var(--btn-color);
    cursor: pointer;
    display: inline-block;
    font-size: var(--btn-fz);
    font-weight: var(--btn-fw);
    min-height: var(--btn-h);
    line-height: var(--btn-lh);
    min-width: var(--btn-min-w);
    outline: 0;
    padding: var(--btn-p);
    position: relative;
    text-align: center;
    transition: var(--transition);
    touch-action: manipulation;
    user-select: none;
}

.button-small {
    min-height: var(--btn-small-h);
    min-width: var(--btn-small-min-w);
    padding: var(--btn-small-p);
    font-size: var(--btn-small-fz);
    line-height: var(--btn-small-lh);
}

.button-small .Loader-wrapper .Loader {
    width: var(--btn-fz);
    height: var(--btn-fz);
}

/* Button primary */
.button-primary {
    background-color: var(--btn-primary-bg);
    border-color: var(--btn-primary-bg);
    color: var(--btn-primary-color);
}

.button-primary.button:hover,
.button-primary.button.button-pressed:hover {
    background-color: var(--btn-primary-hover-bg);
    border-color: var(--btn-primary-hover-border-color);
    color: var(--btn-primary-color);
}

.button-primary:focus {
    background-color: var(--btn-primary-bg);
    border-color: var(--btn-primary-bg);
}

.button-primary.button-pressed,
.button-primary.button-pressed:active,
.button-primary:active,
.button-primary.active {
    background-color: var(--btn-primary-active-bg);
    border-color: var(--btn-primary-active-border-color);
    color: var(--btn-primary-color);
}

.button-primary.button-disabled,
.button-primary.button-disabled:hover,
.button-primary.button-disabled:focus,
.button-primary.button-disabled:active,
.button-primary.button-disabled.active,
.button-primary.button[disabled],
.button-primary.button[disabled]:hover,
.button-primary.button[disabled]:focus,
.button-primary.button[disabled]:active,
.button-primary.button[disabled].active,
.button-primary.button.disabled,
.button-primary.button.disabled:hover,
.button-primary.button.disabled:focus,
.button-primary.button.disabled:active,
.button-primary.button.disabled.active {
    background-color: var(--btn-primary-disabled-bg);
    border-color: var(--btn-primary-disabled-border-color);
    color: var(--btn-primary-disabled-color);
}

/* Button secondary */
.button-secondary {
    background-color: transparent;
    border-color: var(--btn-secondary-border-color);
    color: var(--btn-secondary-color);
}

.button-secondary.button:hover {
    background-color: var(--btn-secondary-hover-bg);
    border-color: var(--btn-secondary-hover-border-color);
    color: var(--btn-secondary-hover-color);
}

.button-secondary.button:focus {
    border-color: var(--btn-secondary-focus-border-color);
    color: var(--btn-secondary-color);
    background-color: var(--btn-secondary-focus-bg);
}

.button-secondary.button.button-pressed:hover {
    border-color: var(--btn-secondary-hover-border-color);
    background-color: var(--btn-secondary-focus-bg);
}

.button-secondary.button.button-pressed,
.button-secondary.button.button-pressed:active,
.button-secondary.button:active,
.button-secondary.button.active {
    background-color: var(--btn-secondary-active-bg);
    border-color: var(--btn-secondary-active-border-color);
    color: var(--btn-secondary-active-color);
}

.button-secondary.button-disabled,
.button-secondary.button-disabled:hover,
.button-secondary.button-disabled:focus,
.button-secondary.button-disabled:active,
.button-secondary.button-disabled.active,
.button-secondary.button[disabled],
.button-secondary.button[disabled]:hover,
.button-secondary.button[disabled]:focus,
.button-secondary.button[disabled]:active,
.button-secondary.button[disabled].active,
.button-secondary.button.disabled,
.button-secondary.button.disabled:hover,
.button-secondary.button.disabled:focus,
.button-secondary.button.disabled:active,
.button-secondary.button.disabled.active {
    border-color: var(--btn-secondary-disabled-border-color);
    color: var(--btn-secondary-disabled-color);
    opacity: 0.5;
    background-color: var(--btn-secondary-disabled-bg);
}

/* Button danger */
.button-danger {
    background-color: var(--btn-danger-bg);
    border-color: var(--btn-danger-bg);
    color: var(--btn-danger-color);
}

.button-danger.button:hover,
.button-danger.button.button-pressed:hover {
    background-color: var(--btn-danger-hover-bg);
    border-color: var(--btn-danger-hover-border-color);
}

.button-danger.button:focus {
    background-color: var(--btn-danger-bg);
    border-color: var(--btn-danger-bg);
    color: var(--btn-danger-color);
}

.button-danger.button.button-pressed,
.button-danger.button.button-pressed:active,
.button-danger.button:active,
.button-danger.button.active {
    background-color: var(--btn-danger-active-bg);
    border-color: var(--btn-danger-active-border-color);
    color: var(--btn-danger-color);
}

.button-danger.button-disabled,
.button-danger.button-disabled:hover,
.button-danger.button-disabled:focus,
.button-danger.button-disabled:active,
.button-danger.button-disabled.active,
.button-danger.button[disabled],
.button-danger.button[disabled]:hover,
.button-danger.button[disabled]:focus,
.button-danger.button[disabled]:active,
.button-danger.button[disabled].active,
.button-danger.button.disabled,
.button-danger.button.disabled:hover,
.button-danger.button.disabled:focus,
.button-danger.button.disabled:active,
.button-danger.button.disabled.active {
    background-color: var(--btn-danger-bg);
    border-color: var(--btn-danger-bg);
    color: var(--btn-danger-color);
    opacity: 0.5;
}

.button:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.button.button-disabled,
.button.button-disabled:hover,
.button.button-disabled:focus,
.button.button-disabled:active,
.button.button-disabled.active,
.button[disabled],
.button[disabled]:hover,
.button[disabled]:focus,
.button[disabled]:active,
.button[disabled].active,
.button.disabled,
.button.disabled:hover,
.button.disabled:focus,
.button.disabled:active,
.button.disabled.active {
    cursor: not-allowed;
}

/* Button link */
.button-link {
    padding: 0;
    height: 100%;
    min-width: inherit;
    background: none;
    border: none;
    text-decoration: none;
    color: var(--btn-link-color);
    cursor: pointer;
}

.button-link.button:hover,
.button-link.button.button-pressed:hover {
    color: var(--btn-link-hover-color);
}

.button-link:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.button-link.button-pressed,
.button-link.button-pressed:active,
.button-link:active,
.button-link.active {
    color: var(--btn-link-active-color);
}

.button-link.button-disabled,
.button-link.button-disabled:hover,
.button-link.button-disabled:focus,
.button-link.button-disabled:active,
.button-link.button-disabled.active,
.button-link.button[disabled],
.button-link.button[disabled]:hover,
.button-link.button[disabled]:focus,
.button-link.button[disabled]:active,
.button-link.button[disabled].active,
.button-link.button.disabled,
.button-link.button.disabled:hover,
.button-link.button.disabled:focus,
.button-link.button.disabled:active,
.button-link.button.disabled.active {
    color: var(--btn-link-disabled-color);
    pointer-events: none;
}

/* Button rounded */
.button-rounded {
    border-radius: var(--btn-rounded-border-radius);
}
