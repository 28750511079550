.Footer {
    --footer-p: var(--padding-base) 0 0;
    --footer-color: var(--footer-color-theme, var(--bright));
    --footer-fz: 16px;
    --footer-lh: 2;
    --footer-nav-mb: var(--padding-base);
    --footer-nav-a-mt: calc(var(--margin-base) / 2);
    --footer-bottom-p: var(--padding-base) 0;
    --footer-bottom-fz: 14px;
    --footer-bottom-lh: 1.57142857;
    --footer-bottom-color: var(--footer-bottom-color-theme, var(--secondary-default));
    --footer-bottom-a-ml: var(--margin-base);
    --footer-bottom-border: var(--border-width-base) var(--border-style-base) var(--secondary-default);
    --footer-logo-max-w: 100px;
    --footer-icon-fz: 16px;
    --footer-container-p: var(--padding-base) 70px;
    --footer-border-top: var(--footer-border-top-theme, var(--border-base));
    --background-footer: var(--background-footer-theme, var(--background-base));

    background-color: var(--background-footer);
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    border-top: var(--footer-border-top);
}

.Footer__nav {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: var(--footer-p);
    color: var(--footer-color);
    font-weight: bold;
    letter-spacing: 0.25px;
    font-size: var(--footer-fz);
    line-height: var(--footer-lh);
}

@media only screen and (max-width: 992px) {
    .Footer__nav {
        flex-wrap: wrap;
    }
}

.Footer__nav nav {
    min-width: calc(100% / 6);
    margin-bottom: var(--footer-nav-mb);
}

@media only screen and (max-width: 992px) {
    .Footer__nav nav {
        min-width: calc(100% / 3);
    }
}

.Footer__nav a {
    display: block;
    font-weight: normal;
    color: var(--footer-color);
}

.Footer__nav a:first-child {
    margin-top: var(--footer-nav-a-mt);
}

.Footer__nav a:hover {
    text-decoration: underline;
}

.Footer__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--footer-bottom-p);
    color: var(--footer-bottom-color);
    letter-spacing: 0.22px;
    font-size: var(--footer-bottom-fz);
    line-height: var(--footer-bottom-lh);
}

.Footer__nav + .Footer__bottom {
    border-top: var(--footer-bottom-border);
}

.Footer__bottom a {
    color: var(--footer-bottom-color);
    text-decoration: underline;
}

.Footer__bottom a:not(:first-child) {
    margin-left: var(--footer-bottom-a-ml);
}

.Footer__bottom a:hover {
    text-decoration: none;
}

.Footer__bottom img {
    max-width: var(--footer-logo-max-w);
}

.Footer__bottom .Icon {
    font-size: var(--footer-icon-fz);
}

.Footer_mini div.Footer__bottom.container {
    padding: var(--footer-container-p);
}
