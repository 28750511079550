.NotificationItem {
    --notification-item-color: var(--notification-item-color-theme, var(--primary-default));
    --notification-item-bc: var(--notification-item-bc-theme, var(--border-color));

    display: grid;
    grid-template-columns: 3fr 1fr 40px;
    padding: 12px 0;
}

.NotificationItem:not(:last-child) {
    border-bottom: 1px solid var(--notification-item-bc);
}

.NotificationItem .notification__title {
    font-weight: 800;
    font-size: 16px;
    color: var(--notification-item-color);
}

.NotificationItem .notification__date {
    justify-self: end;
}

.NotificationItem .notification__description {
    grid-column-start: 1;
    grid-column-end: 2 span;
    color: var(--notification-item-color);
}

.notification__read {
    grid-column: 3;
    grid-row-start: 1;
    grid-row-end: span 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 2px;
    cursor: pointer;
}

.NotificationItem.NotificationItem__without-date {
    grid-template-columns: 3fr 40px;
}

.NotificationItem.NotificationItem__without-date .notification__title {
    grid-column-end: 2 span;
}

.NotificationItem.NotificationItem__without-date .notification__read {
    margin-left: 15px;
}
