.AutoComplete {
    --autocomplete-h: 50px;
    --autocomplete-fz: 16px;
    --autocomplete-lh: var(--line-height-base);
    --autocomplete-bg: var(--bright);
    --autocomplete-border-width-base: var(--border-width-base);
    --autocomplete-border-color: var(--autocomplete-border-color-theme, var(--border-color));
    --autocomplete-border: var(--autocomplete-border-width-base) var(--border-style-base) var(--autocomplete-border-color);
    --autocomplete-input-p: var(--indent-base) 20px var(--indent-base) 15px;
    --autocomplete-icon-size: 20px;
    --autocomplete-icon-color: var(--autocomplete-icon-color-theme, var(--secondary-default));
    --autocomplete-dropdown-h: 250px;
    --autocomplete-dropdown-p: calc(var(--padding-base) / 3) calc(var(--padding-base) / 2);
    --autocomplete-dropdown-lh: 1.375;
    --autocomplete-dropdown-active-color: var(--autocomplete-dropdown-active-color-theme, var(--accent-default));
    --autocomplete-dropdown-border-radius: 0 0 var(--border-radius-base) var(--border-radius-base);
    --autocomplete-placeholder-color: var(--autocomplete-placeholder-color-theme, var(--secondary-default));

    position: relative;
    width: 100%;
    font-size: var(--autocomplete-fz);
    box-sizing: content-box;
    border: var(--autocomplete-border);
    line-height: var(--autocomplete-h);
    background-color: var(--autocomplete-bg);
    border-radius: var(--border-radius-base);
}

.AutoComplete:focus-within {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.AutoComplete:focus-within .TextInput:focus-visible {
    outline: none;
}

.AutoComplete.AutoComplete__opened {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}

.AutoComplete .form-group input.TextInput {
    padding: var(--autocomplete-input-p);
    height: var(--autocomplete-h);
    font-size: var(--autocomplete-fz);
    line-height: var(--autocomplete-lh);
    border-radius: 0;
}

.AutoComplete .form-group input.TextInput,
.AutoComplete .form-group .TextInput-group {
    border: none;
}

.AutoComplete .autocomplete-search-icon {
    position: relative;
    left: 10px;
    color: var(--autocomplete-icon-color);
    font-size: var(--autocomplete-icon-size);
}

.AutoComplete .AutoComplete-dropdown {
    position: absolute;
    z-index: 99;
    top: calc(100% + var(--autocomplete-border-width-base));
    left: calc(-1 * var(--autocomplete-border-width-base));
    right: 0;
    overflow: auto;
    width: 100%;
    max-height: var(--autocomplete-dropdown-h);
    background-color: var(--autocomplete-bg);
    border: var(--autocomplete-border);
    border-top: 0;
    border-radius: var(--autocomplete-dropdown-border-radius);
    transform: scaleY(0);
    transform-origin: top;
    transition: var(--transition);
}

.AutoComplete .AutoComplete-dropdown.AutoComplete-dropdown__opened {
    transform: scaleY(1);
}

.AutoComplete .AutoComplete-dropdown .select__option .checkbox-wrapper {
    padding: var(--autocomplete-dropdown-p);
    border-radius: 0;
    font-size: var(--autocomplete-fz);
    line-height: var(--autocomplete-dropdown-lh);
}

.AutoComplete .AutoComplete-dropdown .select__option:focus .checkbox-wrapper,
.AutoComplete .AutoComplete-dropdown .select__option:hover .checkbox-wrapper,
.AutoComplete .AutoComplete-dropdown .select__option-active .checkbox-wrapper {
    background: var(--autocomplete-dropdown-active-color);
}

.AutoComplete .TextInput::placeholder {
    color: var(--autocomplete-placeholder-color);
}

.AutoComplete .TextInput-prefix .Icon-search svg {
    color: var(--autocomplete-placeholder-color);
}

.AutoComplete .TextInput-affix-wrapper {
    height: auto;
}
