/*
    --badge-text-yellow uses direct color value to avoid
    dark theme change to bright color text on yellow background
*/
.Badge {
    --badge-p: 0 7px;
    --badge-mh: 22px;
    --badge-border-radius: 4px;
    --badge-bg-green: var(--positive-default);
    --badge-bg-yellow: var(--warning-yellow-light);
    --badge-text-yellow: #25282E;
    --badge-bg-red: var(--negative-default);
    --badge-bg-default: var(--secondary-dark);
    --badge-text-default: var(--badge-text-color);

    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-height: var(--badge-mh);
    margin-top: calc(var(--margin-base) / 2);
    padding: var(--badge-p);
    border-radius: var(--badge-border-radius);
    background-color: var(--badge-bg-green);
    color: var(--badge-text-default);
}

.Badge.badge__default {
    background-color: var(--badge-bg-default);
}

.Badge.badge__green {
    background-color: var(--badge-bg-green);
}

.Badge.badge__red {
    background-color: var(--badge-bg-red);
}

.Badge.badge__yellow {
    background-color: var(--badge-bg-yellow);
    color: var(--badge-text-yellow);
}
