.TabPane {
    --tab-pabe-c: var(--tab-pabe-c-theme, var(--secondary-default));
    --tab-pabe-fz: 14px;
    --tab-pabe-lh: 1.5;
    --tab-pabe-transition: opacity 0.45s;
    --tab-pabe-inactive-zi: 2;

    color: var(--tab-pabe-c);
    opacity: 1;
    font-size: var(--tab-pabe-fz);
    line-height: var(--tab-pabe-lh);
    flex-shrink: 0;
    transition: var(--tab-pabe-transition);
}

.TabPane.TabPane-inactive {
    position: relative;
    z-index: var(--tab-pabe-inactive-zi);
    overflow: hidden;
    width: 100%;
    padding: 0 !important;
    height: 0;
    opacity: 1;
    pointer-events: none;
}
