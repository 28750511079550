.BandwidthChart-container {
    --bandwidth-chart-fz: 14px;
    --bandwidth-chart-label-h: 49px;
    --bandwidth-chart-label-mt: calc(var(--indent-base) + 1px);
    --bandwidth-chart-legend-fz: 16px;
    --bandwidth-chart-axis-color: var(--bandwidth-chart-axis-color-theme, var(--primary-default));
    --bandwidth-chart-plot-color: var(--bandwidth-chart-plot-color-theme, var(--secondary-lighter));
    --bandwidth-chart-color-0: var(--bandwidth-chart-color-0-theme, var(--primary-default));
    --bandwidth-chart-color-1: var(--bandwidth-chart-color-1-theme, var(--accent-default));
    --bandwidth-chart-color-2: var(--bandwidth-chart-color-2-theme, var(--accent-lighter));
    --bandwidth-chart-color-3: var(--bandwidth-chart-color-3-theme, var(--secondary-default));
    --bandwidth-chart-color-4: var(--bandwidth-chart-color-4-theme, var(--accent-light));
    --bandwidth-chart-color-5: var(--bandwidth-chart-color-5-theme, var(--accent-dark));
    --bandwidth-chart-color-6: var(--bandwidth-chart-color-6-theme, var(--secondary-default));
    --bandwidth-chart-color-7: var(--bandwidth-chart-color-7-theme, var(--positive-lighter));
    --bandwidth-chart-color-8: var(--bandwidth-chart-color-8-theme, var(--positive-default));
    --bandwidth-chart-color-9: var(--bandwidth-chart-color-9-theme, var(--positive-darker));
    --bandwidth-triangle-color-0: var(--bandwidth-triangle-color-0-theme, var(--accent-darker));
    --bandwidth-triangle-color-1: var(--bandwidth-triangle-color-1-theme, var(--accent-lighter));

    position: relative;
}

.BandwidthChart-container .chart-root {
    width: 100%;
}

.BandwidthChart-container .chart-plot {
    fill: var(--bandwidth-chart-plot-color);
}

.BandwidthChart-container .chart-color-0.chart-bar {
    fill: var(--bandwidth-chart-color-0);
}

.BandwidthChart-container .chart-color-1.chart-bar {
    fill: var(--bandwidth-chart-color-1);
}

.BandwidthChart-container .chart-color-2.chart-bar {
    fill: var(--bandwidth-chart-color-2);
}

.BandwidthChart-container .chart-color-3.chart-bar {
    fill: var(--bandwidth-chart-color-3);
}

.BandwidthChart-container .chart-color-4.chart-bar {
    fill: var(--bandwidth-chart-color-4);
}

.BandwidthChart-container .chart-color-5.chart-bar {
    fill: var(--bandwidth-chart-color-5);
}

.BandwidthChart-container .chart-color-6.chart-bar {
    fill: var(--bandwidth-chart-color-6);
}

.BandwidthChart-container .chart-color-7.chart-bar {
    fill: var(--bandwidth-chart-color-7);
}

.BandwidthChart-container .chart-color-8.chart-bar {
    fill: var(--bandwidth-chart-color-8);
}

.BandwidthChart-container .chart-color-9.chart-bar {
    fill: var(--bandwidth-chart-color-9);
}

.BandwidthChart-container .chart-color-0.chart-triangle {
    fill: var(--bandwidth-triangle-color-0);
}

.BandwidthChart-container .chart-color-1.chart-triangle {
    fill: var(--bandwidth-triangle-color-1);
}

.BandwidthChart-container .chart-bar-legend {
    font-size: var(--bandwidth-chart-fz);
    line-height: 1;
}

.BandwidthChart-container .chart-legend {
    font-size: var(--bandwidth-chart-legend-fz);
    line-height: 1;
    font-weight: var(--accent-font-weight);
    font-family: var(--accent-font-family);
}

.BandwidthChart-container .chart-xaxis-line {
    fill: none;
    stroke: var(--bandwidth-chart-axis-color);
}

.BandwidthChart-container .chart-axis-labels {
    position: absolute;
    top: 0;
    left: 0;
}

.BandwidthChart-container .chart-axis-labels .chart-label {
    display: flex;
    align-items: center;
    height: var(--bandwidth-chart-label-h);
    margin-top: var(--bandwidth-chart-label-mt);
}

.BandwidthChart-container .chart-axis-labels .chart-label .Form-control-label {
    margin-bottom: 0;
}
