.Risk-return-container {
    --risk-return-chart-color-0: var(--risk-return-chart-color-0-theme, var(--accent-lighter));
    --risk-return-chart-color-1: var(--risk-return-chart-color-1-theme, var(--accent-darker));
    --risk-return-chart-color-2: var(--risk-return-chart-color-2-theme, var(--secondary-default));
    --risk-return-chart-color-3: var(--risk-return-chart-color-3-theme, var(--accent-default));
    --risk-return-chart-color-4: var(--risk-return-chart-color-4-theme, var(--accent-lighter));
    --risk-return-chart-color-5: var(--risk-return-chart-color-5-theme, var(--secondary-default));
    --risk-return-chart-color-6: var(--risk-return-chart-color-6-theme, var(--primary-default));
    --risk-return-chart-color-7: var(--risk-return-chart-color-7-theme, var(--positive-lighter));
    --risk-return-chart-color-polygon: var(--risk-return-chart-color-polygon-theme, var(--positive-light));
    --risk-return-chart-point-hover: var(--risk-return-chart-point-hover-theme, var(--accent-dark));
    --risk-return-m: 0 auto;
    --risk-return-ml: var(--margin-base);
    --risk-return-fz: 13px;
    --risk-return-color: var(--risk-return-color-theme, var(--primary-default));
    --risk-return-axis-stroke: var(--risk-return-axis-stroke-theme, var(--secondary-light));
    --risk-return-grid-stroke: var(--risk-return-grid-stroke-theme, var(--secondary-lighter));
    --risk-return-legend-fz: 16px;
    --risk-return-legend-lh: 20px;
    --risk-return-legend-m: var(--margin-base) auto 0;
    --risk-return-legend-item-mr: var(--margin-base);
    --risk-return-legend-point-size: 14px;
    --risk-return-tooltip-p: 11.5px var(--margin-base);
    --risk-return-tooltip-fz: 16px;
    --risk-return-tooltip-bg: var(--risk-return-tooltip-bg-theme, var(--bright));
    --risk-return-tooltip-color: var(--risk-return-tooltip-color-theme, var(--primary-default));
    --risk-return-tooltip-stroke: var(--risk-return-tooltip-stroke-theme, var(--border-color));
    --risk-return-tooltip-row-m: 5px 0;

    position: relative;
    align-items: center;
    max-width: 100%;
}

.Risk-return-chart-container {
    position: relative;
    width: fit-content;
    margin: var(--risk-return-m);
    max-width: 100%;
}

.Risk-return-labels {
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: space-between;
}

.Risk-return-labels > span {
    color: var(--risk-return-color);
    font-size: var(--risk-return-fz);
    line-height: 1;
}

.Risk-return-xaxis-labels {
    width: calc(100% - 40px);
    margin-top: -5px;
    margin-left: 29px;
}

.Risk-return-yaxis-labels {
    position: absolute;
    width: calc(100% - 45px);
    margin-left: 0;
    transform: rotate(-90deg);
    transform-origin: -5px -10px;
}

.Risk-return-legend {
    margin: var(--risk-return-legend-m);
    width: fit-content;
    text-align: center;
}

.Risk-return-legend-item {
    display: inline-flex;
    align-items: center;
    color: var(--risk-return-color);
    letter-spacing: 0.25px;
    font-size: var(--risk-return-legend-fz);
    line-height: var(--risk-return-legend-lh);
}

.Risk-return-legend-item:not(:last-child) {
    margin-right: var(--risk-return-legend-item-mr);
}

.Risk-return-legend-item .legend-name:before {
    display: inline-block;
    width: var(--risk-return-legend-point-size);
    height: var(--risk-return-legend-point-size);
    margin-right: calc(var(--risk-return-legend-point-size) / 2);
    border-radius: var(--border-radius-circle);
    content: '';
}

.Risk-return-legend-item .legend-name {
    display: flex;
    align-items: center;
    font-family: var(--accent-font-family);
    font-weight: var(--accent-font-weight);
}

.Risk-return-legend .legend-item-0 .legend-name:before {
    background-color: var(--risk-return-chart-color-0);
}

.Risk-return-legend .legend-item-1 .legend-name:before {
    background-color: var(--risk-return-chart-color-1);
}

.Risk-return-legend .legend-item-2 .legend-name:before {
    background-color: var(--risk-return-chart-color-2);
}

.Risk-return-legend .legend-item-3 .legend-name:before {
    background-color: var(--risk-return-chart-color-3);
}

.Risk-return-legend .legend-item-4 .legend-name:before {
    background-color: var(--risk-return-chart-color-4);
}

.Risk-return-legend .legend-item-5 .legend-name:before {
    background-color: var(--risk-return-chart-color-5);
}

.Risk-return-legend .legend-item-6 .legend-name:before {
    background-color: var(--risk-return-chart-color-6);
}

.Risk-return-legend .legend-item-7 .legend-name:before {
    background-color: var(--risk-return-chart-color-7);
}

.Risk-return-legend .legend-item-matrix .legend-name:before {
    background-color: var(--risk-return-chart-color-polygon);
    border-radius: 0;
}

.Highcharts-risk-return {
    overflow: visible !important;
    width: fit-content;
    margin-left: var(--risk-return-ml);
    max-width: 100%;
}

.Highcharts-risk-return .highcharts-container {
    z-index: 2;
    overflow: visible !important;
    width: fit-content;
    max-width: 100%;
}

.Highcharts-risk-return .highcharts-root {
    overflow: visible !important;
    max-width: 100%;
    height: auto;
}

.Highcharts-risk-return .highcharts-background {
    fill: transparent;
}

.Highcharts-risk-return .highcharts-point {
    cursor: pointer;
}

.Highcharts-risk-return .highcharts-point-hover {
    stroke: var(--risk-return-chart-point-hover);
    stroke-width: 1px;
}

.Highcharts-risk-return .highcharts-color-0 {
    fill: var(--risk-return-chart-color-0);
    stroke: var(--risk-return-chart-color-0);
}

.Highcharts-risk-return .highcharts-color-1 {
    fill: var(--risk-return-chart-color-1);
    stroke: var(--risk-return-chart-color-1);
}

.Highcharts-risk-return .highcharts-color-2 {
    fill: var(--risk-return-chart-color-2);
    stroke: var(--risk-return-chart-color-2);
}

.Highcharts-risk-return .highcharts-color-3 {
    fill: var(--risk-return-chart-color-3);
    stroke: var(--risk-return-chart-color-3);
}

.Highcharts-risk-return .highcharts-color-4 {
    fill: var(--risk-return-chart-color-4);
    stroke: var(--risk-return-chart-color-4);
}

.Highcharts-risk-return .highcharts-color-5 {
    fill: var(--risk-return-chart-color-5);
    stroke: var(--risk-return-chart-color-5);
}

.Highcharts-risk-return .highcharts-color-6 {
    fill: var(--risk-return-chart-color-6);
    stroke: var(--risk-return-chart-color-6);
}

.Highcharts-risk-return .highcharts-color-7 {
    fill: var(--risk-return-chart-color-7);
    stroke: var(--risk-return-chart-color-7);
}

.Highcharts-risk-return .highcharts-polygon-series {
    fill: var(--risk-return-chart-color-polygon);
    stroke: var(--risk-return-chart-color-polygon);
    fill-opacity: 1;
    opacity: 0.3 !important;
}

.Highcharts-risk-return .highcharts-axis-labels {
    font-family: var(--body-font-family);
    fill: var(--risk-return-color);
}

.Highcharts-risk-return .highcharts-axis-line {
    stroke: var(--risk-return-axis-stroke);
    stroke-width: 1px;
}

.Highcharts-risk-return .highcharts-xaxis-grid .highcharts-grid-line {
    stroke: var(--risk-return-grid-stroke);
    stroke-width: 1px;
}

.Highcharts-risk-return .highcharts-xaxis-labels {
    font-size: var(--risk-return-fz);
}

.Highcharts-risk-return .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: var(--risk-return-grid-stroke);
    stroke-width: 1px;
}

.Highcharts-risk-return .highcharts-yaxis-labels {
    font-size: var(--risk-return-fz);
}

.Highcharts-risk-return .highcharts-halo {
    display: none;
}

.Highcharts-risk-return .highcharts-crosshair {
    stroke: var(--risk-return-color);
}

.Highcharts-risk-return .highcharts-tooltip {
    stroke: var(--risk-return-tooltip-stroke);
    filter: none !important;
}

.Highcharts-risk-return .highcharts-tooltip-box {
    fill: var(--risk-return-tooltip-bg);
    fill-opacity: 0.9;
}

.Highcharts-risk-return .highcharts-tooltip > span {
    font-size: var(--risk-return-tooltip-fz);
    font-family: var(--body-font-family);
    padding: var(--risk-return-tooltip-p) !important;
    color: var(--risk-return-tooltip-color);
    white-space: normal !important;
}

.Highcharts-risk-return .highcharts-tooltip .tooltip-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: var(--risk-return-tooltip-row-m);
}
