.radio-wrapper {
    --radio-size: 18px;
    --radio-dot-color: var(--radio-dot-color-theme, var(--primary-default));
    --radio-duration: 0.3s;
    --radio-button-bg: var(--radio-button-bg-theme, var(--background-content));
    --radio-dot-size: calc(var(--radio-size) - 8px);
    --radio-size-big: 24px;
    --radio-inner-size-big: 12px;
    --radio-button-inner-size-base: 6px;
    --radio-color: var(--radio-color-theme, var(--text-color));
    --radio-label-color: var(--radio-label-color-theme, var(--primary-default));
    --radio-description-color: var(--radio-description-color-theme, var(--secondary-default));
    --radio-wrapper-mr: 8px;
    --radio-input-bs: 0 0 1px 2px var(--radio-input-bs-theme, var(--accent-default));
    --radio-checked-after-border: var(--border-width-base) var(--border-style-base) var(--radio-dot-color);
    --radio-inner-br: 100px;
    --radio-inner-after-transition: opacity .15s;
    --radio-error-bc: var(--radio-error-bc-theme, var(--negative-default));
    --radio-fz: var(--input-font-size-base);
    --radio-lh: var(--input-line-height-base);
    --radio-label-lh: 1.14285714;
    --radio-group-wrapper-mt: var(--margin-base);
    --radio-group-horizontal-wrapper-ml: calc(var(--margin-base) * 2);
    --radio-wrapper-span-pl: 12px;
    --radio-wrapper-hover-inner-border-color: var(--radio-wrapper-hover-inner-border-color-theme, var(--border-color-hover));
    --radio-wrapper-hover-inner-bc: var(--radio-wrapper-hover-inner-bc-theme, var(--background-content));
    --radio-error-hover-inner-border-color: var(--radio-error-hover-inner-border-color-theme, var(--negative-dark));
    --radio-border-style: var(--border-style-base);
    --radio-border-width: var(--border-width-base);
    --radio-border-color: var(--radio-border-color-theme, var(--input-border-color-base));


    --radio-checked-background-color: var(--control-checked-background-color);
    --radio-checked-border-color: var(--control-checked-border-color);
    --radio-checked-color: var(--control-checked-color);

    display: inline-flex;
    align-items: flex-start;
    box-sizing: border-box;
    color: var(--radio-color);
    cursor: pointer;
    font-size: var(--radio-fz);
    font-variant: var(--font-variant-base);
    gap: 12px;
    list-style: none;
    line-height: var(--radio-lh);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
    margin: 0;
    padding: 0;
    position: relative;
    white-space: nowrap;
}

.radio {
    position: relative;
    top: 4px;
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: sub;
    margin: 0;
    padding: 0;
    color: var(--radio-color);
    font-size: var(--radio-fz);
    font-variant: var(--font-variant-base);
    outline: none;
    list-style: none;
    line-height: var(--radio-lh);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
    white-space: nowrap;
    cursor: pointer;
}

.radio-checked:after {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    width: 100%;
    height: 100%;
    border: var(--radio-checked-after-border);
    content: '';
    border-radius: var(--border-radius-circle);
}

.radio-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    cursor: pointer;
}

.radio-input:focus-visible + .radio-inner {
    box-shadow: var(--radio-input-bs);
}

.radio-inner {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    width: var(--radio-size);
    height: var(--radio-size);
    background-color: var(--radio-button-bg);
    border-color: var(--radio-border-color, var(--border-color));
    border-style: var(--radio-border-style);
    border-width: var(--radio-border-width);
    border-radius: var(--radio-inner-br);
    transition: all var(--radio-duration);
}

.radio-inner:after {
    position: absolute;
    top: calc(calc(var(--radio-size) - var(--radio-dot-size)) / 2 - 1px);
    left: calc(calc(var(--radio-size) - var(--radio-dot-size)) / 2 - 1px);
    display: table;
    width: var(--radio-dot-size);
    height: var(--radio-dot-size);
    background-color: var(--radio-dot-color);
    border-top: 0;
    border-left: 0;
    border-radius: var(--radio-dot-size);
    opacity: 0;
    content: ' ';
    transition: var(--radio-inner-after-transition);
}

.radio-checked .radio-inner {
    border-color: var(--radio-checked-border-color) !important;
    background-color: var(--radio-checked-background-color);
}

.radio-checked .radio-inner:after {
    top: 50%;
    left: 50%;
    width: var(--radio-button-inner-size-base);
    height: var(--radio-button-inner-size-base);
    border: 0;
    background: var(--radio-checked-color);
    opacity: 1;
    transform: translate(-50%, -50%);
}

span.radio + * {
    padding-right: var(--radio-button-inner-size-base);
    padding-left: var(--radio-button-inner-size-base);
}

@supports (-moz-appearance: meterbar) and (background-blend-mode: difference, normal) {
    .radio {
        vertical-align: text-bottom;
    }
}

.radio-wrapper-disabled {
    opacity: 0.5;
}

.radio-error .radio-inner {
    border-color: var(--radio-error-bc);
}

.radio-label {
    color: var(--radio-label-color);
    font-size: var(--radio-fz);
}

.radio-description {
    color: var(--radio-description-color);
    margin-bottom: 0;
}

.radio-group {
    --radio-group-wrapper-mt: var(--margin-base);

    display: flex;
    flex-direction: column;
    gap: var(--radio-group-wrapper-mt);
    margin: 0;
    padding: 0;
    color: var(--text-color);
    font-size: var(--font-size-base);
    font-variant: var(--font-variant-base);
    list-style: none;
    line-height: var(--line-height-base);
    -webkit-font-feature-settings: var(--font-feature-settings-base);
    font-feature-settings: var(--font-feature-settings-base);
}

.radio-group-horizontal {
    flex-direction: row;
}

.radio-wrapper + span,
.radio + span {
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    flex-direction: column;
    padding: 0;
    white-space: normal;
}

.radio-wrapper.radio-big {
    align-items: center;
}

.radio-wrapper.radio-big .radio-inner {
    width: var(--radio-size-big);
    height: var(--radio-size-big);
}

.radio-wrapper.radio-big .radio-checked .radio-inner:after {
    width: var(--radio-inner-size-big);
    height: var(--radio-inner-size-big);
    background-size: var(--radio-inner-size-big);
}
