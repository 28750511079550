body {
    position: relative;
    margin: 0;
    padding: 0;
    color: var(--primary-default);
    font-family: var(--body-font-family);
    font-size: 1.4rem;
    line-height: 1.57142857;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0.5em;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

p {
    margin-top: 0;
    margin-bottom: 10px;
}

a {
    color: var(--link-color);
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
}

a:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

a[disabled] {
    cursor: not-allowed;
}

[type='button']:focus-visible,
[role='button']:focus-visible {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

b,
strong {
    font-weight: bolder;
}

ol,
ul,
dl {
    margin-top: 0;
}

img {
    vertical-align: middle;
    border-style: none;
}

fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

[tabindex='-1']:focus {
    outline: none !important;
}

dt {
    font-weight: 500;
}

dd {
    margin-bottom: 0.5em;
    margin-left: 0;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    vertical-align: baseline;
    line-height: 0;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

pre,
code,
kbd,
samp {
    font-size: 1em;
    font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}

#root {
    min-height: 100vh;
}

.text-right {
    text-align: right !important;
}

.content {
    background-color: var(--secondary-lighter);
}
