.profile-menu {
    --profile-menu-link-marker-size: 6px;
    --profile-menu-alert-icon-wrapper-h: 20px;
    --profile-menu-gap: var(--control-gap-base);
    --profile-menu-control-size: 32px;


    --profile-menu-alert-icon-wrapper-mr: var(--padding-base);
    --profile-menu-alert-icon-wrapper-mobile-mr: var(--margin-base);
    --profile-menu-alert-icon-wrapper-span-w: 40px;
    --profile-menu-alert-color: var(--profile-menu-alert-color-theme, var(--secondary-default));
    --profile-menu-alert-after-border-color: var(--profile-menu-alert-after-border-color-theme, var(--background-header));
    --profile-menu-alert-after-border: var(--border-radius-base) var(--border-style-base) var(--profile-menu-alert-after-border-color, var(--background-header));
    --profile-menu-alert-after-bc: var(--profile-menu-alert-after-bc-theme, var(--negative-light));
    --profile-menu-alert-after-ml: calc(var(--profile-menu-link-marker-size) / 3);


    --notifications-menubutton-border-radius: var(--control-border-radius);
    --notifications-menubutton-background-color: var(--secondary-lighter);
    --notifications-menubutton-color: var(--primary-default);
    --notifications-menubutton-fz: 16px;
    --notifications-menubutton-size: 32px;

    display: flex;
    justify-content: stretch;
    align-items: center;
    gap: var(--profile-menu-gap);
}

.notifications-menubutton {
    cursor: pointer;
    height: var(--profile-menu-control-size);
    width: var(--profile-menu-control-size);
}

.profile-menu .avatar img {
    margin: 0;
}

.notifications-menubutton {
    background-color: var(--notifications-menubutton-background-color);
    color: var(--notifications-menubutton-color);
    border-radius: var(--notifications-menubutton-border-radius);
    position: relative;
    font-size: var(--notifications-menubutton-fz);
    line-height: 1;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notifications-menubutton:after {
    /*
    position: absolute;
    top: 12px;
    right: 12px;
    display: inline-block;
    width: var(--profile-menu-link-marker-size);
    height: var(--profile-menu-link-marker-size);
    vertical-align: super;
    box-sizing: content-box;
    border-radius: var(--border-radius-circle);
    border: var(--profile-menu-alert-after-border);
    background-color: var(--profile-menu-alert-after-bc);
    margin-left: var(--profile-menu-alert-after-ml);
    content: ' ';
    */

    content: ' ';
    background-color: var(--profile-menu-alert-after-bc);
    border: 4px solid var(--background-header-theme);
    border-radius: 50%;
    display: block;
    height: 16px;
    width: 16px;
    position: absolute;
    top: -6px;
    right: -6px;
}

.Dropdown-container__opened .notifications-menubutton {
    --notifications-menubutton-background-color: var(--primary-default);
    --notifications-menubutton-color: #ffffff;
}

.notifications-menubutton--empty:after {
    display: none;
}

.profile-menu__notification {
    --notification-max-width: 290px;
    --notification-max-height: 300px;
    --profile-menu-notification-min-width: 240px;
    --profile-menu-notification-p: 0 18px;
    --profile-menu-notification-lh: 1.5;
    --profile-menu-notification-notifications-error-p: 18px 0;

    overflow-y: auto;
    max-height: var(--notification-max-height, 400px);
    min-width: var(--profile-menu-notification-min-width);
    max-width: var(--notification-max-width);
    padding: var(--profile-menu-notification-p);
    line-height: var(--profile-menu-notification-lh);
}

.profile-menu__notification > .NotificationsError {
    padding: var(--profile-menu-notification-notifications-error-p);
}

.profile-menu__content {
    --profile-menu-content-min-width: 240px;
    --profile-menu-content-p: 18px;
    --profile-menu-content-logout-button-m: 10px auto 0;
    --profile-menu-content-logout-button-min-w: 100px;
    --profile-menu-content-logout-fz: 14px;
    --profile-menu-content-logout-lh: 1.57142857;
    --profile-menu-footer-mt: var(--margin-base);

    min-width: var(--profile-menu-content-min-width);
    padding: var(--profile-menu-content-p);
    text-align: center;
}

.profile-menu__content .logout-button {
    display: block;
    height: auto;
    margin: var(--profile-menu-content-logout-button-m);
    padding: 0;
    min-width: var(--profile-menu-content-logout-button-min-w);
    font-size: var(--profile-menu-content-logout-fz);
    line-height: var(--profile-menu-content-logout-lh);
    text-decoration: underline;
}

.profile-menu__content .logout-button span {
    text-decoration: underline;
}

.profile-menu__name {
    --profile-menu-name-m: 10px;
    --profile-menu-name-fz: 18px;
    --profile-menu-name-color: var(--primary-default);
    --profile-menu-name-lh: 1.33333333;

    margin-top: var(--profile-menu-name-m);
    margin-bottom: 0;
    font-size: var(--profile-menu-name-fz);
    color: var(--profile-menu-name-color);
    line-height: var(--profile-menu-name-lh);
}

.profile-menu__email,
.profile-menu__additional {
    --profile-menu-email-mb: 18px;
    --profile-menu-email-color: var(--secondary-default);
    --profile-menu-email-font-size: 12px;
    --profile-menu-email-lh: 12px;

    margin-bottom: var(--profile-menu-email-mb);
    color: var(--profile-menu-email-color);
    font-size: var(--profile-menu-email-font-size);
    line-height: var(--profile-menu-email-lh);
}

.profile-menu__content .profile-menu__footer {
    margin-top: var(--profile-menu-footer-mt);
}

.profile-menu__content .profile-menu__footer .Tabs-bar{
    justify-content: center;
}

.profile-menu__content .additional-button {
    margin-top: calc(var(--margin-base) / 2);
}

.profile-menu__Popover.rc-tooltip-placement-bottom  .rc-tooltip-inner,
.profile-menu__Popover.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
.profile-menu__Popover.rc-tooltip-placement-bottomRight .rc-tooltip-inner {
    margin-top: 0;
}

.profile-menu__Popover.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.profile-menu__Popover.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.profile-menu__Popover.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    top: 0;
}

@media only screen and (max-width: 480px) {
    .notifications-menu__Popover, .profile-menu__Popover {
        width: 100%;
        right: 4px;
        left: auto !important;
    }

    .notifications-menu__Popover.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
        right: 58px;
    }

    .profile-menu__Popover.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
        right: 15px;
    }

    .profile-menu__notification, .profile-menu__Popover {
        max-width: 100%;
    }

    .profile-menu .alert-icon-wrapper {
        margin-right: var(--profile-menu-alert-icon-wrapper-mobile-mr);
    }

    .profile-menu__Popover.rc-tooltip-placement-bottom  .rc-tooltip-inner,
    .profile-menu__Popover.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
    .profile-menu__Popover.rc-tooltip-placement-bottomRight .rc-tooltip-inner,
    .profile-menu__notification.rc-tooltip-placement-bottom  .rc-tooltip-inner,
    .profile-menu__notification.rc-tooltip-placement-bottomLeft .rc-tooltip-inner,
    .profile-menu__notification.rc-tooltip-placement-bottomRight .rc-tooltip-inner {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}
