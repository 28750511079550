.Tabbed-navigation {
    --tabbed-navigation-links-margin: 40px;
    --tabbed-navigation-margin: var(--padding-base) 0;
    --tabbed-navigation-link-color: var(--tabbed-navigation-link-color-theme, var(--secondary-default));
    --tabbed-navigation-link-fz: 16px;
    --tabbed-navigation-link-lh: 1.1875;
    --tabbed-navigation-link__hover-color: var(--tabbed-navigation-link__hover-color-theme, var(--primary-dark));
    --tabbed-navigation-link__active-color: var(--tabbed-navigation-link__active-color-theme, var(--primary-darker));
    --tabbed-navigation-active-color: var(--tabbed-navigation-active-color-theme, var(--primary-default));

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: var(--tabbed-navigation-margin);
}

.Tabbed-navigation__link {
    color: var(--tabbed-navigation-link-color);
    font-size: var(--tabbed-navigation-link-fz);
    line-height: var(--tabbed-navigation-link-lh);
    text-align: center;
}

.Tabbed-navigation__link:hover {
    color: var(--tabbed-navigation-link__hover-color);
}

.Tabbed-navigation__link:active {
    color: var(--tabbed-navigation-link__active-color);
}

.Tabbed-navigation__link_active {
    color: var(--tabbed-navigation-active-color);
    font-weight: bold;
}

.Tabbed-navigation__link + .Tabbed-navigation__link {
    margin-left: var(--tabbed-navigation-links-margin);
}
