.RangeSlider {
    --slider-height: 3px;
    --slider-dot-width: 2px;
    --slider-dot-height: 15px;
    --slider-dot-margin-top: 10px;
    --slider-handle-size: 18px;
    --slider-mark-text-max-width: 150px;
    --slider-dot-bg: var(--slider-dot-bg-theme, var(--secondary-light));
    --slider-bg: var(--slider-bg-theme, var(--secondary-light));
    --slider-handle-bg: var(--slider-handle-bg-theme, var(--primary-default));
    --slider-fill-bg: var(--slider-fill-bg-theme, var(--primary-default));
    --slider-dot-top: calc(var(--slider-height) + var(--slider-dot-margin-top));
    --slider-mark-top: 42px;
    --slider-mark-fz: 16px;
    --slider-mark-color: var(--slider-mark-color-theme, var(--primary-default));
    --slider-padding: 4px;
    --slider-ml: calc(var(--slider-mark-text-max-width) / 2);
    --slider-mr: calc(var(--slider-mark-text-max-width) / 2);
    --slider-mb: 60px;

    position: relative;
    width: 100%;
    height: var(--slider-height);
    margin-left: var(--slider-ml);
    margin-right: var(--slider-mr);
    background: var(--slider-bg);
    -ms-touch-action: none;
    touch-action: none;
    cursor: pointer;
    margin-bottom: var(--slider-mb);
}

.RangeSlider .RangeSlider__fill {
    top: 0;
    background-color: var(--slider-fill-bg);
    height: var(--slider-height);
}

.RangeSlider .RangeSlider__handle {
    position: absolute;
    top: 50%;
    display: inline-block;
    width: var(--slider-handle-size);
    height: var(--slider-handle-size);
    background: var(--slider-handle-bg);
    cursor: pointer;
    border-radius: var(--border-radius-circle);
    transform: translate3d(-50%, -50%, 0);
}

.RangeSlider .RangeSlider__handle:focus-visible {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.RangeSlider .RangeSlider__fill_hidden,
.RangeSlider .RangeSlider__handle_hidden {
    display: none;
}

.RangeSlider .RangeSlider__marks {
    position: relative;
    top: var(--slider-mark-top);
    font-size: var(--slider-mark-fz);
    color: var(--slider-mark-color);
}

.RangeSlider .RangeSlider__marks-item {
    position: absolute;
    width: var(--slider-mark-text-max-width);
    text-align: center;
    min-width: 50px;
    word-break: break-word;
    cursor: pointer;
    transform: translate3d(-50%, 0, 0);
}

.RangeSlider .RangeSlider__dots {
    position: relative;
}

.RangeSlider .RangeSlider__dots-item {
    position: absolute;
    top: var(--slider-dot-top);
    width: var(--slider-dot-width);
    height: var(--slider-dot-height);
    background-color: var(--slider-dot-bg);
}

@media (max-width: 768px) {
    .RangeSlider {
        --slider-mark-text-max-width: 70px;
    }
}
