.form-control-message {
    --form-control-error-mt: 2px;
    --form-control-error-color: var(--form-control-error-color-theme, var(--negative-default));

    display: none;
    color: var(--form-control-error-color);
}

.textarea + .form-control-message {
    white-space: normal;
}

.form-group_invalid .form-control-message {
    display: inline-block;
    color: var(--form-control-error-color);
}
