:root {
    --indent-base: 15px;
    --margin-base: 20px;
    --padding-base: 30px;

    /* Fonts */
    --body-font-family: Inter, Helvetica, Arial, sans-serif;
    --font-variant-base: tabular-nums;
    --font-feature-settings-base: 'tnum';
    --font-size-base: 14px;
    --line-height-base: 1.5;

    /* Form & inputs */
    --input-border-color-base: #000000;
    --input-font-size-base: 16px;
    --input-line-height-base: 26px;
    --input-height-base: 40px;
    --label-font-size-base: 14px;
    --label-line-height-base: 24px;

    /* Border styles */
    --border-width-base: 1px;
    --border-style-base: solid;
    --border-base: var(--border-width-base) var(--border-style-base) var(--border-color);
    --border-radius-circle: 50%;
    --border-radius-base: 5px;
    --border-radius-count-box: 3px;

    /* Shadow*/
    --box-shadow: rgba(0, 0, 0, 0.15);
    --box-shadow-color-base: var(--secondary-light); /*TODO: define*/
    --shadow-color: none;

    /*Focus*/
    --focus-outline-default: 2px solid Highlight;
    --focus-outline-style: auto;
    --focus-outline-color: -webkit-focus-ring-color;

    /*Disabled*/
    --disabled-color: rgba(0, 0, 0, 0.25);
    --disabled-bg: #f5f5f5;

    /* Animation */
    --transition: all .13s cubic-bezier(0.645, 0.045, 0.355, 1);
    --ease-in-out-circ: cubic-bezier(0.78, 0.14, 0.15, 0.86);

    /* Additional colors */
    --white-rgb: 255, 255, 255;
    --black-rgb: 0, 0, 0;
    --primary-default-rgb: 40, 39, 38;
    --warning-yellow: #CEB55E;
    --warning-yellow-light: #FFD54A;

    /* Charts Tooltip */
    --tooltip-bg: var(--bright);

    /* DatePicker */
    --datepicker-week-color: rgba(var(--accent-default-rgb), 0.25);

    /* Calendar */
    --event-primary: #4C5484;
    --event-primary-bg: #D2D8FF;
    --event-purple: #7B4C84;
    --event-purple-bg: #F6C8FF;
    --event-orange: #CE805E;
    --event-orange-bg: #FFE6DB;
    --event-green: #72A356;
    --event-green-bg: #D5FFBE;
    --event-turquoise: #4C7684;
    --event-turquoise-bg: #D4F4FF;
    --event-yellow: #CEB55E;
    --event-yellow-bg: #FFF3C8;

    /* Badge */
    --badge-text-color: #FFF;

    /* rework */
    --control-border-radius: 5px;
    --control-gap-base: 20px;

    --control-border-color: var(--input-border-color-base);
    --control-background-color: var(--background-content);
    --control-color: var(--primary-default);
    --control-checked-border-color: var(--primary-default);
    --control-checked-background-color: var(--primary-default);
    --control-checked-color: var(--background-content);
}
