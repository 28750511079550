.rc-tooltip-placement-bottom,
.rc-tooltip-placement-bottomLeft,
.rc-tooltip-placement-bottomRight {
    padding-top: var(--tooltip-distance);
}

.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 0;
    border-bottom: 10px solid var(--popover-border-color);
}

.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow:after,
.rc-tooltip-placement-bottomLeft > .rc-tooltip-content > .rc-tooltip-arrow:after,
.rc-tooltip-placement-bottomRight > .rc-tooltip-content > .rc-tooltip-arrow:after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 0;
    border-bottom: 10px solid var(--popover-rc-tooltip-inner-bc);
    top: 1px;
}

.rc-tooltip-placement-bottom > .rc-tooltip-content > .rc-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    top: -1px;
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow .rc-tooltip-content,
.rc-tooltip-placement-bottomRight .rc-tooltip-arrow .rc-tooltip-content {
    transform: translateY(calc(23px / 2));
}

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
    left: 50%;
    transform: translateX(-50%);
}

.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow {
    left: var(--popover-arrow-offset-horizontal);
}

.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    right: var(--popover-arrow-offset-vertical);
}
