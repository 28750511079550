.Loader-wrapper {
    --loader-animation-duration: 1.2s;
    --loader-size: 25px;
    --loader-big-size: 50px;
    --loader-border-color: var(--loader-border-color-theme, var(--secondary-light));
    --loader-light-border-color: var(--loader-light-border-color-theme, var(--secondary-lighter));
    --loader-negative-border-color: var(--loader-negative-border-color-theme, var(--negative-lighter));
    --loader-border: 2px var(--border-style-base) var(--loader-border-color);
    --loader-big-border: 3px var(--border-style-base) var(--loader-border-color);
    --loader-spin-color: var(--loader-spin-color-theme, var(--secondary-dark));
    --loader-light-spin-color: var(--loader-light-spin-color-theme, var(--secondary-default));
    --loader-negative-spin-color: var(--loader-negative-spin-color-theme, var(--white));

    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
}

.Loader-wrapper .Loader {
    display: block;
    margin: auto;
    width: var(--loader-size);
    height: var(--loader-size);
    border: var(--loader-border);
    border-left-color: var(--loader-spin-color);
    animation: loader-spin var(--loader-animation-duration) infinite linear;
    border-radius: var(--border-radius-circle);
}

.Loader-wrapper .Loader.Loader-large {
    width: var(--loader-big-size);
    height: var(--loader-big-size);
    border: var(--loader-big-border);
    border-left-color: var(--loader-spin-color);
}

.Loader-wrapper .Loader.Loader-light {
    border-color: var(--loader-light-border-color);
    border-left-color: var(--loader-light-spin-color);
}

.Loader-wrapper .Loader.Loader-negative {
    border-color: var(--loader-negative-border-color);
    border-left-color: var(--loader-negative-spin-color);
}

.Loader-wrapper .Loader .Loader-circle {
    display: none;
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
