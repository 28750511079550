.Highcharts-column-chart {
    --column-chart-color: var(--column-chart-color-theme, var(--primary-default));
    --column-chart-fz: 16px;
    --column-chart-lh: 1.375;
    --column-chart-color-0: var(--column-chart-color-0-theme, var(--positive-default));
    --column-chart-color-1: var(--column-chart-color-1-theme, var(--negative-default));
}

.Highcharts-column-chart .highcharts-plot-line {
    stroke: var(--column-chart-color);
}

.Highcharts-column-chart .highcharts-background {
    fill: transparent;
}

.Highcharts-column-chart .highcharts-series-0 .highcharts-point {
    fill: var(--column-chart-color-0);
}

.Highcharts-column-chart .highcharts-series-1 .highcharts-point {
    fill: var(--column-chart-color-1);
}

.Highcharts-column-chart .highcharts-grid-line,
.Highcharts-column-chart .highcharts-axis-line {
    display: none;
}

.Highcharts-column-chart .highcharts-stack-labels > span {
    visibility: inherit !important;
}

.Highcharts-column-chart .highcharts-stack-labels > span .top {
    top: -10px;
    position: relative;
}

.Highcharts-column-chart .highcharts-stack-labels > span .bottom {
    bottom: -10px;
    position: relative;
}

.Highcharts-column-chart .highcharts-stack-labels > span .label {
    color: var(--column-chart-color);
    font-size: var(--column-chart-fz);
    line-height: var(--column-chart-lh);
    font-family: var(--body-font-family);
    font-weight: 400;
}
