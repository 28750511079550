html {
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: 62.5%;
    line-height: 1.5;
}

html.font-size-m {
    font-size: 71.4286%;
}

html.font-size-l {
    font-size: 80.357%;
}
