.Tabs-bar {
    --tabs-bar-mb: calc(var(--padding-base) / 2);
    --tabs-bar-border-bottom: var(--border-width-base) var(--border-style-base) var(--tabs-bar-border-color-theme, var(--border-color));
    --tabs-bar-nav-fz: 16px;
    --tabs-bar-nav-lh: 1.1875;
    --tabs-bar-nav-transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    --tabs-bar-nav-icon-zi: 2;
    --tabs-bar-nav-icon-bc: var(--tabs-bar-nav-icon-bc-theme, var(--bright));
    --tabs-bar-cb-nav-icon-bc: var(--tabs-bar-cb-nav-icon-bc-theme, var(--background-content));
    --tabs-bar-scrollable-zi: -1;
    --tabs-bar-slider-zi: 1;
    --tabs-bar-slider-lh: var(--line-height-base);
    --tabs-bar-slider-transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    --tabs-bar-tab-m: 0 var(--margin-base) var(--tabs-scroll-height) 0;
    --tabs-bar-tab-transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    --tabs-tab-color: var(--tabs-tab-color-theme, var(--secondary-default));
    --tabs-bar-slider-bc: var(--tabs-bar-slider-bc-theme, var(--secondary-lighter));
    --tabs-bar-slider-bc-shadow: var(--tabs-bar-slider-bc-shadow-theme, none);

    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-bottom: var(--tabs-bar-mb);
    border-bottom: var(--tabs-bar-border-bottom);
}

.Tabs-bar:focus {
    outline: none;
}

.Tabs-bar .Tabs-bar__extra {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--tabs-card-height);
}

.Tabs-bar.Tabs-bar__wrapped .Tabs-bar__extra {
    flex-grow: 1;
}

.Tabs-bar .Tabs-nav {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: -1px;
    font-size: var(--tabs-bar-nav-fz);
    line-height: var(--tabs-bar-nav-lh);
    white-space: nowrap;
    transition: var(--tabs-bar-nav-transition);
    zoom: 1;
    min-width: var(--tabs-nav-min-width);
    max-width: 100%;
}

@media (min-width: 768px) {
    .Tabs-bar .Tabs-nav {
        max-width: 50%;
    }
}

.Tabs-bar.Tabs-bar__wrapped .Tabs-nav {
    flex-grow: 1;
    border-bottom: var(--tabs-bar-border-bottom);
}

.Tabs-bar .Tabs-nav_inner {
    position: relative;
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(var(--tabs-card-height) + var(--tabs-scroll-height));
    margin-bottom: calc(-1 * var(--tabs-scroll-height));
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
}

.Tabs-bar .Tabs-nav.Tabs-nav__scrollable {
    height: var(--tabs-card-height);
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.Tabs-bar .Tabs-nav.Tabs-nav__scrollable ::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.Tabs-bar .Tabs-nav-icon__left,
.Tabs-bar .Tabs-nav-icon__right {
    z-index: var(--tabs-bar-nav-icon-zi);
    overflow: hidden;
    font-size: var(--tabs-icons-size);
    color: var(--tabs-icons-color);
    cursor: pointer;
    padding-top: var(--tabs-icons-padding-vertical);
    padding-bottom: var(--tabs-icons-padding-vertical);
    background-color: var(--tabs-bar-nav-icon-bc);
}

.Tabs-bar .Tabs-nav-icon__left:hover,
.Tabs-bar .Tabs-nav-icon__right:hover {
    color: var(--tabs-highlight-color);
}

.Content-box .Tabs-bar .Tabs-nav-icon__left,
.Content-box .Tabs-bar .Tabs-nav-icon__right {
    background-color: var(--tabs-bar-cb-nav-icon-bc);
}

.Tabs-bar .Tabs-nav-icon__left {
    left: 0;
    padding-right: var(--tabs-icons-padding-horizontal);
}

.Tabs-bar .Tabs-nav-icon__right {
    right: 0;
    padding-left: var(--tabs-icons-padding-horizontal);
}

.Tabs-bar .Tabs-nav.Tabs-nav__scrollable.Tabs-nav__icon-left-hidden .Tabs-nav-icon__left {
    z-index: var(--tabs-bar-scrollable-zi);
    width: 0;
    opacity: 0;
    padding-right: 0;
}

.Tabs-bar .Tabs-nav.Tabs-nav__scrollable.Tabs-nav__icon-right-hidden .Tabs-nav-icon__right {
    z-index: var(--tabs-bar-scrollable-zi);
    width: 0;
    opacity: 0;
    padding-left: 0;
}

.Tabs-bar .Tabs-nav:not(.Tabs-nav__scrollable) .Tabs-nav-icon__left,
.Tabs-bar .Tabs-nav:not(.Tabs-nav__scrollable) .Tabs-nav-icon__right {
    z-index: var(--tabs-bar-scrollable-zi);
    width: 0;
    opacity: 0;
    padding-left: 0;
    padding-right: 0;
}

.Tabs-bar .Tab-slider {
    position: absolute;
    top: calc(var(--tabs-card-height) - var(--tabs-ink-bar-height));
    left: 0;
    z-index: var(--tabs-bar-slider-zi);
    height: var(--tabs-ink-bar-height);
    box-sizing: border-box;
    box-shadow: var(--tabs-bar-slider-bc-shadow);
    line-height: var(--tabs-bar-slider-lh);
    background-color: var(--tabs-bar-slider-bc);
    transform-origin: 0 0;
    transition: var(--tabs-bar-slider-transition);
}

.Tabs-bar .Tab {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: fit-content;
    margin: var(--tabs-bar-tab-m);
    padding: var(--tabs-padding-vertical) 0;
    color: var(--tabs-tab-color);
    text-decoration: none;
    cursor: pointer;
    transition: var(--tabs-bar-tab-transition);
}

.Tabs-bar .Tab:hover {
    color: var(--tabs-highlight-color);
}

.Tabs-bar .Tab:focus-visible {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.Tabs-bar .Tab.Tab-active {
    font-weight: bold;
    color: var(--tabs-highlight-color);
}

.Tabs-bar .Tab.Tab-disabled {
    cursor: not-allowed;
}

.Tabs-bar .Tab.Tab-disabled:hover {
    color: var(--tabs-disabled-color);
}
