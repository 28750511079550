.Pagination {
    --pagination-alignment: center;
    --pagination-size: 20px;
    --pagination-fz: 16px;
    --pagination-lh: 1.1875;
    --pagination-item-prev-next-h: var(--pagination-size);
    --pagination-item-prev-next-min-width: var(--pagination-size);
    --pagination-item-prev-next-color: var(--pagination-item-prev-next-color-theme, var(--secondary-default));
    --pagination-item-prev-next-lh: var(--pagination-size);
    --pagination-item-prev-next-m: 0 5px;
    --pagination-item-item-prev-next-hover-color: var(--pagination-item-item-prev-next-hover-color-theme, var(--primary-default));
    --pagination-prev-next-fz: 10px;
    --pagination-next-m: 0 0 0 5px;
    --pagination-item-active-btn-color: var(--pagination-active-color-theme, var(--primary-default));

    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: center;
    font-size: var(--pagination-fz);
    line-height: var(--pagination-lh);
}

.pagination-item,
.pagination-prev,
.pagination-next {
    height: var(--pagination-item-prev-next-h);
    min-width: var(--pagination-item-prev-next-min-width);
    margin: var(--pagination-item-prev-next-m);
    color: var(--pagination-item-prev-next-color);
    list-style: none;
    line-height: var(--pagination-item-prev-next-lh);
    text-align: center;
}

.pagination-item:hover,
.pagination-prev:hover,
.pagination-next:hover,
.pagination-item:focus,
.pagination-prev:focus,
.pagination-next:focus,
.pagination-item:hover button,
.pagination-prev:hover button,
.pagination-next:hover button {
    color: var(--pagination-item-item-prev-next-hover-color);
    cursor: pointer;
}

.pagination-prev,
.pagination-next {
    margin-left: 0;
    font-size: var(--pagination-prev-next-fz);
}

.pagination-next {
    margin: var(--pagination-next-m);
}

.Pagination button {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}

.Pagination button:focus {
    outline: var(--focus-outline-default);
    outline-color: var(--focus-outline-color);
    outline-style: var(--focus-outline-style);
}

.pagination-item-active button {
    font-weight: bold;
    color: var(--pagination-item-active-btn-color);
}
